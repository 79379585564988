import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { useState, useRef, useEffect } from 'react';

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
/**
 * MonthPicker allows users to select a month and year with an animated dropdown and improved accessibility.
 * @params {Function} setDate - A function to set the selected date.
 * @params {Date} startDate - The earliest selectable date.
 * @returns A MonthPicker component.
 */
export default function MonthPicker({ setDate, startDate }) {
  const [isVisible, setIsVisible] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [year, setYear] = useState(new Date().getFullYear());
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (!isVisible) {
      const previousMonthIndex = selectedMonth - 1;
      const previousYear = previousMonthIndex < 0 ? year - 1 : year;
      const correctedPreviousMonth =
        previousMonthIndex < 0 ? 11 : previousMonthIndex;

      setDate({
        thisMonth: { month: selectedMonth, year: year },
        previousMonth: { month: correctedPreviousMonth, year: previousYear },
      });
    }
  }, [selectedMonth, year, isVisible, setDate]);

  const isYearSelectable = (year) => {
    const currentYear = new Date().getFullYear();
    const startYear = startDate.getFullYear();

    return year >= startYear && year <= currentYear;
  };

  const navigateMonth = (direction, e) => {
    let newMonth = selectedMonth + direction;
    let newYear = year;

    if (newMonth < 0) {
      newMonth = 11;
      newYear -= 1;
    } else if (newMonth > 11) {
      newMonth = 0;
      newYear += 1;
    }

    if (isMonthSelectable(newMonth, newYear)) {
      setSelectedMonth(newMonth);
      setYear(newYear);
    }
  };

  const navigateYear = (direction, e) => {
    e.stopPropagation(); // Prevent the dropdown from toggling
    const newYear = year + direction;
    if (isYearSelectable(newYear)) {
      setYear(newYear);
    }
  };

  const toggleDropdown = () => {
    setIsVisible(!isVisible);
    if (!isVisible) {
      setTimeout(() => dropdownRef.current?.focus(), 100);
    } // Focus the dropdown when it becomes visible
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      setIsVisible(false);
    }

    if (event.key === 'ArrowLeft') {
      navigateMonth(-1);
    }
    if (event.key === 'ArrowRight') {
      navigateMonth(1);
    }
    if (event.key === 'Enter') {
      navigateMonth(0);
      setIsVisible(false);
    }
    if (event.key === 'ArrowDown') {
      const index = months.indexOf(months[selectedMonth]);
      const nextIndex = index + 3;
      if (nextIndex < months.length) {
        setSelectedMonth(nextIndex);
      }
    }
    if (event.key === 'ArrowUp') {
      const index = months.indexOf(months[selectedMonth]);
      const nextIndex = index - 3;
      if (nextIndex >= 0) {
        setSelectedMonth(nextIndex);
      }
    }
  };

  const isMonthSelectable = (monthIndex, year) => {
    const testDate = new Date(year, monthIndex + 1);
    const testDate2 = new Date(year, monthIndex);
    return testDate >= startDate && testDate2 <= new Date();
  };

  const selectMonth = (monthIndex) => {
    if (isMonthSelectable(monthIndex, year)) {
      setSelectedMonth(monthIndex);
      setIsVisible(false); // Close dropdown after selection
      // setDate(new Date(year, monthIndex)); // Update external date state
    }
  };

  return (
    <div className="box-border relative z-10" onKeyDown={handleKeyDown}>
      <div
        className="flex items-center justify-between sm:w-64 mx-auto transition duration-150 ease-in-out bg-white border border-gray-300 rounded-md cursor-pointer dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 dark:border-gray-600 dark:text-white w-[calc(100vw-16px)]"
        onClick={toggleDropdown}
      >
        <button
          className="flex items-center justify-around w-12 h-10 "
          onClick={(e) => {
            e.stopPropagation();
            navigateMonth(-1, e);
          }}
        >
          <ChevronLeftIcon
            className={`w-5 h-5 cursor-pointer ${!isMonthSelectable(selectedMonth - 1, year) ? 'text-gray-400 cursor-not-allowed' : ''}`}
            aria-label="Previous month"
          />
        </button>

        <span
          className="flex items-center justify-around flex-1 h-10 font-medium text-zinc-800"
          aria-hidden="true"
        >{`${months[selectedMonth]} ${year}`}</span>
        <button
          className="flex items-center justify-around w-12 h-10 "
          onClick={(e) => {
            e.stopPropagation();
            navigateMonth(1, e);
          }}
        >
          <ChevronRightIcon
            className={`w-5 h-5 cursor-pointer ${!isMonthSelectable(selectedMonth + 1, year) ? 'text-gray-400 cursor-not-allowed' : ''}`}
            aria-label="Next month"
          />
        </button>
      </div>
      {isVisible && (
        <div
          ref={dropdownRef}
          tabIndex={-1}
          className="absolute w-full p-4 mt-1 transition duration-300 ease-out transform translate-y-1 bg-white rounded-md shadow-lg opacity-100 dark:bg-gray-800"
          style={{ animation: 'slideDown 0.3s ease-out' }}
          aria-label="Month picker"
        >
          <div className="flex items-center justify-between">
            <ChevronLeftIcon
              className={`w-5 h-5 ml-2 cursor-pointer ${!isYearSelectable(year - 1) ? 'text-gray-400 cursor-not-allowed' : ''}`}
              onClick={(e) => navigateYear(-1, e)}
              aria-label="Previous year"
            />
            <span className="font-semibold">{year}</span>
            <ChevronRightIcon
              className={`w-5 h-5 mr-2 cursor-pointer ${!isYearSelectable(year + 1) ? 'text-gray-400 cursor-not-allowed' : ''}`}
              onClick={(e) => navigateYear(1, e)}
              aria-label="Next year"
            />
          </div>
          <div className="grid grid-cols-3 gap-4 mt-2">
            {months.map((month, index) => (
              <div
                key={month}
                className={`rounded-md px-2 py-1 text-center ${!isMonthSelectable(index, year) ? 'text-gray-400 cursor-not-allowed' : index === selectedMonth ? 'bg-indigo-500 text-white' : 'hover:bg-indigo-100 dark:hover:bg-gray-600 cursor-pointer'}`}
                onClick={() => selectMonth(index)}
                role="option"
                aria-selected={index === selectedMonth}
                tabIndex={0}
              >
                {month}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
