import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline';
import { Modal } from 'flowbite-react';
import { Fragment, useEffect, useState } from 'react';

const SelfHostedOptionsModal = ({
  showModal,
  closeModal,
  model,
  deployModel,
  setServerToUseForDeployment,
  serverToUseForDeployment,
  selfHostedServers,
}) => {
  const [serversToShow, setServersToShow] = useState([]);
  useEffect(() => {
    setServersToShow([{ id: 0, server_name: 'Tromero' }, ...selfHostedServers]);
    setServerToUseForDeployment({ id: 0, server_name: 'Tromero' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selfHostedServers]);

  return (
    <Modal
      show={showModal}
      onClose={closeModal}
      title="Deploy Model"
      dismissible
    >
      <Modal.Header>
        <div className="text-lg font-semibold text-zinc-800">Deploy Model</div>
      </Modal.Header>
      <div className="p-6">
        <div className="text-zinc-700">
          <p>Choose the server you want to deploy the model to:</p>
          <div className="mt-4 text-sm text-zinc-700">
            <div className="font-semibold pb-2">Model:</div>{' '}
            <div className="relative w-full py-2 pl-3 pr-10 text-left bg-white shadow cursor-default focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-indigo-300 sm:text-sm">
              {model?.model_name}
            </div>
          </div>
          <div className="mt-4 text-sm text-zinc-700">
            <div className="font-semibold pb-2">Server:</div>{' '}
            <Listbox
              value={serverToUseForDeployment}
              onChange={setServerToUseForDeployment}
            >
              <div className="relative mt-1">
                <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white shadow cursor-default focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-indigo-300 sm:text-sm">
                  <span className="block truncate">
                    {serverToUseForDeployment?.id === 0
                      ? 'Tromero'
                      : serverToUseForDeployment?.server_name}
                  </span>
                  <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <ChevronUpDownIcon
                      className="w-5 h-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white shadow-lg max-h-60 ring-1 ring-black/5 focus:outline-none sm:text-sm">
                    {serversToShow.map((server, serverIdx) => (
                      <Listbox.Option
                        key={serverIdx}
                        className={({ active }) =>
                          `relative cursor-default select-none py-2 pl-10 pr-4 ${
                            active
                              ? 'bg-indigo-100 text-indigo-900'
                              : 'text-gray-900'
                          }`
                        }
                        value={server}
                      >
                        {({ selected }) => (
                          <>
                            <span
                              className={`block truncate ${
                                selected ? '' : 'font-normal'
                              }`}
                            >
                              {server.server_name}
                            </span>
                            {selected ? (
                              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                                <CheckIcon
                                  className="w-5 h-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
          </div>
        </div>
        <div className="flex justify-end gap-4 mt-8">
          <button
            onClick={() => deployModel(model)}
            className="flex items-center gap-1 p-2 px-3 text-sm font-bold rounded-md text-zinc-700 bg-blue-200 hover:bg-blue-300 active:bg-zinc-300"
          >
            Deploy
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default SelfHostedOptionsModal;
