import { FaCheckCircle } from 'react-icons/fa';

import SelectedListingForRental from './SelectedListingForRental';
import useListingRental from '../Hooks/useListingRental';
import Spinner from './Spinner';
const PriceBreakdown = ({
  selectedRental,
  selectedOsTemplate,
  handleUnselectRental,
}) => {
  const { handleRentListing, loading } = useListingRental({
    selectedRental,
    selectedOsTemplate,
    handleUnselectRental,
  });

  return (
    <div className="z-0 flex flex-col w-full my-12 mb-12 rounded-[12px] wavyslow shadow-3xinner md:mb-36 ">
      <div className="absolute inset-0 z-0 hidden rounded-[12px] bg-gray-800/80 dark:block"></div>
      <div className="z-20 h-full m-1 rounded-[8px] bg-white/90 dark:bg-gray-800/70 ">
        <div className="flex flex-row items-center justify-between">
          <h1 className="relative -top-5 left-7 text-2xl italic tracking-wide text-slate-800 font-extralight backdrop-blur-sm px-0.5 dark:text-gray-100 rounded-full ">
            Price Breakdown
          </h1>
        </div>

        {selectedRental ? (
          <div className="z-20 p-4 md:m-4 rounded-lg CONTAINER dark:bg-gray-800 dark:text-gray-100 md:min-w-[700px]">
            <SelectedListingForRental
              selectedListing={selectedRental}
              selectedOsTemplate={selectedOsTemplate}
              newRental
              handleUnselectClick={handleUnselectRental}
            />
          </div>
        ) : (
          <div className="z-20 flex items-center justify-center h-24 m-4 bg-white rounded-lg shadow-md dark:bg-gray-800 dark:text-gray-100">
            <span className="px-4 font-sans text-slate-800 dark:text-gray-100">
              Select an instance from the options provided above to proceed.
            </span>
          </div>
        )}
      </div>
      <span className="flex flex-row justify-center">
        <button
          type="button"
          disabled={!selectedRental || loading}
          onClick={handleRentListing}
          className="z-20 flex items-center justify-center w-full mx-6 my-2 text-sm font-medium text-center bg-white border rounded-lg disabled:hover:bg-white md:mr-8 md:w-2/3 text-slate-800 border-slate-700 hover:bg-slate-100 focus:ring-4 focus:outline-none focus:ring-slate-300 dark:border-slate-500 dark:text-gray-200 dark:hover:text-white dark:hover:bg-slate-500 dark:focus:ring-slate-800 dark:bg-gray-800 h-9 md:ml-0"
        >
          {loading ? (
            <Spinner borderTopColor={'#475569'} />
          ) : (
            <>
              <FaCheckCircle
                size={20}
                className="mr-2 text-slate-400 dark-gray-100"
              />
              Create Machine & Rent
            </>
          )}
        </button>
      </span>
    </div>
  );
};

export default PriceBreakdown;
