import { Switch } from '@headlessui/react';
import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import clsx from 'clsx';

import { useUser } from '../UserContext';
import { amountToBackend, amountToFrontend } from '../utils/CurrencyUtils';

const AutoTopupSettings = ({ setIsSetupOk }) => {
  const [enabled, setEnabled] = useState(false);
  const [threshold, setThreshold] = useState(10);
  const [topup, setTopup] = useState(20);
  const [thresholdError, setThresholdError] = useState('');
  const [topupError, setTopupError] = useState('');
  const { user } = useUser();

  useEffect(() => {
    if (user) {
      const autoTopUpEnabled =
        user.new_credits_top_up_threshold > 0 &&
        user.new_credits_top_up_amount > 0;
      setEnabled(autoTopUpEnabled);
      setThreshold(amountToFrontend(user.new_credits_top_up_threshold));
      setTopup(amountToFrontend(user.new_credits_top_up_amount));
    }
  }, [user]);

  useEffect(() => {
    if (threshold < 5) {
      setThresholdError('Threshold must be at least $5');
    }
    if (threshold >= 5) {
      setThresholdError('');
    }
    if (threshold < topup) {
      setTopupError('Top-up amount must be greater than threshold');
    }
    if (threshold >= topup) {
      setTopupError('');
    }
    if (threshold === '') {
      setThresholdError('Threshold cannot be empty');
    }
    if (topup < 10) {
      setTopupError('Top-up amount must be at least $10');
    }
    if (topup >= 10) {
      setTopupError('');
    }
    if (topup < threshold) {
      setTopupError('Top-up amount must be greater than threshold');
    }
    if (topup >= threshold) {
      setTopupError('');
    }
    if (topup === '') {
      setTopupError('Top-up amount cannot be empty');
    }

    let ok = !enabled || (thresholdError === '' && topupError === '');
    setIsSetupOk(ok);

    if (ok && enabled) {
      const settings = {
        threshold: amountToBackend(threshold),
        topup: amountToBackend(topup),
      };
      localStorage.setItem('autoTopUpEnabled', JSON.stringify(settings));
    } else {
      localStorage.removeItem('autoTopUpEnabled');
    }
  }, [threshold, topup, setIsSetupOk, thresholdError, topupError, enabled]);

  const handleEnabledChange = (e) => {
    setEnabled(e);
  };

  const handleThresholdChange = (e) => {
    const value = parseFloat(e.target.value);
    setThreshold(isNaN(value) ? '' : value); // Fallback to 0 if NaN
  };

  const handleTopupChange = (e) => {
    const value = parseFloat(e.target.value);
    setTopup(isNaN(value) ? '' : value); // Fallback to 0 if NaN
  };

  return (
    <>
      <div className="flex-1 mt-6 bg-white rounded-md dark:bg-gray-700">
        <Switch.Group as="div" className="flex items-center justify-between">
          <span className="flex flex-col flex-grow">
            <Switch.Label
              as="span"
              className="font-semibold text-gray-900 dark:text-gray-200"
              passive
            >
              <span className="flex items-center font-semibold text-gray-900 dark:text-gray-200">
                Enable auto top-up?
              </span>
            </Switch.Label>
            <Switch.Description
              as="div"
              className="pr-4 text-sm text-gray-500 dark:text-gray-300"
            >
              <span className="text-pretty">
                You can enable auto top-up to automatically top up your balance
                when it falls below a certain threshold and avoid service
                interruptions.
              </span>
            </Switch.Description>
          </span>
          <Switch
            checked={enabled}
            onChange={handleEnabledChange}
            className={clsx(
              enabled ? 'bg-gray-900' : 'bg-gray-300',
              'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-gray-600 focus:ring-offset-2',
            )}
          >
            <span className="sr-only">Use setting</span>
            <span
              className={clsx(
                enabled ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
              )}
            >
              <span
                className={clsx(
                  enabled
                    ? 'opacity-0 duration-100 ease-out'
                    : 'opacity-100 duration-200 ease-in',
                  'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity',
                )}
                aria-hidden="true"
              >
                <svg
                  className="w-3 h-3 text-gray-400"
                  fill="none"
                  viewBox="0 0 12 12"
                >
                  <path
                    d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              <span
                className={clsx(
                  enabled
                    ? 'opacity-100 duration-200 ease-in'
                    : 'opacity-0 duration-100 ease-out',
                  'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity',
                )}
                aria-hidden="true"
              >
                <svg
                  className="w-3 h-3 text-indigo-600"
                  fill="currentColor"
                  viewBox="0 0 12 12"
                >
                  <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                </svg>
              </span>
            </span>
          </Switch>
        </Switch.Group>
      </div>
      {enabled && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5, delay: 0.1 }}
        >
          <div className="mt-8 ">
            <label className="text-base font-semibold text-gray-900 dark:text-gray-200">
              Top-up threshold
            </label>
            <p className="text-xs text-gray-500 dark:text-gray-300">
              The low limit at which your balance will be topped up. (Minimum
              $5)
            </p>
            <fieldset className="">
              <legend className="sr-only">Top-up threshold</legend>

              <div className="relative w-full mt-2 rounded-md ">
                <div className="relative flex items-stretch flex-grow focus-within:z-10">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-800 pointer-events-none">
                    $
                  </div>
                  <input
                    type="number"
                    name="threshold"
                    value={threshold}
                    className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 placeholder:text-xs"
                    placeholder="Minimum balance before top-up"
                    onChange={handleThresholdChange}
                    min="5"
                  />
                </div>
                {thresholdError && (
                  <p className="absolute text-xs text-red-500 dark:text-red-300">
                    {thresholdError}
                  </p>
                )}
              </div>
            </fieldset>
          </div>
          <div className="mt-6">
            <label className="text-base font-semibold text-gray-900 dark:text-gray-200">
              Top-up amount
            </label>
            <p className="text-xs text-gray-500 dark:text-gray-300">
              The amount your balance will automatically be topped up by.
              (Minimum $10)
            </p>
            <fieldset className="">
              <legend className="sr-only">Top-up amount</legend>

              <div className="relative w-full pb-6 mt-2 rounded-md ">
                <div className="relative flex items-stretch flex-grow focus-within:z-10">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-800 pointer-events-none">
                    $
                  </div>
                  <input
                    type="number"
                    name="topup"
                    value={topup}
                    className="block w-full  rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 placeholder:text-xs"
                    placeholder="Enter top-up amount"
                    onChange={handleTopupChange}
                    min="10"
                  />
                </div>
                {topupError && (
                  <p className="absolute text-xs text-red-500 dark:text-red-300">
                    {topupError}
                  </p>
                )}
              </div>
            </fieldset>
          </div>
        </motion.div>
      )}
    </>
  );
};

export default AutoTopupSettings;
