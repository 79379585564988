import { useEffect } from 'react';
import { Veriff } from '@veriff/js-sdk';
import { toast } from 'react-hot-toast';

import { useUser } from '../UserContext';

const apiKey = import.meta.env.VITE_VERIFF_API_KEY;

const Veriffication = ({ onClose }) => {
  const { user, customAxios, fetchAndUpdateUserDetails } = useUser();

  useEffect(() => {
    const loadScripts = () => {
      const script = document.createElement('script');
      script.src = 'https://cdn.veriff.me/sdk/js/1.5/veriff.min.js';
      document.head.appendChild(script);

      const script2 = document.createElement('script');
      script2.src = 'https://cdn.veriff.me/incontext/js/v1/veriff.js';
      document.head.appendChild(script2);
    };

    loadScripts();

    const onSession = async (err, response) => {
      if (response && response.verification && response.verification.url) {
        try {
          await postSessionData(response);
          window.veriffSDK.createVeriffFrame({
            url: response.verification.url,
          });
        } catch (error) {
          if (error.code === 'ERR_NETWORK') {
            toast.error('Network error. Please try again later.');
            return;
          }
          toast.error('An unexpected error occurred. Please try again.');
          console.error('Error initiating Veriff session:', error);
        }
      }
    };

    const veriff = Veriff({
      host: 'https://stationapi.veriff.com',
      apiKey,
      parentId: 'veriff-root',
      onSession,
    });

    veriff.setParams({
      person: {
        givenName: ' ',
        lastName: ' ',
      },
      vendorData: ' ',
    });

    veriff.mount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const postSessionData = async (response) => {
    try {
      await customAxios.post('veriff_session', {
        session_id: response.verification.id,
      });
    } catch (error) {
      console.error('An unexpected error occurred:', error);
      if (error.code === 'ERR_NETWORK') {
        toast.error('Network error. Please try again later.');
        return;
      }
      toast.error('An unexpected error occurred. Please try again.');
    }
  };

  useEffect(() => {
    const targetNode = document.body;

    const observerCallback = (mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList') {
          mutation.removedNodes.forEach(async (node) => {
            if (node.nodeName === 'DIV') {
              const iframe = node.querySelector('iframe#veriffFrame');
              if (iframe) {
                onClose();
                if (user) {
                  await fetchAndUpdateUserDetails();
                }
              }
            }
          });
        }
      });
    };

    const observer = new MutationObserver(observerCallback);
    const config = { childList: true, subtree: true };
    observer.observe(targetNode, config);

    return () => observer.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div id="veriff-root"></div>;
};

export default Veriffication;
