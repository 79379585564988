import { useState } from 'react';
import { Modal } from 'flowbite-react';
import { toast } from 'react-hot-toast';

import { useUser } from '../../UserContext';
import Spinner from '../Spinner';

const UserIsUnconfirmedModal = ({ showModal, closeModal }) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const { user, customAxios } = useUser();

  const resendConfirmation = async () => {
    if (!user?.email) {
      toast.error('We could not find your email address');
      return;
    }

    try {
      setLoading(true);
      const response = await customAxios.post('resend_confirmation', {
        email: user.email,
      });
      if (response.status === 200) {
        setMessage('Confirmation Sent');
        toast.success('Confirmation Sent');
      }
    } catch (error) {
      if (error.code === 'ERR_NETWORK') {
        toast.error('Network error. Please try again later.');
        return;
      }
      console.error('Error resending confirmation email:', error);
      toast.error('Error Sending Confirmation. Please Try Again');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={showModal} onClose={closeModal}>
      <Modal.Header> Reminder</Modal.Header>
      <Modal.Body>
        <div className="pb-4 text-base">
          Please confirm your email address to rent machines.
        </div>
        <div className="pb-8 text-base">
          Check your inbox (including spam) for a verification email. Click the
          link to confirm
        </div>
        <div>
          <button
            disabled={loading || message}
            onClick={resendConfirmation}
            className="flex items-center justify-center h-10 my-4 text-sm text-white bg-gray-800 border rounded-md w-72 font-spaceG"
          >
            {loading ? <Spinner /> : message || 'Resend Verification Email'}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UserIsUnconfirmedModal;
