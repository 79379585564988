import React, { useState, useEffect } from 'react';
import { Tooltip } from 'flowbite-react';

import templateList, { categories } from './OsTemplateSelect/OsType';

/**
 * @description The OsTemplateSelection component is used to display the available OS templates for the user to select from.
 * @function OsTemplateSelection
 * @param {function} handleSelectOsTemplate - This function takes the selected OS template's name as an argument and sets the selected OS template in the parent component.
 * @param {string} preselected - The preselected information from the URL.
 * @returns {JSX.Element}
 */
const OsTemplateSelection = ({ handleSelectOsTemplate, preselected }) => {
  const [selectedTemplateName, setSelectedTemplateName] = useState();
  const [showAll, setShowAll] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [displayedTemplates, setDisplayedTemplates] = useState(templateList);
  const [displayedCategories, setDisplayedCategories] = useState(categories);

  const handleSelectMachine = (osTemplate) => {
    if (osTemplate.isActive) {
      setSelectedTemplateName(osTemplate.name);
      handleSelectOsTemplate(osTemplate.name);

      if (osTemplate.name === 'custom_image') {
        const nextSection = document.getElementById('custom-image-details');
        if (nextSection) {
          nextSection.scrollIntoView({ behavior: 'smooth' });
        }
      } else {
        sessionStorage.removeItem('customImageDetails');
        // Scroll to the target element
        const element = document.getElementById('tabs-container-original');
        if (element) {
          const elementPosition =
            element.getBoundingClientRect().top + window.scrollY;

          window.scrollTo({
            top: elementPosition - window.innerHeight / 3.25,
            behavior: 'smooth',
          });
        }
      }
    }
  };

  useEffect(() => {
    const sortedTemplates = templateList.sort((a, b) => {
      if (a.isActive && !b.isActive) {
        return -1;
      }
      if (!a.isActive && b.isActive) {
        return 1;
      }
      return 0;
    });
    setDisplayedTemplates(sortedTemplates);
  }, []);

  // displayed categories are the categories that have at least one template that is marked as active
  useEffect(() => {
    const newCategories = { all: 'All' };
    templateList.forEach((template) => {
      template.category.forEach((category) => {
        if (template.isActive) {
          newCategories[category] = categories[category];
        }
      });
    });
    setDisplayedCategories(newCategories);
  }, []);

  const handleSelectTemplatesToShow = (category) => {
    setSelectedCategory(category);

    if (category === 'all') {
      setDisplayedTemplates(templateList);
      return;
    }
    const newTemplateList = templateList.filter((template) =>
      template.category.includes(category),
    );
    setDisplayedTemplates(newTemplateList);
  };

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  useEffect(() => {
    if (preselected.template && preselected.isValid) {
      setSelectedTemplateName(preselected.template);
      handleSelectOsTemplate(preselected.template);
    } else if (templateList.length > 0) {
      setSelectedTemplateName(templateList[0].name);
      handleSelectOsTemplate(templateList[0].name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderTooltipContent = (info, moreDetailsLink) => {
    return (
      <ul className="list-none p-0 m-0 min-w-[250px] text-xs text-gray-700">
        {info.map((item, index) => {
          return (
            <li key={index} className="flex items-baseline mb-2 text-left">
              <div className="mr-2">·</div>
              <div>{item}</div>
            </li>
          );
        })}
        {moreDetailsLink && (
          <li className="text-left">
            <a
              href={moreDetailsLink}
              target="_blank"
              rel="noopener noreferrer"
              className="text-indigo-600 underline"
            >
              More Details
            </a>
          </li>
        )}
      </ul>
    );
  };

  const handleShowMoreIcon = (e) => {
    e.stopPropagation();
  };

  return (
    <div className="flex flex-col w-full my-12 rounded-[12px] wavyslow shadow-3xinner ">
      <div className="absolute inset-0 hidden rounded-[12px] bg-gray-800/80 dark:block"></div>
      <div className="z-10 h-full m-1 bg-white/90 rounded-[8px] dark:bg-gray-800/70">
        <div className="flex flex-row items-center justify-between">
          <h1 className="relative -top-5 left-7 text-2xl italic tracking-wide text-slate-800 font-extralight px-0.5 backdrop-blur-lg dark:text-gray-100 rounded-full">
            Select an OS Template
          </h1>
        </div>
        <div className="flex flex-wrap ml-8 gap-x-2 gap-y-4 ">
          {Object.keys(displayedCategories).map((category) => (
            <button
              key={category}
              type="button"
              className={`px-2 py-1 text-xs font-medium rounded-md ${
                selectedCategory === category
                  ? 'bg-slate-100 text-slate-800 dark:bg-gray-700 dark:text-gray-100 outline-gray-300 outline outline-1'
                  : 'text-gray-600 dark:text-gray-500 '
              }`}
              onClick={() => handleSelectTemplatesToShow(category)}
            >
              {displayedCategories[category]}
            </button>
          ))}
        </div>
        <div
          className={`grid grid-cols-1 p-4 font-sans lg:gap-4 lg:grid-cols-4 sm:grid-cols-2 ${displayedTemplates.length < 6 ? 'pb-20' : null} `}
        >
          {displayedTemplates
            .slice(0, showAll ? displayedTemplates.length : 8)
            .map(
              (osTemplate) =>
                osTemplate.canShow && (
                  <button
                    key={osTemplate.name}
                    className={`relative p-4 m-1 shadow-lg h-24 rounded-lg hover:shadow-md disabled:hover:shadow-lg bg-white dark:bg-gray-900 capitalize ${
                      selectedTemplateName === osTemplate.name
                        ? 'outline outline-gray-900 dark:outline-gray-300 text-slate-800 dark:text-gray-100 shadow-none outline-2'
                        : ' text-gray-600 dark:text-gray-500'
                    } ${osTemplate.isActive ? 'cursor-pointer' : 'opacity-50'}`}
                    disabled={!osTemplate.isActive}
                    onClick={() => handleSelectMachine(osTemplate)}
                  >
                    <div className="flex items-center">
                      <div
                        className={`flex items-center justify-center min-w-8 h-8 mr-3 text-white rounded-full  ${
                          selectedTemplateName === osTemplate.name
                            ? 'from-gray-900 to-gray-600 bg-gradient-to-r dark:border-2'
                            : 'from-slate-400 to-slate-300 bg-gradient-to-r'
                        }`}
                      >
                        {osTemplate.icon}
                      </div>
                      <div className="flex-1 text-xl font-light text-left whitespace-nowrap">
                        {osTemplate.displayName}
                      </div>
                    </div>
                    <div className="flex items-center justify-between">
                      <div className="my-4 overflow-x-hidden text-sm whitespace-nowrap lg:max-w-36 xl:max-w-none text-ellipsis">
                        {osTemplate.text}
                      </div>
                      <div>
                        {osTemplate.info &&
                          osTemplate.info.length > 0 &&
                          osTemplate.isActive && (
                            <Tooltip
                              content={renderTooltipContent(
                                osTemplate.info,
                                osTemplate.moreDetailsLink,
                              )}
                              arrow={false}
                              style="light"
                            >
                              <svg
                                onClick={handleShowMoreIcon}
                                className="w-4 h-4 text-gray-500 dark:text-white"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 20 20"
                              >
                                <path
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M8 9h2v5m-2 0h4M9.408 5.5h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                                />
                              </svg>
                            </Tooltip>
                          )}
                        {!osTemplate.isActive && (
                          <div className="w-12 text-xs italic text-right text-gray-600 dark:text-gray-500">
                            Coming Soon
                          </div>
                        )}
                      </div>
                    </div>
                  </button>
                ),
            )}
        </div>

        {displayedTemplates.length > 8 && (
          <div className="flex justify-end m-6">
            <button
              type="button"
              className="flex items-center justify-center p-2 -mt-4 text-sm font-medium bg-white rounded-lg shadow text-slate-700 dark:bg-gray-800 hover:bg-slate-100 dark:border-slate-500 dark:text-gray-200 dark:hover:text-white dark:hover:bg-slate-500"
              onClick={toggleShowAll}
            >
              {showAll ? (
                <svg
                  className="w-4 h-4 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 8"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M13 7 7.674 1.3a.91.91 0 0 0-1.348 0L1 7"
                  />
                </svg>
              ) : (
                <svg
                  className="w-4 h-4 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 8"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 5.326 5.7a.909.909 0 0 0 1.348 0L13 1"
                  />
                </svg>
              )}
            </button>
          </div>
        )}
      </div>
      {/* <span className="flex flex-row justify-center">
        <button
          type="button"
          className="flex items-center text-slate-700 dark:bg-gray-800 border border-slate-700 hover:bg-slate-100 focus:ring-4 focus:outline-none focus:ring-slate-300 font-medium rounded-lg text-sm px-3 py-1.5 text-center mr-8 my-2 dark:border-slate-500 dark:text-gray-200 dark:hover:text-white dark:hover:bg-slate-500 dark:focus:ring-slate-800 bg-white z-10"
        >
          <svg
            className="w-4 h-4 mr-2 text-slate-800 dark:text-gray-200"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 18 18"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 1v16M1 9h16"
            />
          </svg>
          Show more templateList
        </button>
      </span> */}
    </div>
  );
};

export default OsTemplateSelection;
