import clsx from 'clsx';

const BaseModelList = ({
  deployList,
  selectedModel,
  handleSetSelectedModel,
}) => {
  const groupedModels = (models) => {
    const families = {
      Mistral: [],
      Mixtral: [],
      LLaMA: [],
      Others: [],
    };

    models.forEach((model) => {
      if (model.model_name.includes('mistral')) {
        families.Mistral.push(model);
      } else if (model.model_name.includes('mixtral')) {
        families.Mixtral.push(model);
      } else if (model.model_name.includes('llama')) {
        families.LLaMA.push(model);
      } else {
        families.Others.push(model);
      }
    });

    return Object.keys(families).map((family) => ({
      family,
      models: families[family],
    }));
  };

  const getImageUrlForFamily = (family) => {
    switch (family) {
      case 'Mistral':
      case 'Mixtral':
        return '/mistral.png';
      case 'LLaMA':
        return '/meta.png';
      default:
        return '';
    }
  };

  return (
    <>
      {/* desktop */}
      <div className="hidden w-full md:block">
        {deployList.map((model, index) => (
          <div
            aria-roledescription="button"
            key={model.model_id + ' ' + index}
            className={clsx(
              'flex items-start justify-between w-full p-4 m-1 border rounded-md border-zinc-300 relative min-h-32 my-6',
              selectedModel?.model_id === model.model_id
                ? 'bg-zinc-100  '
                : 'bg-white',
            )}
            onClick={() => handleSetSelectedModel(model)}
          >
            <div className="grid w-full grid-cols-6 ">
              <div className="self-start max-w-full col-span-2 text-lg font-medium text-left break-words text-zinc-800 text-wrap md:self-center">
                <div className="w-full overflow-hidden whitespace-nowrap text-ellipsis">
                  <div className="flex flex-row items-center">
                    <img
                      src={getImageUrlForFamily(
                        model.model_name.includes('mistral')
                          ? 'Mistral'
                          : model.model_name.includes('mixtral')
                            ? 'Mixtral'
                            : model.model_name.includes('llama')
                              ? 'LLaMA'
                              : 'Others',
                      )}
                      alt=""
                      className="w-auto h-5 mr-2"
                    />
                    {model.model_name}
                  </div>
                </div>
                <div className="mt-1 text-sm font-light">
                  Base model supported for pay-per-token inference
                </div>
              </div>
              <div className="flex items-start text-sm text-zinc-600 justify-self-center">
                {model.last_deployed_on
                  ? new Date(model.last_deployed_on).toLocaleDateString(
                      'en-US',
                      {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                      },
                    )
                  : null}
              </div>
              <div className="flex items-start text-sm text-zinc-600 justify-self-center">
                {model.last_used
                  ? new Date(model.last_used).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                    })
                  : null}
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* mobile */}
      <div className="w-full md:hidden space-y-2">
        {deployList.map((model) => (
          <div
            aria-roledescription="button"
            key={model.model_id}
            className={clsx(
              'flex flex-col w-[98%] px-4 py-2 m-1 border rounded-md border-zinc-300 min-h-44 relative',
              selectedModel?.model_id === model.model_id
                ? 'bg-zinc-100 ring-2 '
                : 'bg-white',
            )}
            onClick={() => handleSetSelectedModel(model)}
          >
            <div className="font-medium text-left text-zinc-800 text-wrap ">
              <div
                className={clsx(
                  'whitespace-nowrap overflow-hidden text-ellipsis w-full text-base',
                  model.self_hosted &&
                    (model.state === 'deployed' ||
                      model.state === 'failed_undeploy') &&
                    '!w-[65%]',
                )}
              >
                {model.model_name}
              </div>
              <div className="mt-1 text-xs font-light">
                Base model supported for pay-per-token inference
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default BaseModelList;
