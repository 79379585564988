import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const ModelsList = () => {
  return (
    <div className="px-6 mx-auto max-w-7xl lg:px-8">
      <div className="py-24 ">
        <div className="px-6 mx-auto max-w-7xl lg:px-8">
          <h2 className="text-2xl leading-8 text-center text-gray-900 sm:text-4xl">
            Improve and Switch Between State-of-the-Art Models
          </h2>
          <div className="grid items-center max-w-lg grid-cols-4 mx-auto gap-x-2 gap-y-10 sm:max-w-xl sm:gap-x-10 lg:mx-0 lg:max-w-none ">
            <img
              alt="openai"
              src="/openailogo.png"
              className="object-contain w-full col-span-1 max-h-48 "
            />
            <img
              alt="mistral"
              src="/mistrallogo.png"
              className="object-contain w-full col-span-1 max-h-48 "
            />
            <img
              alt="Meta logo"
              src="/metalogo.png"
              className="object-contain w-full col-span-1 max-h-48 "
            />
            <img
              alt="Anthropic logo"
              src="/anthropic.png"
              className="object-contain w-full col-span-1 p-4 max-h-48 "
            />
          </div>
          <h2 className="text-lg leading-8 text-center text-zinc-800 ">
            OpenAI, Mistral, Meta, Google, Microsoft, Anthropic and many more.
          </h2>
          <div className="flex items-center justify-center w-full py-12 ">
            <Link
              to="/models"
              className="flex items-center justify-center px-3 py-2 text-sm text-white rounded shadow-sm cursor-pointer bg-zinc-900 hover:bg-zinc-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-zinc-600"
            >
              See full list of supported models
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModelsList;
