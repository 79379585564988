import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from 'flowbite-react';
import { Link } from 'react-router-dom';
import { toast } from 'react-hot-toast';

import { useUser } from '../../UserContext';
import Spinner from '../Spinner';

const KeyCopyModal = ({
  showKeyCopyModal,
  onClose,
  sshKey,
  osTemplate,
  ipAddress,
}) => {
  const { user, customAxios, fetchAndUpdateUserDetails } = useUser();
  const hasKeys = useMemo(
    () => user?.ssh_keys || JSON.parse(localStorage.getItem('ssh_keys')) || [],
    [user],
  );
  const [loading, setLoading] = useState(false);
  const [publicKey, setPublicKey] = useState('');
  useEffect(() => {
    if (showKeyCopyModal && hasKeys.length > 0 && sshKey) {
      navigator.clipboard
        .writeText(sshKey)
        .then(() => {
          // console.log('SSH key copied to clipboard');
        })
        .catch((err) => {
          console.error('Could not copy text: ', err);
        });
    }
  }, [showKeyCopyModal, sshKey, hasKeys]);

  const handleAddSSHKey = async (event) => {
    event.preventDefault();
    setLoading(true);
    const sshKeyData = {
      public_key: publicKey,
    };

    try {
      await customAxios.post('ssh_keys', sshKeyData);

      setPublicKey('');
      await fetchAndUpdateUserDetails();
      toast.success('Public key added successfully');
    } catch (error) {
      if (error.code === 'ERR_NETWORK') {
        toast.error('Network error. Please try again later.');
        return;
      }
      if (import.meta.env.DEV) {
        console.error(error?.response?.data ?? error);
      }
      toast.error('Error adding SSH Key');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Modal show={showKeyCopyModal} size="xl" popup onClose={onClose}>
        <div className="font-spaceG">
          <div className="bg-white rounded-lg dark:bg-gray-800 dark:border">
            <Modal.Header></Modal.Header>
            <div className="flex flex-col items-center justify-center mb-4 text-xl font-medium text-center text-gray-900 dark:text-gray-200 whitespace-nowrap">
              Machine {sshKey ? 'SSH' : 'IP Address'}
            </div>
            {hasKeys.length > 0 ? (
              <div className="HAS">
                <Modal.Body className="p-4 md:p-6">
                  <div className="space-y-6">
                    <div className="flex flex-col items-center justify-center w-full px-2 py-5 mx-auto text-gray-800 border rounded-md dark:text-gray-400 md:w-3/4">
                      <div className="mx-auto">
                        {sshKey ? (
                          <>
                            <div className="text-sm md:text-base">
                              SSH key copied to clipboard, please input:
                            </div>
                            <div className="flex text-sm text-indigo-700 dark:text-indigo-200 whitespace-nowrap md:text-base">
                              {sshKey}
                            </div>
                            <p className="flex text-sm md:text-base">
                              command into your terminal for access.
                            </p>
                          </>
                        ) : (
                          <div className="text-sm md:text-base">
                            The IP address of your machine is:{' '}
                            <span className="text-indigo-700 dark:text-indigo-200">
                              {ipAddress}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                    <Link
                      to="/articles/4"
                      className="flex justify-center text-sm text-indigo-600"
                    >
                      Need help with SSHing? Check out our tutorial here.
                    </Link>
                  </div>
                </Modal.Body>
              </div>
            ) : (
              <div className="HASNOT">
                <Modal.Body>
                  <div className="space-y-6">
                    <div className="flex flex-col items-center justify-center w-full py-4 mx-auto text-gray-800 rounded-md shadow-inner bg-gray-50 dark:text-gray-400">
                      <span className="text-left ">
                        If you have an SSH key already, you can upload it below:{' '}
                      </span>
                      <form
                        onSubmit={handleAddSSHKey}
                        className="flex flex-col w-full"
                      >
                        <div className="flex w-full my-2">
                          <div className="w-full font-spaceG">
                            <label
                              htmlFor="public-key"
                              className="block mb-2 text-sm font-medium text-slate-900 dark:text-white"
                            >
                              {/* Public Key */}
                            </label>
                            <textarea
                              name="public-key"
                              id="public-key"
                              placeholder="Paste your SSH public key here"
                              value={publicKey}
                              onChange={(e) =>
                                setPublicKey(e.target.value.trim())
                              }
                              className="block w-3/4 mx-auto border rounded-lg  border-slate-300 text-slate-900 sm:text-sm focus:ring-slate-600 focus:border-slate-600 dark:bg-gray-700 dark:border-slate-600 dark:placeholder-gray-400 dark:text-white p-2.5"
                              rows="2"
                              required
                            ></textarea>
                          </div>
                        </div>

                        <div className="flex space-x-3">
                          <button
                            type="submit"
                            className="flex items-center justify-center w-3/4 h-10 px-3 mx-auto mt-2 text-xs font-medium text-center text-white bg-gray-600 rounded-md md:text-sm hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800 whitespace-nowrap"
                          >
                            {' '}
                            {loading ? (
                              <Spinner />
                            ) : (
                              <span>Upload SSH Key</span>
                            )}
                          </button>
                          {/* {showSuccessMessage && (
                            <span className="flex items-center px-2 text-green-600 rounded-lg shadow whitespace-nowrap">
                              {sshSuccessMessage}
                            </span>
                          )} */}
                        </div>
                      </form>
                    </div>
                    <Link
                      to="/articles/4"
                      className="flex justify-center text-sm text-indigo-600"
                    >
                      Need help with SSHing? Check out our tutorial here.
                    </Link>
                  </div>
                </Modal.Body>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default KeyCopyModal;
