import { Outlet } from 'react-router-dom';
import { useEffect } from 'react';

import TailorNavbar from '../Components/Tailor/TailorNavbar';
import UnderNavbarBanner from '../Components/UnderNavbarBanner';
import NavigationBar from '../Components/NavigationBar';

//
const TailorLayout = () => {
  useEffect(() => {
    document.documentElement.classList.remove('dark');
    // return () => applyDarkModePreference();
  }, []);

  return (
    <>
      {/* <NavigationBar /> */}
      <UnderNavbarBanner />
      <div className="flex flex-col w-screen lg:flex-row">
        {/* <TailorNavbar /> */}
        <TailorNavbar />
        <div className="flex-1 overflow-x-hidden">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default TailorLayout;
