import { useCurrencyConverter } from '../Hooks/useCurrencyConverter';
import { cleanGpuName, getImageSrcForRegion } from '../utils/InstanceUtils';
import { getOsTypeDisplayName } from './OsTemplateSelect/OsType';

const SelectedListingForRental = ({
  selectedListing,
  selectedOsTemplate,
  newRental = false,
  handleUnselectClick,
}) => {
  const money = useCurrencyConverter();

  return (
    <>
      <div className="relative flex flex-col pb-0 pl-0 pr-0 bg-white rounded-lg shadow-md md:p-4 SELECTEDLISTING dark:bg-gray-900">
        {/* Row 1 */}
        {newRental && (
          <div className="absolute z-10 flex items-center right-2 top-2 md:hidden">
            <button
              type="button"
              onClick={handleUnselectClick}
              className="inline-flex items-center justify-center w-8 h-5 text-sm font-medium bg-transparent rounded shadow hover:bg-slate-200 dark:bg-slate-600 dark:hover:bg-slate-700"
            >
              <svg
                className="w-4 h-4 text-gray-500 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 18 2"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h16"
                />
              </svg>
            </button>
          </div>
        )}
        <div className="flex flex-col justify-between font-sans md:flex-row">
          {/* Left */}
          <div className="items-center hidden my-2 md:flex">
            <span className="inline-flex items-center justify-center w-8 h-8 ml-4 mr-2 text-base font-semibold rounded-full text-slate-800 bg-slate-200 dark:bg-gray-300 ">
              {selectedListing.gpus}x
            </span>
            <span className="mr-4 text-lg italic tracking-wide text-slate-900 dark:text-gray-100 ">
              {cleanGpuName(selectedListing.normalized_gpu_type)}
            </span>
            <div className="items-center hidden w-40 mx-0 my-2 text-sm font-semibold capitalize text-slate-600 whitespace-nowrap min-w-40 lg:mx-4 lg:my-0 dark:text-gray-300 md:flex">
              <div className="flex items-center justify-center w-6 h-6 mx-1 capitalize rounded-full">
                <img
                  src={`/${getImageSrcForRegion(selectedListing.region.split('_').join(' '))}`}
                  alt={`${selectedListing.region.split('_').join(' ')} flag`}
                />
              </div>
              {selectedListing.region.split('_').join(' ')}
            </div>
          </div>
          {/* Right */}
          <div className="items-center hidden mr-4 space-x-4 md:flex">
            <div className="font-semibold text-right text-slate-600 dark:text-gray-200">
              {money(selectedListing.total_price, 'hr')}
            </div>
            {newRental && (
              <button
                type="button"
                onClick={handleUnselectClick}
                className="inline-flex items-center justify-center w-8 h-5 text-sm font-medium bg-transparent rounded shadow hover:bg-slate-200 dark:bg-slate-600 dark:hover:bg-slate-700"
              >
                <svg
                  className="w-4 h-4 text-gray-500 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 18 2"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M1 1h16"
                  />
                </svg>
              </button>
            )}
          </div>
        </div>

        <div className="relative flex w-full pt-4 font-sans md:hidden text-slate-900 dark:text-gray-100">
          <div className="w-16">
            <div className="flex items-center justify-around h-10">
              <span className="inline-flex items-center justify-center text-base font-semibold rounded-full w-9 h-9 text-slate-800 bg-slate-200 dark:bg-gray-300">
                {selectedListing.gpus}x
              </span>
            </div>
            <div className="flex items-center justify-around h-10">
              <div className="flex items-center self-center w-6 h-6 rounded-full">
                <img
                  src={`/${getImageSrcForRegion(selectedListing.region.split('_').join(' '))}`}
                  alt={`${selectedListing.region.split('_').join(' ')} flag`}
                />
              </div>
            </div>
            <div className="flex items-center justify-around h-10">
              <div className="w-3 h-3 rounded-full bg-slate-200 dark:bg-gray-300"></div>
            </div>
          </div>
          <div className="flex-grow">
            <div className="flex items-center h-10 ">
              <span className="text-lg italic tracking-wide text-slate-900 dark:text-gray-100 font-extralight">
                {cleanGpuName(
                  selectedListing.normalized_gpu_type,
                ).toUpperCase()}
              </span>
            </div>
            <div className="flex items-center h-10 capitalize">
              {selectedListing.region.split('_').join(' ')}
            </div>
            <div className="flex items-center h-10">
              {getOsTypeDisplayName(selectedOsTemplate)}
            </div>
          </div>
          <div className="absolute font-medium lowercase bottom-1 right-3 text-slate-600 dark:text-gray-200">
            {money(selectedListing.total_price, 'hr')}
          </div>
        </div>

        {/* Lower Part */}
        <div
          className={`bg-slate-50 dark:bg-gray-800  rounded-b-lg md:h-10 h-4 font-sans`}
        >
          <div className="items-center justify-between hidden p-2 ml-10 text-sm md:flex">
            <div className="flex-1">
              <span className="pr-2 text-slate-900 dark:text-gray-100">
                {getOsTypeDisplayName(selectedOsTemplate)}
              </span>

              <span className="px-2 text-slate-200 dark:text-gray-500">|</span>
              <span className="px-2 text-slate-900 dark:text-gray-100">
                {selectedListing.host_ram} GB RAM
              </span>
              <span className="px-2 text-slate-200 dark:text-gray-500">|</span>
              <span className="pl-2 uppercase text-slate-900 dark:text-gray-100">
                {selectedListing.cpu_name.split('_').join(' ')}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden font-sans md:block">
        <div className="z-20 grid items-center grid-cols-4 p-4 mt-4 bg-white rounded-lg shadow-md dark:bg-gray-800 dark:text-gray-100">
          <div className="z-10 px-2 text-xl italic tracking-wide text-slate-900 dark:text-gray-100 whitespace-nowrap ">
            Total Price:
          </div>
          <div className="mr-2 font-semibold text-right justify-self-center text-slate-600 dark:text-gray-200">
            {money(selectedListing.total_price, 'hr')}
          </div>
          <div className="mr-2 font-semibold text-right text-slate-600 dark:text-gray-200 justify-self-center">
            {money(selectedListing.total_price * 24, 'day')}
          </div>
          <div className="font-semibold text-right mr text-slate-600 dark:text-gray-200 justify-self-center">
            {money(selectedListing.total_price * 730, 'month')}
          </div>
        </div>
      </div>

      <div className="block md:hidden">
        <div className="z-20 p-4 mt-4 bg-white rounded-lg shadow-md dark:bg-gray-900 dark:text-gray-100">
          <div className="text-xl italic tracking-wide text-slate-900 dark:text-gray-100 whitespace-nowrap font-extralight">
            Total Price:
          </div>
          <div className="flex flex-col items-center mt-4">
            <div className="grid w-full grid-cols-3 text-sm">
              <div className="flex items-center justify-self-center">
                <span className="font-medium text-slate-600 dark:text-gray-200">
                  {money(selectedListing.total_price, 'hr')}
                </span>
              </div>
              <div className="flex items-center justify-self-center">
                <span className="font-medium text-slate-600 dark:text-gray-200">
                  {money(selectedListing.total_price * 24, 'day')}
                </span>
              </div>
              <div className="flex items-center justify-self-center">
                <span className="font-medium text-slate-600 dark:text-gray-200">
                  {money(selectedListing.total_price * 730, 'mth')}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectedListingForRental;
