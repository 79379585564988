/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useCallback } from 'react';
import { toast } from 'react-hot-toast';
import { track } from '@vercel/analytics';

import { useUser } from '../UserContext';
import { useRentalModal } from '../RentalModalContext';
import { areAllCreditCardsExpired } from '../utils/userUtils';

/**
 * Custom hook to manage the rental process of listings.
 *
 * @param {Object} options - Configuration options for the rental process.
 * @param {Object} options.selectedRental - The selected rental object.
 * @param {string} options.selectedOsTemplate - The selected OS template identifier.
 * @param {Function|null} options.handleUnselectRental - Callback to handle unselecting a rental.
 * @param {Function|null} options.onSuccessfulRentalCallback - Callback to handle successful rental.
 * @param {Function|null} options.finallyCallback - Callback to execute after the rental process.
 * @param {Function|null} options.onErrorRentalCallback - Callback to handle rental errors.
 * @returns {Object} Contains the handleRentListing function and loading state.
 */
const useListingRental = ({
  selectedRental,
  selectedOsTemplate,
  handleUnselectRental = null,
  onSuccessfulRentalCallback = null,
  finallyCallback = null,
  onErrorRentalCallback = null,
  beforeStartCallback = null,
}) => {
  const {
    user,
    setShowSignInModal,
    setShowGetVerifiedModal,
    customAxios,
    fetchAndUpdateUserDetails,
  } = useUser();

  const {
    setShowUserIsUnconfirmedModal,
    setShowUserIsDelinquentModal,
    setTypeofCreditCardModal,
    setErrorMessage,
    setShowRentalSuccessModal,
    setShowAddSSHModal,
    setShowInsufficientCreditsModal,
    setInsufficientTopUpAmount,
  } = useRentalModal();

  const [loading, setLoading] = useState(false);

  const kycRequired = selectedRental?.cluster?.kyc_required;

  const checkUserStatusAndShowModal = useCallback(async () => {
    setTypeofCreditCardModal('');

    if (!user) {
      localStorage.setItem('shouldRedirect', 'no');
      setShowSignInModal(true);
      return false;
    }

    const updatedUser = await fetchAndUpdateUserDetails();

    if (!updatedUser.confirmed) {
      setShowUserIsUnconfirmedModal(true);
      return false;
    }

    if (updatedUser.delinquent) {
      setShowUserIsDelinquentModal(true);
      return false;
    }

    if (
      updatedUser.new_user &&
      (!updatedUser.payment_methods || updatedUser.payment_methods.length === 0)
    ) {
      setTypeofCreditCardModal('newUser');
      return false;
    } else if (
      !updatedUser.payment_methods ||
      updatedUser.payment_methods.length === 0
    ) {
      setTypeofCreditCardModal('noCard');
      return false;
    }

    if (areAllCreditCardsExpired(updatedUser.payment_methods)) {
      setTypeofCreditCardModal('expiredCard');
      return false;
    }

    if (updatedUser.ssh_keys.length === 0) {
      setShowAddSSHModal(true);
      return false;
    }

    if (selectedOsTemplate === 'custom_image') {
      const customImageDetails = await JSON.parse(
        sessionStorage.getItem('customImageDetails'),
      );
      if (
        !customImageDetails?.dockerhubPath ||
        (customImageDetails?.templateVisibility === 'private' &&
          !customImageDetails?.dockerAccessToken) ||
        (customImageDetails?.httpPortsToOpen.length === 0 &&
          customImageDetails?.tcpPortsToOpen.length === 0) ||
        (!customImageDetails?.httpPortsToOpen &&
          !customImageDetails?.tcpPortsToOpen)
      ) {
        toast.error(
          'Please provide the custom image details and click continue',
        );
        const customImageDetailsSection = document.getElementById(
          'custom-image-details',
        );
        if (customImageDetailsSection) {
          customImageDetailsSection.scrollIntoView({ behavior: 'smooth' });
        }
        return false;
      }
    }

    return true;
  }, [
    user,
    kycRequired,
    fetchAndUpdateUserDetails,
    setShowSignInModal,
    setShowUserIsUnconfirmedModal,
    setShowGetVerifiedModal,
    setShowUserIsDelinquentModal,
    setTypeofCreditCardModal,
    setShowAddSSHModal,
  ]);

  const createMachineAndRent = useCallback(async () => {
    setLoading(true);
    setErrorMessage('');
    if (beforeStartCallback) {
      beforeStartCallback();
    }

    let payload = {
      node_id: parseInt(selectedRental.id),
      os_template: selectedOsTemplate,
      gpu_count: parseInt(selectedRental.gpus),
    };

    if (selectedOsTemplate === 'custom_image') {
      const customImageDetails = await JSON.parse(
        sessionStorage.getItem('customImageDetails'),
      );
      payload = {
        ...payload,
        ...customImageDetails,
      };
    }
    try {
      const response = await customAxios.post('pods', payload);

      if (response.status === 201 || response.status === 202) {
        sessionStorage.removeItem('customImageDetails');
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'firstTimeRentInstanceSuccess',
          selectedOsTemplate,
          userID: user.id,
        });
        window.dataLayer.push({
          event: 'selectedOsTemplate',
          selectedOsTemplate,
        });
        track('rentInstance', { OS_Template: selectedOsTemplate });

        if (onSuccessfulRentalCallback) {
          onSuccessfulRentalCallback();
        } else {
          if (handleUnselectRental) {
            handleUnselectRental();
          }
          setShowRentalSuccessModal(true);
        }
      }
    } catch (error) {
      if (import.meta.env.DEV) {
        console.error('Error renting an instance:', error);
      }
      if (error.code === 'ERR_NETWORK') {
        toast.error('Network error. Please try again later.');
        return;
      }
      if (onErrorRentalCallback) {
        onErrorRentalCallback();
      }
      if (error.code === 'delinquent_user') {
        setShowUserIsDelinquentModal(true);
      } else if (error.code === 'node.missing') {
        toast.error(error.message);
      } else if (error.code === 'insufficient_credits') {
        setShowInsufficientCreditsModal(true);
      } else {
        toast.error('An error occurred, please try again later');
      }
    } finally {
      setLoading(false);
      if (finallyCallback) {
        finallyCallback();
      }
    }
  }, [
    selectedRental,
    selectedOsTemplate,
    customAxios,
    setShowRentalSuccessModal,
    handleUnselectRental,
    onSuccessfulRentalCallback,
    onErrorRentalCallback,
    user,
    setErrorMessage,
    setTypeofCreditCardModal,
    setShowUserIsDelinquentModal,
    setShowInsufficientCreditsModal,
    setInsufficientTopUpAmount,
    setShowAddSSHModal,
    beforeStartCallback,
  ]);

  const handleRentListing = useCallback(async () => {
    const canProceed = await checkUserStatusAndShowModal();
    if (!canProceed) {
      return;
    }

    await createMachineAndRent();
  }, [
    checkUserStatusAndShowModal,
    selectedRental,
    selectedOsTemplate,
    createMachineAndRent,
  ]);

  return {
    handleRentListing,
    loading,
  };
};

export default useListingRental;
