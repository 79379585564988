import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { motion } from 'framer-motion';
import { loadStripe } from '@stripe/stripe-js';
import { Switch } from '@headlessui/react';
import { FaStripe } from 'react-icons/fa6';
import clsx from 'clsx';
import { InformationCircleIcon } from '@heroicons/react/20/solid';

import { useUser } from '../../UserContext';
import { amountToBackend, amountToFrontend } from '../../utils/CurrencyUtils';
import { useCurrencyConverter } from '../../Hooks/useCurrencyConverter';
import ExpiredRibbon from '../ExpiredCreditCardRibbon';
import HowPaymentWorksLink from '../HowPaymentWorksLink';
import Spinner from '../Spinner';
import InvoiceHistory from './InvoiceHistory';

const stripe_publishable_key = import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY;
const stripePromise = loadStripe(stripe_publishable_key);

export const VatText = () => (
  <div className="text-sm text-gray-500 break-normal">
    The prices displayed for the credit purchases as well as the auto top-up
    amounts do not include VAT. VAT will be added at checkout by Stripe based on
    the VAT rate in your location. The final price you pay will be the price for
    the credits as well as the VAT.
  </div>
);

const BillingContent = ({ team }) => {
  const { user, setUser, customAxios, fetchAndUpdateUserDetails } = useUser();
  const [cardToDelete, setCardToDelete] = useState(null);
  const [threshold, setThreshold] = useState(0);
  const [topup, setTopup] = useState(0);
  const [enabled, setEnabled] = useState(true);
  const [selectedTopupAmount, setSelectedTopupAmount] = useState(0);
  const [manualTopupAmount, setManualTopupAmount] = useState(0);
  const [quickConfirm, setQuickConfirm] = useState(false);
  const [manualConfirm, setManualConfirm] = useState(false);
  const [loadingDeleteCard, setLoadingDeleteCard] = useState(false);
  const [loadingAddCard, setLoadingAddCard] = useState(false);
  const [formData, setFormData] = useState({
    tax_id_type: '',
    tax_id_number: '',
  });

  const money = useCurrencyConverter();

  useEffect(() => {
    if (user) {
      const autoTopUpEnabled =
        user.new_credits_top_up_threshold > 0 &&
        user.new_credits_top_up_amount > 0;
      setEnabled(autoTopUpEnabled);
      setThreshold(amountToFrontend(user.new_credits_top_up_threshold)); // Convert cents to dollars
      setTopup(amountToFrontend(user.new_credits_top_up_amount)); // Convert cents to dollars
    }
  }, [user]);

  useEffect(() => {
    if (team && team.tax_id_type && team.tax_id_number) {
      setFormData({
        tax_id_type: team.tax_id_type,
        tax_id_number: team.tax_id_number,
      });
    }
  }, [team]);

  const handleContinue = async () => {
    const stripe = await stripePromise;

    try {
      setLoadingAddCard(true);
      const response = await customAxios.post('create-session', {});

      await fetchAndUpdateUserDetails();
      const session = response.data;

      localStorage.setItem('afterStripeRedirect', '/tailor/settings#billing');
      const result = await stripe.redirectToCheckout({
        sessionId: session.session_id,
      });
      if (result.error) {
        throw new Error(result.error.message);
      }
    } catch (error) {
      console.error('Error during checkout:', error);
      if (error.code === 'ERR_NETWORK') {
        toast.error('Network error. Please try again later.');
        return;
      }
      if (error.code === 'no-valid-token') {
        return;
      }
      toast.error('Error during checkout');
    } finally {
      setLoadingAddCard(false);
    }
  };

  const handleThresholdChange = (event) => {
    setThreshold(event.target.value);
  };

  const handleTopupChange = (event) => {
    setTopup(event.target.value);
  };

  // Handlers for changing the state
  const handleEnabledChange = async (newEnabledState) => {
    // Directly update enabled state
    // setEnabled(newEnabledState);

    if (newEnabledState) {
      // Check if there are payment methods before enabling
      if (user.payment_methods.length === 0) {
        // Inform user to add a payment method first and do not enable auto top-up
        toast.error('Please add a payment method first.');
        setEnabled(false); // Make sure switch goes back to disabled
        return;
      } else {
        // Apply default values if current values are not above zero or keep existing values
        const defaultThreshold = threshold > 0 ? threshold : 10;
        const defaultTopup = topup > 0 ? topup : 20;
        handleSubmitSettings(defaultThreshold, defaultTopup);
      }
    } else {
      // Set values to zero to disable auto top-up
      setThreshold(0);
      setTopup(0);
      handleSubmitSettings(0, 0); // Submit zero values to server
    }
  };

  function handleCreditInputChange(event) {
    const value = event.target.value;
    const numValue = parseFloat(value); // Converts the input string to a number

    if (!isNaN(numValue) && numValue >= 5) {
      setManualTopupAmount(numValue);
      event.target.setCustomValidity(''); // Clear validation message
    } else {
      setManualTopupAmount(0); // Reset or handle appropriately
      event.target.setCustomValidity('Please enter a value of at least $5');
    }
  }

  const handleSubmitSettings = async (newThreshold, newTopup) => {
    // Convert string inputs to numbers for accurate comparison
    const thresholdValue = parseFloat(newThreshold);
    const topupValue = parseFloat(newTopup);

    // Check if either value is less than $5 and not empty
    if (
      (thresholdValue > 0 && thresholdValue < 5) ||
      (topupValue > 0 && topupValue < 5)
    ) {
      toast.error('Minimum threshold and top-up amount is $5.');
      return;
    }

    const payload = {
      top_up_threshold: amountToBackend(newThreshold),
      top_up_amount: amountToBackend(newTopup),
    };

    try {
      await customAxios.put('/credits/preferences', payload);
      await fetchAndUpdateUserDetails();
      toast.success(
        newThreshold > 0
          ? 'Auto top-up settings updated successfully'
          : 'Auto top-up disabled',
      );
    } catch (error) {
      console.error('Error updating auto top-up settings:', error);
      toast.error('Failed to update settings');
    }
  };

  const handleManualTopUp = async (amount) => {
    if (parseInt(amount) < 5) {
      // Since amount is in cents, 500 cents = $5
      toast.error('Minimum top-up amount is $5.');
      return;
    }

    const payload = {
      amount: amountToBackend(parseInt(amount)),
    };

    try {
      const response = await customAxios.post('credits', payload);
      if (import.meta.env.DEV || import.meta.env.MODE === 'staging') {
        console.log('Manual top-up response:', response);
      }

      if (response.data?.url) {
        window.open(response.data.url, '_blank');
      } else {
        toast.success(
          'Payment successful. Your account will be updated shortly',
        );
      }
      setUser({
        ...user,
        credits: user.new_credits_available + payload.amount,
      });

      await fetchAndUpdateUserDetails();
      setQuickConfirm(false);
      setManualConfirm(false);
    } catch (error) {
      console.error('Error during manual top-up:', error);
      toast.error('Failed to add credits');
    }
  };

  const handleDeleteCard = async (cardId) => {
    setLoadingDeleteCard(true);
    try {
      const body = {
        payment_method_id: cardId,
      };

      await customAxios.post('remove-payment-method', body);
      toast.success('Payment method removed');
      setCardToDelete(null);

      // TODO: ** the API call should return the updated user details
      await fetchAndUpdateUserDetails();
    } catch (error) {
      if (import.meta.env.DEV) {
        console.error('Error removing payment method:', error.message);
      }
      if (error.code === 'ERR_NETWORK') {
        toast.error('Network error. Please try again later.');
        return;
      } else {
        toast.error(
          'Error removing the payment method. Please try again later',
        );
      }
    } finally {
      setLoadingDeleteCard(false);
    }
  };

  const confirmDeleteCard = (card) => {
    setCardToDelete(card);
  };

  const cancelDeleteCard = () => {
    setCardToDelete(null);
  };

  const handleQuickClick = (amount) => {
    setSelectedTopupAmount(amount);
    setQuickConfirm(true);
    setManualConfirm(false);
  };

  const handleManualClick = (amount) => {
    setSelectedTopupAmount(amount);
    setQuickConfirm(false);
    setManualConfirm(true);
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await customAxios.put('/team', {
        tax_id_type: formData.tax_id_type,
        tax_id_number: formData.tax_id_number,
      });

      toast.success('Tax information updated successfully');
    } catch (error) {
      toast.error(
        'Failed to update tax information. Please confirm the details are correct and try again.',
      );
    }
  };

  // const isDisabled = !!(team && team.tax_id_type && team.tax_id_number);

  return (
    <div>
      <div className="flex-col justify-center flex-1 w-full p-6 mx-auto mb-16 text-xs border rounded-lg rightside">
        <div className="break-all">
          <div className="w-full mb-10 text-sm text-left text-indigo-500 dark:text-gray-300">
            <h2 className="mb-4 text-xl font-medium leading-tight tracking-tight text-slate-900 sm:mb-6 dark:text-white">
              Manage Balance
            </h2>
            <div className="flex justify-between space-x-4">
              <div className="flex-1 pb-10 dark:bg-gray-800">
                <h3 className="text-lg font-semibold text-gray-700 dark:text-white">
                  Balance
                </h3>
                <p className="text-3xl font-bold text-gray-900 dark:text-white">
                  {typeof user.new_credits_available === 'number'
                    ? money(user.new_credits_available)
                    : money(0)}
                </p>
              </div>
            </div>

            {user?.payment_methods.length > 0 ? (
              <>
                <VatText />

                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.2 }}
                  className="flex flex-col pt-8 h-30 max-w-[400px]"
                >
                  <span className="mb-2 font-semibold text-gray-700">
                    Quick top-up
                  </span>
                  <div className="flex w-full h-12 space-x-4 text-gray-800 justify-evenly">
                    <button
                      onClick={() => handleQuickClick(10)}
                      className="w-1/3 px-4 py-2 text-gray-100 bg-gray-900 border rounded shadow hover:text-gray-900 hover:bg-gray-100 hover:border-gray-900"
                    >
                      10$
                    </button>
                    <button
                      onClick={() => handleQuickClick(25)}
                      className="w-1/3 px-4 py-2 text-gray-100 bg-gray-900 border rounded shadow hover:text-gray-900 hover:bg-gray-100 hover:border-gray-900"
                    >
                      25$
                    </button>
                    <button
                      onClick={() => handleQuickClick(100)}
                      className="w-1/3 px-4 py-2 text-gray-100 bg-gray-900 border rounded shadow hover:text-gray-900 hover:bg-gray-100 hover:border-gray-900"
                    >
                      100$
                    </button>
                  </div>
                  {quickConfirm && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.2 }}
                      className="flex items-center pt-8 h-30"
                    >
                      <button
                        className="h-6 px-2 mr-2 border rounded hover:border-gray-900"
                        onClick={() => setQuickConfirm(false)}
                      >
                        -
                      </button>{' '}
                      {user.payment_methods.length > 0 ? (
                        <div className="flex items-center justify-between w-full pr-2 sm:px-4">
                          <div className="relative flex flex-col justify-center w-40 h-16 px-2 border border-gray-900 rounded sm:w-48">
                            <div className="absolute flex items-center justify-center w-3 h-3 bg-gray-900 rounded-full top-1 right-1">
                              <div className="w-1 h-1 bg-white rounded-full"></div>
                            </div>
                            <div className="text-gray-700">Payment Method</div>
                            <div className="mt-1 text-xs text-gray-500">
                              <span className="capitalize">
                                {user.payment_methods[0].brand}
                              </span>
                              ****
                              {user.payment_methods[0].last4}
                            </div>
                          </div>
                          <button
                            onClick={() =>
                              handleManualTopUp(selectedTopupAmount)
                            }
                            className="flex items-center justify-center w-24 h-10 px-2 text-sm text-white bg-indigo-600 border rounded shadow hover:bg-indigo-700"
                          >
                            Pay ${selectedTopupAmount}
                          </button>
                        </div>
                      ) : (
                        <div className="flex items-center justify-between w-full ">
                          <div className="flex-1 ml-2 text-sm text-gray-700 break-normal">
                            No payment methods available.
                          </div>
                          <button
                            onClick={handleContinue}
                            className="flex items-center h-10 px-4 text-sm border border-gray-900 rounded shadow hover:bg-gray-100"
                          >
                            Add Payment Method
                          </button>
                        </div>
                      )}
                    </motion.div>
                  )}

                  <div>
                    <div className="mt-6 mb-2 font-semibold text-gray-700">
                      Top-up amount{' '}
                      <span className="text-xs text-gray-500">
                        ($5 Minimum)
                      </span>
                    </div>
                    <div className="flex mt-2 rounded-md shadow-sm">
                      <div className="relative flex items-stretch flex-grow focus-within:z-10">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-800 pointer-events-none">
                          {/* <UsersIcon
                          className="w-5 h-5 text-gray-400"
                          aria-hidden="true"
                        /> */}
                          $
                        </div>
                        <input
                          type="text"
                          name="credit"
                          id="credit"
                          className="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                          placeholder="Enter amount"
                          onChange={handleCreditInputChange}
                          pattern="^[5-9]$|^[1-9]\d+$"
                        />
                      </div>
                      <button
                        // onClick={() => {
                        //   const amount =
                        //     document.getElementById('credit').value;
                        //   handleManualTopUp(amount * 100);
                        // }}
                        onClick={() => handleManualClick(manualTopupAmount)}
                        type="button"
                        disabled={!manualTopupAmount}
                        className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-100 ring-1 ring-inset ring-gray-900 hover:text-gray-900 bg-gray-900 hover:bg-gray-100 whitespace-nowrap"
                      >
                        + Add credit
                      </button>
                    </div>
                  </div>
                </motion.div>
                {manualConfirm && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.2 }}
                    className="flex items-center pt-8 h-30 max-w-[400px]"
                  >
                    <button
                      className="h-6 px-2 mr-2 border rounded hover:border-gray-900"
                      onClick={() => setManualConfirm(false)}
                    >
                      -
                    </button>{' '}
                    {user.payment_methods.length > 0 ? (
                      <div className="flex items-center justify-between w-full pr-2 sm:px-4">
                        <div className="relative flex flex-col justify-center w-40 h-16 px-2 border border-gray-900 rounded sm:w-48">
                          <div className="absolute flex items-center justify-center w-3 h-3 bg-gray-900 rounded-full top-1 right-1">
                            <div className="w-1 h-1 bg-white rounded-full"></div>
                          </div>
                          <div className="text-gray-700">Payment Method</div>
                          <div className="mt-1 text-xs text-gray-500">
                            <span className="capitalize">
                              {user.payment_methods[0].brand}
                            </span>
                            ****
                            {user.payment_methods[0].last4}
                          </div>
                        </div>
                        <button
                          onClick={() => handleManualTopUp(selectedTopupAmount)}
                          className="flex items-center justify-center w-24 h-10 px-2 text-sm text-white bg-indigo-600 border rounded shadow hover:bg-indigo-700"
                        >
                          Pay ${selectedTopupAmount}
                        </button>
                      </div>
                    ) : (
                      <div className="flex items-center justify-between w-full ">
                        <div className="flex-1 ml-2 text-sm text-gray-700 break-normal">
                          No payment methods available.
                        </div>
                        <button
                          onClick={handleContinue}
                          className="flex items-center h-10 px-4 text-sm border border-gray-900 rounded shadow hover:bg-gray-100"
                        >
                          Add Payment Method
                        </button>
                      </div>
                    )}
                  </motion.div>
                )}

                <div className="flex-1 py-10 rounded-md dark:bg-gray-800">
                  <Switch.Group
                    as="div"
                    className="flex items-center justify-between"
                  >
                    <span className="flex flex-col flex-grow">
                      <Switch.Label
                        as="span"
                        className="text-lg font-semibold text-gray-900 dark:text-white"
                        passive
                      >
                        <span className="flex items-center text-lg font-semibold text-gray-900 dark:text-white">
                          Auto top-up is {enabled ? 'enabled' : 'disabled'}
                          {enabled ? (
                            <span></span>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 16 16"
                              fill="currentColor"
                              className="w-4 h-4 ml-2 text-yellow-300"
                            >
                              <path
                                fillRule="evenodd"
                                d="M6.701 2.25c.577-1 2.02-1 2.598 0l5.196 9a1.5 1.5 0 0 1-1.299 2.25H2.804a1.5 1.5 0 0 1-1.3-2.25l5.197-9ZM8 4a.75.75 0 0 1 .75.75v3a.75.75 0 1 1-1.5 0v-3A.75.75 0 0 1 8 4Zm0 8a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
                                clipRule="evenodd"
                              />
                            </svg>
                          )}
                        </span>
                      </Switch.Label>
                      <Switch.Description
                        as="div"
                        className="pr-4 text-sm text-gray-500 "
                      >
                        <span className="text-pretty">
                          {enabled && user.payment_methods.length > 0
                            ? `Your balance will Auto-top-up with $${amountToFrontend(
                                user?.new_credits_top_up_amount,
                              ).toFixed(
                                2,
                              )} when it falls below $${amountToFrontend(
                                user?.new_credits_top_up_threshold,
                              ).toFixed(2)} using payment method ${
                                user.payment_methods[0].brand
                                  .charAt(0)
                                  .toUpperCase() +
                                user.payment_methods[0].brand.slice(1)
                              } (${user.payment_methods[0].last4}). VAT will also be added to this Auto-top-up.`
                            : user.payment_methods.length === 0
                              ? 'You will need to first add a payment method below to enable Auto top-up.'
                              : 'We recommend enabling auto top-up to avoid service interruptions.'}
                        </span>
                      </Switch.Description>
                    </span>
                    <Switch
                      checked={enabled}
                      disabled={user.payment_methods.length === 0}
                      onChange={handleEnabledChange}
                      className={clsx(
                        enabled ? 'bg-gray-900' : 'bg-gray-200',
                        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-gray-600 focus:ring-offset-2',
                      )}
                    >
                      <span className="sr-only">Use setting</span>
                      <span
                        className={clsx(
                          enabled ? 'translate-x-5' : 'translate-x-0',
                          'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                        )}
                      >
                        <span
                          className={clsx(
                            enabled
                              ? 'opacity-0 duration-100 ease-out'
                              : 'opacity-100 duration-200 ease-in',
                            'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity',
                          )}
                          aria-hidden="true"
                        >
                          <svg
                            className="w-3 h-3 text-gray-400"
                            fill="none"
                            viewBox="0 0 12 12"
                          >
                            <path
                              d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                        <span
                          className={clsx(
                            enabled
                              ? 'opacity-100 duration-200 ease-in'
                              : 'opacity-0 duration-100 ease-out',
                            'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity',
                          )}
                          aria-hidden="true"
                        >
                          <svg
                            className="w-3 h-3 text-indigo-600"
                            fill="currentColor"
                            viewBox="0 0 12 12"
                          >
                            <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                          </svg>
                        </span>
                      </span>
                    </Switch>
                  </Switch.Group>
                </div>
                {enabled && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5, delay: 0.1 }}
                  >
                    <div className="pt-10 border-t">
                      <label className="text-base font-semibold text-gray-900">
                        Top-up threshold
                      </label>
                      <p className="text-sm text-gray-500">
                        The low limit at which your balance will be topped up
                      </p>
                      <fieldset className="mt-4">
                        <legend className="sr-only">Threshold method</legend>

                        <div className="flex w-full pb-6 mt-2 rounded-md ">
                          <div className="relative flex items-stretch flex-grow focus-within:z-10">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-800 pointer-events-none">
                              {/* <UsersIcon
                          className="w-5 h-5 text-gray-400"
                          aria-hidden="true"
                        /> */}
                              $
                            </div>
                            <input
                              type="text"
                              name="threshold"
                              value={threshold}
                              className="block rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 w-1/2"
                              placeholder="Minimum balance before top-up"
                              onChange={handleThresholdChange}
                              pattern="^[5-9]$|^[1-9]\d+$"
                            />
                          </div>
                        </div>
                      </fieldset>
                    </div>
                    <div className="mt-6">
                      <label className="text-base font-semibold text-gray-900">
                        Top-up amount
                      </label>
                      <p className="text-sm text-gray-500">
                        The amount your balance will automatically be topped up
                        by
                      </p>
                      <fieldset className="mt-4">
                        <legend className="sr-only">Top-up method</legend>

                        <div className="flex w-full pb-6 mt-2 rounded-md ">
                          <div className="relative flex items-stretch flex-grow focus-within:z-10">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-800 pointer-events-none">
                              {/* <UsersIcon
                          className="w-5 h-5 text-gray-400"
                          aria-hidden="true"
                        /> */}
                              $
                            </div>
                            <input
                              type="text"
                              name="topup"
                              value={topup}
                              className="block w-1/2  rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              placeholder="Enter amount"
                              onChange={handleTopupChange}
                              pattern="^[5-9]$|^[1-9]\d+$"
                            />
                          </div>
                        </div>
                      </fieldset>
                      <button
                        type="button"
                        className="flex items-center justify-center w-full h-10 px-3 text-sm font-semibold text-white bg-gray-900 rounded-md shadow-sm sm:w-1/2 hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:mt-8"
                        onClick={() => handleSubmitSettings(threshold, topup)}
                      >
                        Update Auto-top-up
                      </button>
                    </div>
                  </motion.div>
                )}
              </>
            ) : (
              <div className="text-sm text-center text-gray-500 break-normal dark:text-gray-300 text-wrap">
                Please add a payment method to enable auto top-up and to manage
                your balance.
              </div>
            )}
          </div>
        </div>

        {user?.payment_methods.length === 0 && <VatText />}
      </div>

      <div className="items-center flex-1 p-6 mx-auto mb-16 border rounded-lg">
        <h2 className="mb-4 text-xl font-medium leading-tight tracking-tight text-slate-900 sm:mb-6 dark:text-white">
          Tax Identification Number
        </h2>
        <div className="p-4 mb-4 rounded-md sm:mb-6 bg-indigo-50">
          <div className="flex">
            <div className="flex-shrink-0">
              <InformationCircleIcon
                aria-hidden="true"
                className="w-5 h-5 text-indigo-400"
              />
            </div>
            <div className="flex-1 ml-3 md:flex md:justify-between">
              <p className="text-sm text-indigo-700">
                If you are a business user, it is essential to provide your tax
                ID number to ensure accurate VAT billing.
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col space-y-6 md:flex-row md:space-y-0 md:space-x-6 rightside">
          <div className="">
            <label
              htmlFor="taxIdType"
              className="block mb-2 text-sm font-medium text-slate-900 dark:text-white"
            >
              Tax ID Type
            </label>
            <select
              name="tax_id_type"
              id="taxIdType"
              value={formData.tax_id_type}
              onChange={handleInputChange}
              className="flex-1 block w-full h-10 text-sm border-gray-300 rounded-lg shadow-sm form-input focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 dark:bg-gray-800 dark:text-gray-100 dark:placeholder-gray-300 text-slate-900"
              // disabled={isDisabled}
              required
            >
              <option value="">Select Tax ID Type</option>
              <option value="ad_nrt">Andorra (NRT)</option>
              <option value="ae_trn">United Arab Emirates (TRN)</option>
              <option value="ar_cuit">Argentina (CUIT)</option>
              <option value="au_abn">Australia (ABN)</option>
              <option value="au_arn">Australia (ARN)</option>
              <option value="bg_uic">Bulgaria (UIC)</option>
              <option value="bh_vat">Bahrain (VAT)</option>
              <option value="bo_tin">Bolivia (TIN)</option>
              <option value="br_cnpj">Brazil (CNPJ)</option>
              <option value="br_cpf">Brazil (CPF)</option>
              <option value="ca_bn">Canada (BN)</option>
              <option value="ca_gst_hst">Canada (GST/HST)</option>
              <option value="ca_pst_bc">Canada (PST, British Columbia)</option>
              <option value="ca_pst_mb">Canada (PST, Manitoba)</option>
              <option value="ca_pst_sk">Canada (PST, Saskatchewan)</option>
              <option value="ca_qst">Canada (QST)</option>
              <option value="ch_uid">Switzerland (UID)</option>
              <option value="ch_vat">Switzerland (VAT)</option>
              <option value="cl_tin">Chile (TIN)</option>
              <option value="cn_tin">China (TIN)</option>
              <option value="co_nit">Colombia (NIT)</option>
              <option value="cr_tin">Costa Rica (TIN)</option>
              <option value="de_stn">Germany (STN)</option>
              <option value="do_rcn">Dominican Republic (RCN)</option>
              <option value="ec_ruc">Ecuador (RUC)</option>
              <option value="eg_tin">Egypt (TIN)</option>
              <option value="es_cif">Spain (CIF)</option>
              <option value="eu_oss_vat">European Union (OSS VAT)</option>
              <option value="eu_vat">European Union (VAT)</option>

              <option value="ge_vat">Georgia (VAT)</option>
              <option value="hk_br">Hong Kong (BR)</option>
              <option value="hu_tin">Hungary (TIN)</option>
              <option value="id_npwp">Indonesia (NPWP)</option>
              <option value="il_vat">Israel (VAT)</option>
              <option value="in_gst">India (GST)</option>
              <option value="is_vat">Iceland (VAT)</option>
              <option value="jp_cn">Japan (CN)</option>
              <option value="jp_rn">Japan (RN)</option>
              <option value="jp_trn">Japan (TRN)</option>
              <option value="ke_pin">Kenya (PIN)</option>
              <option value="kr_brn">South Korea (BRN)</option>
              <option value="kz_bin">Kazakhstan (BIN)</option>
              <option value="li_uid">Liechtenstein (UID)</option>
              <option value="mx_rfc">Mexico (RFC)</option>
              <option value="my_frp">Malaysia (FRP)</option>
              <option value="my_itn">Malaysia (ITN)</option>
              <option value="my_sst">Malaysia (SST)</option>
              <option value="ng_tin">Nigeria (TIN)</option>
              <option value="no_vat">Norway (VAT)</option>
              <option value="no_voec">Norway (VOEC)</option>
              <option value="nz_gst">New Zealand (GST)</option>
              <option value="om_vat">Oman (VAT)</option>
              <option value="pe_ruc">Peru (RUC)</option>
              <option value="ph_tin">Philippines (TIN)</option>
              <option value="ro_tin">Romania (TIN)</option>
              <option value="rs_pib">Serbia (PIB)</option>
              <option value="ru_inn">Russia (INN)</option>
              <option value="ru_kpp">Russia (KPP)</option>
              <option value="sa_vat">Saudi Arabia (VAT)</option>
              <option value="sg_gst">Singapore (GST)</option>
              <option value="sg_uen">Singapore (UEN)</option>
              <option value="si_tin">Slovenia (TIN)</option>
              <option value="sv_nit">El Salvador (NIT)</option>
              <option value="th_vat">Thailand (VAT)</option>
              <option value="tr_tin">Turkey (TIN)</option>
              <option value="tw_vat">Taiwan (VAT)</option>
              <option value="ua_vat">Ukraine (VAT)</option>
              <option value="gb_vat">United Kingdom (VAT)</option>
              <option value="us_ein">United States (EIN)</option>
              <option value="uy_ruc">Uruguay (RUC)</option>
              <option value="ve_rif">Venezuela (RIF)</option>
              <option value="vn_tin">Vietnam (TIN)</option>
              <option value="za_vat">South Africa (VAT)</option>
            </select>
          </div>

          <div>
            <label
              htmlFor="taxId"
              className="block mb-2 text-sm font-medium text-slate-900 dark:text-white"
            >
              Tax ID
            </label>
            <form
              onSubmit={handleSubmit}
              className="relative flex items-center"
            >
              <input
                type="text"
                name="tax_id_number"
                id="taxId"
                value={formData.tax_id_number}
                onChange={handleInputChange}
                className="flex-1 block w-full h-10 text-sm border-gray-300 shadow-sm rounded-l-md form-input focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 dark:bg-gray-800 dark:text-gray-100 dark:placeholder-gray-300 text-slate-900"
                // disabled={isDisabled}
                required
              />
              <button
                type="submit"
                className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-[11px] text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 h-10"
                // disabled={isDisabled}
              >
                {' '}
                Save
              </button>
            </form>
          </div>
        </div>
      </div>

      <div className="flex-col justify-center flex-1 w-full p-6 mx-auto mb-16 text-xs border rounded-lg rightside ">
        <div className="break-all">
          <div className="w-full mb-10 text-sm text-left text-indigo-500 dark:text-gray-300">
            <h2 className="mb-4 text-xl font-medium leading-tight tracking-tight text-slate-900 sm:mb-6 dark:text-white">
              Payment Methods
            </h2>
          </div>
          {/* error here */}
          {user?.payment_methods?.length === 0 ? (
            <div className="text-sm text-center text-gray-500 break-normal dark:text-gray-300 text-wrap">
              You currently have no active payment methods. Please press the
              button below to add one.
            </div>
          ) : (
            <ul>
              {user?.payment_methods?.map((card) => (
                <div
                  className="relative flex items-center w-full h-24 p-2 my-2 rounded-lg dark:text-gray-300"
                  key={card.id}
                >
                  {cardToDelete && cardToDelete.id === card.id ? (
                    <div className="flex flex-col items-center justify-between w-full md:flex-row">
                      <span className="flex items-center h-16 text-sm md:text-base sm:whitespace-nowrap">
                        Are you sure you want to delete this card?
                      </span>
                      <div>
                        <button
                          onClick={() => handleDeleteCard(card.id)}
                          className="px-2 py-1 mx-2 text-base text-red-500 rounded hover:bg-red-100"
                        >
                          {loadingDeleteCard ? (
                            <Spinner
                              size={'14px'}
                              borderColor={'pink'}
                              borderTopColor={'red'}
                            />
                          ) : (
                            <span>Yes</span>
                          )}
                        </button>
                        <button
                          onClick={cancelDeleteCard}
                          className="px-2 py-1 text-base text-gray-500 rounded hover:bg-gray-100"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  ) : (
                    <>
                      {user.payment_methods.length > 0 && (
                        <button
                          onClick={() => confirmDeleteCard(card)}
                          className={`px-2 py-1 mr-4 text-sm rounded shadow text-white`}
                        >
                          <svg
                            className="w-4 h-4 text-gray-700 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 18 20"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M1 5h16M7 8v8m4-8v8M7 1h4a1 1 0 0 1 1 1v3H6V2a1 1 0 0 1 1-1ZM3 5h12v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V5Z"
                            />
                          </svg>
                        </button>
                      )}

                      <div className="relative flex items-center justify-center w-48 h-24 overflow-hidden text-gray-800 bg-gray-100 border border-gray-200 rounded-md shadow dark:border-gray-700 dark:bg-gray-900 dark:text-gray-200 min-w-48 overflow-ellipsis">
                        <ExpiredRibbon
                          expMonth={card.exp_month}
                          expYear={card.exp_year}
                        />
                        <span className="flex items-center mr-2 text-xs font-medium capitalize">
                          {card.brand}
                        </span>
                        <span className="flex items-center">
                          · · · · {card.last4}
                        </span>
                      </div>
                    </>
                  )}
                </div>
              ))}
            </ul>
          )}
        </div>

        <div className="flex mt-12">
          <button
            className="w-1/4 h-12 rounded-md  min-w-[200px] items-center flex justify-center group  shadow bg-gray-900 mb-6 hover:bg-gray-800"
            onClick={handleContinue}
          >
            {' '}
            {loadingAddCard ? (
              <Spinner />
            ) : (
              <div className="flex items-center justify-center my-auto">
                <div className="flex text-sm font-medium text-gray-100 ">
                  + Add a card with{' '}
                </div>
                <div>
                  <FaStripe className="w-12 h-12 ml-1.5 text-gray-100" />
                </div>
              </div>
            )}
          </button>
        </div>
        <HowPaymentWorksLink />
      </div>
      <InvoiceHistory />
    </div>
  );
};

export default BillingContent;
