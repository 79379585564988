import { useEffect, useState } from 'react';
import { Modal } from 'flowbite-react';
import { useNavigate } from 'react-router-dom';

export default function RentalSuccessModal({ showModal, closeModal }) {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(3);

  useEffect(() => {
    if (showModal) {
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown === 1) {
            clearInterval(timer);
            setTimeout(() => {
              closeModal(false);
              navigate('/tailor');
            }, 0);
            return 0;
          } else {
            return prevCountdown - 1;
          }
        });
      }, 2500);

      return () => clearInterval(timer);
    }
  }, [showModal, navigate, closeModal]);

  const handleAcknowledgement = () => {
    closeModal(false);
    navigate('/tailor');
  };

  return (
    <div className="">
      <Modal show={showModal} size="md" popup onClose={handleAcknowledgement}>
        <div className="h-0.5 bg-gray-900 dark:bg-gray-200 ml-1 shrink-width-animation"></div>
        <Modal.Header
          theme={{
            close: { icon: 'H', base: 'text-gray-900 dark:text-gray-200' },
          }}
        >
          <div className="bg-white rounded-lg dark:bg-gray-800 dark:border">
            <div className="flex items-center justify-center">
              <img
                src="/tromero_bright.png"
                className="w-12 h-auto m-2 sm:h-10 sm:w-auto"
                alt="tromero logo"
              />
              <div className="flex justify-center ml-4 text-base md:text-xl font-medium text-gray-900 dark:text-gray-200 whitespace-nowrap h-full">
                Thank you for renting with us!
              </div>
            </div>
          </div>
        </Modal.Header>
      </Modal>
    </div>
  );
}
