import { RentalModalProvider } from '../RentalModalContext';

/**
 * RentalModalManager: A component that wraps the children components with the RentalModalProvider
 * @component
 * @return {React.Component}
 * @param {Object} props
 * @param {React.Component} props.children - The children components to be wrapped
 *
 */
const RentalModalManager = ({ children }) => {
  return <RentalModalProvider>{children}</RentalModalProvider>;
};

export default RentalModalManager;
