import { Switch } from '@headlessui/react';
import clsx from 'clsx';

import { getLogMessage } from '../../../utils/TailorUtils';

const ModelList = ({
  deployList,
  selectedModel,
  handleSetSelectedModel,
  toggleDeployStatus,
  selfHostedServers,
}) => {
  const modelStateMessage = {
    start_training: {
      switchLabel: 'Training Starting',
      switchDescription: 'This model is starting the training process',
    },
    failed_training: {
      switchLabel: 'Failed',
      switchDescription: 'This model cannot be deployed',
    },
    deploying: {
      switchLabel: 'Deploying',
      switchDescription: 'This model is being deployed',
    },
    deployed: {
      switchLabel: 'Deployed',
      switchDescription: 'This model is deployed',
    },
    undeploying: {
      switchLabel: 'Undeploying',
      switchDescription: 'This model is being un-deployed',
    },
    training: {
      switchLabel: 'Training',
      switchDescription: 'This model is still training',
    },
    failed: {
      switchLabel: 'Failed',
      switchDescription: 'This model cannot be deployed',
    },
    dormant: {
      switchLabel: 'Ready to Deploy',
      switchDescription: 'This Model is ready to be deployed',
    },
    failed_deploy: {
      switchLabel: 'Failed to deploy',
      switchDescription: 'This model could not be deployed, try again',
    },
    failed_undeploy: {
      switchLabel: 'Failed to undeploy',
      switchDescription: 'This model could be un-deployed at the moment',
    },
  };

  const findSelfHostedServerName = (id) => {
    const server = selfHostedServers.find((server) => server.id === id);
    return server ? server.server_name : 'Tromero';
  };

  return (
    <>
      {/* desktop */}
      <div className="hidden w-full md:block">
        {deployList.map((model, index) => (
          <div
            aria-roledescription="button"
            key={model.model_id + ' ' + index}
            className={clsx(
              'flex items-start justify-between w-full p-4 m-1 border rounded-md border-zinc-300 relative min-h-32 my-6',
              selectedModel?.model_id === model.model_id
                ? 'bg-zinc-100 ring-2 ring-indigo-400 '
                : 'bg-white',
            )}
            onClick={() => handleSetSelectedModel(model)}
          >
            <div className="grid w-full grid-cols-6 ">
              {model.self_hosted &&
                (model.state === 'deployed' ||
                  model.state === 'failed_undeploy') && (
                  <div className="absolute px-2 py-1 text-xs bg-blue-200 rounded-md bottom-1 left-1">
                    Self Hosted:{' '}
                    {findSelfHostedServerName(model.self_hosted_server_id)}
                  </div>
                )}
              <div className="self-start max-w-full col-span-2 text-lg font-medium text-left break-words text-zinc-800 text-wrap md:self-center">
                <div className="w-full overflow-hidden whitespace-nowrap text-ellipsis">
                  {model.model_name}
                </div>
                <div className="mt-1 text-sm font-light">
                  Base Model: {model?.base_model_data?.display_name}
                </div>
                <div className="text-sm font-light">
                  Training Data: {getLogMessage(model)}
                </div>
              </div>
              <div className="flex items-start text-sm text-zinc-600 justify-self-center">
                {new Date(model.created_at).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                })}
              </div>
              <div className="flex items-start text-sm text-zinc-600 justify-self-center">
                {model.last_deployed_on
                  ? new Date(model.last_deployed_on).toLocaleDateString(
                      'en-US',
                      {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                      },
                    )
                  : null}
              </div>
              <div className="flex items-start text-sm text-zinc-600 justify-self-center">
                {model.last_used
                  ? new Date(model.last_used).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                    })
                  : null}
              </div>
              <div className="flex items-start text-sm text-zinc-600 justify-self-end ">
                <Switch.Group
                  as="div"
                  className="flex items-center justify-between"
                >
                  <span className="absolute flex flex-col flex-grow text-right text-wrap bottom-4 right-4">
                    <Switch.Label
                      as="span"
                      className="text-sm font-medium leading-6 text-right text-gray-900"
                      passive
                    >
                      {modelStateMessage[model.state]?.switchLabel}
                    </Switch.Label>
                    <Switch.Description
                      as="span"
                      className="text-sm text-left text-gray-500 "
                    >
                      {modelStateMessage[model.state]?.switchDescription}
                    </Switch.Description>
                  </span>
                  <Switch
                    checked={
                      model.state === 'deployed' ||
                      model.state === 'failed_undeploy'
                    }
                    onClick={(e) => toggleDeployStatus(e, model)}
                    disabled={
                      model.state === 'deploying' ||
                      model.state === 'undeploying'
                    }
                    className={clsx(
                      model.state === 'deployed'
                        ? 'bg-zinc-800'
                        : 'bg-gray-400',
                      'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-zinc-800 focus:ring-offset-2  disabled:cursor-default disabled:border disabled:border-gray-200',
                      model.state === 'failed_undeploy'
                        ? 'disabled:bg-zinc-300'
                        : 'disabled:bg-gray-100',
                    )}
                  >
                    <span
                      aria-hidden="true"
                      className={clsx(
                        model.state === 'deployed' ||
                          model.state === 'failed_undeploy'
                          ? 'translate-x-5'
                          : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out shadow-zinc-400',
                      )}
                    />
                  </Switch>
                </Switch.Group>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* mobile */}
      <div className="w-full md:hidden">
        {deployList.map((model) => (
          <div
            aria-roledescription="button"
            key={model.model_id}
            className={clsx(
              'flex flex-col w-[98%] px-4 py-2 m-1 border rounded-md border-zinc-300 min-h-44 relative',
              selectedModel?.model_id === model.model_id
                ? 'bg-zinc-100 ring-2 '
                : 'bg-white',
            )}
            onClick={() => handleSetSelectedModel(model)}
          >
            {model.self_hosted &&
              (model.state === 'deployed' ||
                model.state === 'failed_undeploy') && (
                <>
                  <div className="absolute top-2 right-2 bg-blue-200 text-xs py-1 px-2 rounded-md max-w-[30%]">
                    Self Hosted:
                    <div className="w-full overflow-hidden text-right whitespace-nowrap text-ellipsis">
                      {findSelfHostedServerName(model.server_id)}
                    </div>
                  </div>
                </>
              )}
            <div className="font-medium text-left text-zinc-800 text-wrap ">
              <div
                className={clsx(
                  'whitespace-nowrap overflow-hidden text-ellipsis w-full text-base',
                  model.self_hosted &&
                    (model.state === 'deployed' ||
                      model.state === 'failed_undeploy') &&
                    '!w-[65%]',
                )}
              >
                {model.model_name}
              </div>
              <div className="mt-1 text-xs font-light">
                Base Model: {model?.model_config?.base_model}
              </div>
              <div className="flex-grow text-xs font-light">
                Training Data: {getLogMessage(model)}
              </div>{' '}
            </div>
            <div className="mt-2 text-xs">
              <div className="flex items-start text-zinc-600 justify-self-center md:items-center">
                Created on:{' '}
                {new Date(model.created_at).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                })}
              </div>
            </div>
            <div className="text-xs grow">
              <div className="flex items-start text-zinc-600 justify-self-center md:items-center">
                Last Used:{' '}
                {new Date(model.created_at).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                })}
              </div>
            </div>
            <Switch.Group
              as="div"
              className="flex items-center justify-between w-full"
            >
              <span className="flex flex-col flex-grow">
                <Switch.Label
                  as="span"
                  className="text-sm font-medium leading-6 text-left text-gray-900 capitalize"
                  passive
                >
                  {model.state}
                </Switch.Label>
                <Switch.Description
                  as="span"
                  className="text-sm text-left text-gray-500"
                >
                  {modelStateMessage[model.state]?.bigMessage}
                </Switch.Description>
              </span>

              <Switch
                checked={
                  model.state === 'deployed' ||
                  model.state === 'failed_undeploy'
                }
                onClick={(e) => toggleDeployStatus(e, model)}
                disabled={
                  model.state === 'deploying' || model.state === 'undeploying'
                }
                className={clsx(
                  model.state === 'deployed' ? 'bg-zinc-800' : 'bg-gray-400',
                  'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-zinc-800 focus:ring-offset-2 disabled:cursor-default',
                  model.state === 'failed_undeploy'
                    ? 'disabled:bg-zinc-300'
                    : 'disabled:bg-gray-100',
                )}
              >
                <span
                  aria-hidden="true"
                  className={clsx(
                    model.state === 'deployed' ||
                      model.state === 'failed_undeploy'
                      ? 'translate-x-5'
                      : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                  )}
                />
              </Switch>
            </Switch.Group>
          </div>
        ))}
      </div>
      <hr className="w-full mt-2 border-zinc-300" />
    </>
  );
};

export default ModelList;
