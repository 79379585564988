import { useState, Fragment, useEffect } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import toast from 'react-hot-toast';

import FilteredListings from './FilteredListings';

const TabsContainer = ({
  listings,
  selectedRental,
  handleSelectRental,
  selectedOsTemplate,
  minGpuCount,
  handleUnselectRental,
  preselected,
}) => {
  /**
   * The available GPUs that can be selected. The GPUs are sorted by availability and compatibility
   * with the selected OS template. If there is a specific os template selected, the GPU will be
   * marked as incompatible if it is not compatible with the template.
   * @type {Array}
   * @property {String} name - The name of the GPU.
   * @property {Set} gpus - The number of GPUs available for the GPU, a set of numbers from the minimum to the maximum number of GPUs available for the GPU.
   * @property {String} available - The availability of the GPU, can be 'available', 'incompatible', 'coming-soon', or 'in-use'.
   */
  const availableGpus = [
    // {
    //   name: 'NVIDIA RTX A4000',
    //   gpus: new Set(),
    //   available: `${selectedOsTemplate === 'fsdp' ? 'incompatible' : ''}`,
    // },
    {
      name: 'NVIDIA RTX A6000',
      gpus: new Set(),
      available: `${selectedOsTemplate === 'text_generation' ? 'incompatible' : ''}`,
    },
    // {
    //   name: 'NVIDIA GeForce RTX 4090',
    //   gpus: new Set(),
    //   available: `${selectedOsTemplate === 'fsdp' ? 'incompatible' : ''}`,
    // },
    {
      name: 'NVIDIA H100',
      gpus: new Set(),
      available: '',
    },
  ];

  const [filterCriteria, setFilterCriteria] = useState({
    name: 'Select a GPU type',
    gpus: 'Select the number of GPUs',
  });
  const [filteredListings, setFilteredListings] = useState([]);
  const criteriaThatMakesAListingUnique = [
    'normalized_gpu_type',
    'price_per_gpu',
    'host_ram',
    'cpu_name',
    'disk_size',
    'gpu_memory',
  ];

  /**
   * Reduces a list of listings into a unique set of GPU cards, ensuring that the number of GPUs
   * per card are sorted from low to high, the cards are sorted by availability, and that the cards
   * are unique. It also takes into account whether the selected OS template is compatible with the
   * card.
   * @param {Array} listings - The array of listings to be processed.
   * @param {Array} availableGpus - The initial state of available GPUs.
   * @param {Number} minGpuCount - The minimum number of GPUs that can be selected.
   * @returns {Array} The unique cards with sorted GPUs.
   */
  const uniqueCards = listings.reduce(
    (acc, listing) => {
      const gpuIndex = acc.findIndex(
        (gpu) => gpu.name === listing.normalized_gpu_type,
      );
      if (gpuIndex === -1) {
        acc.push({
          name: listing.normalized_gpu_type,
          gpus: new Set(
            Array.from(
              { length: parseInt(listing.available_gpus) - minGpuCount + 1 },
              (_, i) => i + minGpuCount,
            ),
          ),
          available: 'available',
        });
      } else {
        const arrayOfGpus = Array.from(
          { length: parseInt(listing.available_gpus) - minGpuCount + 1 },
          (_, i) => i + minGpuCount,
        );
        if (acc[gpuIndex].available !== 'incompatible') {
          arrayOfGpus.forEach((gpu) => {
            acc[gpuIndex].gpus.add(gpu);
          });
        }
        acc[gpuIndex].available = 'available';
      }
      return acc;
    },
    [...availableGpus],
  );

  // sorting the available ones on top
  const uniqueCardsSorted = uniqueCards.sort((a, b) => {
    if (a.available === 'available' && b.available !== 'available') {
      return -1;
    }
    if (a.available !== 'available' && b.available === 'available') {
      return 1;
    }
    return 0;
  });

  useEffect(() => {}, [uniqueCardsSorted]);

  // -> setting the default criteria to the first listing available, with the lowest number of gpus
  useEffect(() => {
    const first = uniqueCardsSorted.find((data) => {
      return data.available === 'available';
    });
    if (first) {
      setFilterCriteria({
        name: 'Select a GPU type',
        gpus: 'Select the number of GPUs',
      });
      setFilteredListings(uniqueListings('', null));
    } else {
      setFilterCriteria({
        name: 'All Listings Currently in Use',
        gpus: null,
      });
      setFilteredListings([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listings]);

  useEffect(() => {
    if (
      preselected?.isValid &&
      preselected?.firstRender.current &&
      uniqueCards &&
      listings?.length > 0
    ) {
      const card = uniqueCardsSorted.find((data) => {
        const cardName = data.name.toLowerCase();
        return cardName.includes(preselected.gpu);
      });
      if (card) {
        if (card.available === 'incompatible') {
          preselected.firstRender.current = false;
          toast.error(
            'The selected machine is incompatible with the selected template.',
          );
        } else {
          const isGpuNumberAvailable = card.gpus.has(
            parseInt(preselected.count),
          );
          if (isGpuNumberAvailable) {
            setFilterCriteria({ name: card.name, gpus: preselected.count });
            setFilteredListings(uniqueListings(card.name, preselected.count));
            preselected.firstRender.current = false;
          } else {
            if (filteredListings.length > 0) {
              preselected.count &&
                toast.error(
                  'The selected GPU count is not available for the selected machine.',
                );
              preselected.firstRender.current = false;
            }
            if (card.gpus.size === 0) {
              preselected.firstRender.current = false;
              toast.error('The selected machine is not available.');
            } else {
              setFilterCriteria({
                name: card.name,
                gpus: Math.min(...card.gpus),
              });
              setFilteredListings(
                uniqueListings(card.name, Math.min(...card.gpus)),
              );
            }
          }
          const element = document.getElementById('tabs-container');
          if (element) {
            const elementPosition =
              element.getBoundingClientRect().top + window.scrollY;

            window.scrollTo({
              top: elementPosition - window.innerHeight / 3.25,
              behavior: 'smooth',
            });
          }
        }
      } else {
        preselected.firstRender.current = false;
        toast.error('The selected machine is not available.');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listings, uniqueCards]);

  const handleSelectCardName = (value) => {
    handleUnselectRental();
    const card = uniqueCardsSorted.find((data) => data.name === value);
    if (card) {
      // Check if the previously selected number of GPUs is available for the new machine
      const isGpuNumberAvailable = card.gpus.has(filterCriteria.gpus);

      // Set the GPU number to either the previously selected number (if available) or the smallest number available for the new machine
      const gpuNumberToSet = isGpuNumberAvailable
        ? filterCriteria.gpus
        : Math.min(...card.gpus);

      setFilterCriteria({ name: value, gpus: gpuNumberToSet });
      setFilteredListings(uniqueListings(value, gpuNumberToSet));
    }
  };

  const handleSelectGpus = (value) => {
    handleUnselectRental();
    setFilterCriteria({ name: filterCriteria.name, gpus: value });
    setFilteredListings(uniqueListings(filterCriteria.name, value));
  };

  const uniqueListings = (name, gpus) => {
    const uniqueListingsMap = new Map();
    listings.forEach((listing) => {
      if (
        listing.normalized_gpu_type === name &&
        listing.available_gpus >= gpus
      ) {
        listing.total_price = listing.price_per_gpu * gpus;
        listing.gpus = gpus;
        let uniqueIdentifier = '';
        for (const criteria of criteriaThatMakesAListingUnique) {
          uniqueIdentifier += `${listing[criteria]}-`;
        }
        uniqueIdentifier += listing.cluster.kyc_required;
        uniqueIdentifier += listing.region.split('_').join(' ');
        uniqueListingsMap.set(uniqueIdentifier, listing);
      }
    });

    let finalListings = Array.from(uniqueListingsMap.values());
    finalListings.sort((a, b) => a.total_price - b.total_price);

    return finalListings;
  };

  return (
    <>
      <div className="flex flex-col w-full my-12 rounded-[12px] wavyslow shadow-3xinner ">
        <div className="absolute inset-0 hidden rounded-[12px] bg-gray-800/80 dark:block"></div>
        <div className="z-10 h-full m-1 rounded-[8px] bg-white/90 dark:bg-gray-800/70">
          <div className="flex flex-row items-center justify-between">
            <h1
              className="relative -top-5 left-7 text-2xl italic tracking-wide text-slate-800 font-extralight px-0.5 backdrop-blur-lg dark:text-gray-100 rounded-full"
              id="configure-instance-title"
            >
              Configure Instance
            </h1>
          </div>
          <div className="flex justify-between">
            <div className="FILTERS"></div>
          </div>

          <div className="flex w-full font-sans">
            <div className="flex flex-col w-full px-2 mx-auto my-10 880:flex-row gap-y-8">
              <div className="flex justify-center w-full ">
                <div className="px-4 grow">
                  <div className="ml-2 dark:text-white">GPU Type</div>
                  <Listbox
                    value={filterCriteria.name}
                    onChange={handleSelectCardName}
                  >
                    <div className="relative mt-1">
                      <Listbox.Button className="relative w-full h-12 pl-3 pr-10 mx-auto text-left bg-white border rounded-md shadow-md cursor-default min-w-96 focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 dark:text-white dark:bg-gray-700">
                        <span className="block truncate">
                          {listings.length > 0
                            ? filterCriteria.name
                            : 'All Listings Currently in Use'}
                        </span>
                        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                          <ChevronDownIcon
                            className="w-5 h-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>
                      <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute z-50 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg min-w-96 max-h-60 ring-1 ring-black/5 focus:outline-none dark:text-white dark:bg-gray-700">
                          {uniqueCardsSorted.map((card, Idx) => (
                            <Listbox.Option
                              key={Idx}
                              disabled={card.available !== 'available'}
                              className={({ active }) =>
                                `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                  active
                                    ? 'bg-gray-900 text-gray-100 '
                                    : 'text-gray-900 dark:text-white'
                                }`
                              }
                              value={card.name}
                            >
                              <span
                                className={`block truncate ${
                                  card.available !== 'available'
                                    ? 'text-gray-400'
                                    : ''
                                }`}
                              >
                                {card.name}
                                <span className="text-xs">
                                  {`${card.available === 'incompatible' ? ' - Template Incompatible' : ''}`}
                                  {/* {`${card.available === 'coming-soon' ? ' - (Coming Soon)' : card.available === 'in-use' ? ' - (All Machines Are Currently in Use - Try Again Later)' : card.available === 'incompatible' ? ' - Template Incompatible' : ''}`} */}
                                </span>
                              </span>
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </Listbox>
                </div>
              </div>
              <div className="flex justify-center w-full ">
                <div className="px-4 grow">
                  <div className="ml-2 dark:text-white">GPU Count</div>
                  <Listbox
                    value={filterCriteria.gpus}
                    onChange={handleSelectGpus}
                  >
                    <div className="relative mt-1">
                      <Listbox.Button className="relative w-full h-12 pl-3 pr-10 mx-auto text-left bg-white border rounded-md shadow-md cursor-default min-w-96 focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 dark:text-white dark:bg-gray-700">
                        <span className="block truncate">
                          {filterCriteria.gpus !== 0
                            ? filterCriteria.gpus
                            : '-'}
                        </span>
                        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                          <ChevronDownIcon
                            className="w-5 h-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>
                      <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute z-50 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg min-w-96 max-h-60 ring-1 ring-black/5 focus:outline-none dark:text-white dark:bg-gray-700">
                          {uniqueCardsSorted &&
                            filterCriteria?.gpus &&
                            uniqueCardsSorted.find(
                              (data) => data.name === filterCriteria.name,
                            )?.gpus &&
                            Array.from(
                              uniqueCardsSorted.find(
                                (data) => data.name === filterCriteria.name,
                              ).gpus,
                            ).map((item, Idx) => (
                              <Listbox.Option
                                key={Idx}
                                className={({ active }) =>
                                  `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                    active
                                      ? 'bg-gray-900 text-gray-100'
                                      : 'text-gray-900 dark:text-white'
                                  }`
                                }
                                value={item}
                              >
                                <span className={`block truncate`}>{item}</span>
                              </Listbox.Option>
                            ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </Listbox>
                </div>
              </div>
            </div>
          </div>

          {/* Scrollable content section */}
          <div id="tabs-container-original" className="">
            <div id="myTabContent">
              {/* <button className="flex items-center justify-center px-4 py-2 mx-auto mb-4 font-sans rounded-md shadow-xl bg-white/50 hover:bg-white">
                Compare <ChevronRightIcon className="flex w-5 h-5 ml-1" />
              </button> */}

              <FilteredListings
                filteredListings={filteredListings}
                selectedRental={selectedRental}
                handleSelectRental={handleSelectRental}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TabsContainer;
