import { toast } from 'react-hot-toast';
import { loadStripe } from '@stripe/stripe-js';
import { useEffect } from 'react';

import { useUser } from '../UserContext';
import {
  areAllCreditCardsExpired,
  checkTokenExpiration,
  isCardAboutToExpire,
} from '../utils/userUtils';
import usePaymentLink from '../Hooks/usePaymentLink';
import Spinner from './Spinner';

const stripe_publishable_key = import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY;
const stripePromise = loadStripe(stripe_publishable_key);

const UnderNavbarBanner = () => {
  const { user, customAxios, fetchAndUpdateUserDetails } = useUser();
  const { loading, error, redirectToPaymentLink } = usePaymentLink({
    url: user?.payment_link,
  });

  useEffect(() => {
    if (!user || checkTokenExpiration()) {
      return;
    }
    const updateUserDetails = async () => {
      await fetchAndUpdateUserDetails();
    };
    updateUserDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResendConfirmationEmail = async () => {
    if (!user?.email) {
      toast.warn('We could not find your email address');
      return;
    }
    try {
      await fetchAndUpdateUserDetails();
      if (user.confirmed) {
        toast.warn('You have already confirmed your email');
        return;
      }
      const response = await customAxios.post('resend_confirmation', {
        email: user.email,
      });

      if (response.status === 200) {
        toast.success('Confirmation email Sent');
      }
    } catch (error) {
      if (import.meta.env.DEV) {
        console.error('Error resending confirmation email:', error);
      }
      if (error.code === 'users.resend_confirmation.already_confirmed') {
        toast.warn('You have already confirmed your email', {
          id: 'already-confirmed',
        });
        await fetchAndUpdateUserDetails();
        return;
      } else if (error.code === 'ERR_NETWORK') {
        toast.error('Network error. Please try again later.');
        return;
      } else {
        toast.error('Error Sending Confirmation. Please Try Again', {
          id: 'error-sending-confirmation',
        });
      }
    }
  };

  const handleSettleBalance = async () => {
    redirectToPaymentLink();
    if (error) {
      toast.error(error);
    }
  };

  const handleUpdatePaymentMethod = async () => {
    const stripe = await stripePromise;

    try {
      const response = await customAxios.post('create-session', {});

      await fetchAndUpdateUserDetails();
      const session = response.data;

      const result = await stripe.redirectToCheckout({
        sessionId: session.session_id,
      });
      if (result.error) {
        throw new Error(result.error.message);
      }
    } catch (error) {
      console.error('Error during checkout:', error);
      if (error.code === 'ERR_NETWORK') {
        toast.error('Network error. Please try again later.');
        return;
      }
      if (error.code === 'no-valid-token') {
        return;
      }
      toast.error('Error during checkout');
    }
  };

  return (
    <div className="w-full font-spaceG">
      {user && !user.confirmed && (
        <div className="w-full border-gray-50 dark:border-y-0 bg-gradient-to-t from-transparent to-indigo-200 dark:to-[#5b496f]/90 to-80% dark:to-50% lg:px-8 border-y">
          <div className="flex flex-wrap items-baseline justify-between h-16 gap-2 px-6 mx-auto text-sm text-left max-w-7xl lg:px-8 xl:pr-16">
            <p className="flex items-center justify-start h-full dark:text-gray-200">
              Email confirmation required: please check your email inbox for a
              confirmation link.
            </p>
            <button
              onClick={handleResendConfirmationEmail}
              className="px-2 py-1 text-gray-800 border border-gray-100 rounded-md shadow-md dark:shadow active:border-gray-200 dark:border-transparent bg-gray-50 active:bg-gray-50 active:shadow-inner hover:bg-grey-100 dark:bg-gray-700 dark:active:bg-gray-800 dark:text-gray-200 dark:shadow-gray-800 "
            >
              Resend Confirmation Email
            </button>
          </div>
        </div>
      )}
      {/* {user && user.delinquent && (
        <div className="w-full border-gray-50 dark:border-y-0 bg-gradient-to-t from-transparent to-red-200 dark:to-red-400/90 to-80% dark:to-40% lg:px-8 border-y">
          <div className="flex flex-wrap items-baseline justify-between h-16 gap-2 px-6 mx-auto text-sm text-left max-w-7xl lg:px-8 xl:pr-16">
            <p className="flex items-center justify-start h-full">
              You have an outstanding balance
            </p>
            <button
              onClick={handleSettleBalance}
              className="flex items-center justify-around w-32 h-8 text-gray-800 border border-gray-100 rounded-md shadow-md min-w-32 dark:shadow active:border-gray-200 dark:border-transparent bg-gray-50 active:bg-gray-50 active:shadow-inner hover:bg-grey-100 dark:bg-gray-700 dark:active:bg-gray-800 dark:text-gray-200 dark:shadow-gray-800"
            >
              {loading ? (
                <Spinner size={'18px'} borderTopColor={'gray'} />
              ) : (
                'Settle Balance'
              )}
            </button>
          </div>
        </div>
      )} */}
      {user &&
        (isCardAboutToExpire(user.payment_methods) ||
          areAllCreditCardsExpired(user.payment_methods)) && (
          <div className="w-full border-gray-50 dark:border-y-0 bg-gradient-to-t from-transparent to-[#3c9eb7]/60 dark:to-to-[#3c9eb7] to-80% dark:to-40% lg:px-8 border-y">
            <div className="flex flex-wrap items-baseline justify-between h-16 gap-2 py-3 mx-auto text-sm text-left max-w-7xl lg:px-8 xl:pr-16">
              <p className="flex items-center justify-start h-full dark:text-gray-200">
                {isCardAboutToExpire(user.payment_methods)
                  ? 'Your saved payment method will expire soon.'
                  : 'Your saved payment method has expired.'}
              </p>
              <button
                onClick={handleUpdatePaymentMethod}
                className="px-2 py-1 text-gray-800 border border-gray-100 rounded-md shadow-md dark:shadow active:border-gray-200 dark:border-transparent bg-gray-50 active:bg-gray-50 active:shadow-inner hover:bg-grey-100 dark:bg-gray-700 dark:active:bg-gray-800 dark:text-gray-200 dark:shadow-gray-800"
              >
                Update Payment Method
              </button>
            </div>
          </div>
        )}
    </div>
  );
};

export default UnderNavbarBanner;
