const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 451 260.81"
    width="24"
    height="24"
    {...props}
  >
    <defs>
      <style>
        {
          '.cls-1{fill:#ffffff}.cls-1,.cls-2,.cls-3,.cls-5,.cls-6,.cls-8,.cls-9{stroke:#dce0df;stroke-linejoin:round}.cls-2{fill:#ffffff}.cls-3{fill:#ffffff}.cls-5{fill:#ffffff}.cls-6{fill:#ffffff}.cls-8{fill:#ffffff}.cls-9{fill:#ffffff}'
        }
      </style>
    </defs>
    <title>{'JAX Light Stroke'}</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path d="m50.5 130.4-25 43.31h50l25-43.31h-50z" className="cls-1" />
        <path
          d="m.5 217.01 25-43.3h50l-25 43.3H.5zM125.5 173.71h-50l-25 43.3h50l25-43.3z"
          className="cls-1"
        />
        <path d="M175.5 173.71h-50l-25 43.3h50l25-43.3z" className="cls-1" />
        <path
          d="m150.5 130.4-25 43.31h50l25-43.31h-50zM175.5 87.1l-25 43.3h50l25-43.3h-50z"
          className="cls-1"
        />
        <path
          d="m200.5 43.8-25 43.3h50l25-43.3h-50zM225.5.5l-25 43.3h50l25-43.3h-50z"
          className="cls-1"
        />
        <path d="m.5 217.01 25 43.3h50l-25-43.3H.5z" className="cls-2" />
        <path d="M125.5 260.31h-50l-25-43.3h50l25 43.3z" className="cls-2" />
        <path d="M175.5 260.31h-50l-25-43.3h50l25 43.3z" className="cls-2" />
        <path
          d="m200.5 217.01-25-43.3-25 43.3 25 43.3 25-43.3zM250.5 130.4l-25-43.3-25 43.3h50zM250.5 43.8l-25 43.3 25 43.3 25-43.3-25-43.3z"
          className="cls-3"
        />
        <path
          d="m125.5 173.71-25-43.31-25 43.31h50z"
          style={{
            fill: '#ffffff',
            stroke: '#ffffff',
            strokeLinejoin: 'round',
          }}
        />
        <path d="M250.5 130.4h-50l-25 43.31h50l25-43.31z" className="cls-5" />
        <path d="M300.5 130.4h-50l-25 43.31h50l25-43.31z" className="cls-5" />
        <path
          d="M350.5 43.8 325.5.5l-25 43.3 25 43.3 25-43.3zM375.5 87.1l-25-43.3-25 43.3 25 43.3 25-43.3zM400.5 130.4l-25-43.3-25 43.3 25 43.31 25-43.31zM425.5 173.71l-25-43.31-25 43.31 25 43.3 25-43.3z"
          className="cls-6"
        />
        <path
          d="m450.5 217.01-25-43.3-25 43.3 25 43.3 25-43.3zM425.5.5l-25 43.3 25 43.3 25-43.3-25-43.3z"
          className="cls-6"
        />
        <path
          d="m375.5 87.1 25-43.3 25 43.3-25 43.3-25-43.3zM350.5 130.4l-25 43.31 25 43.3 25-43.3-25-43.31z"
          className="cls-6"
        />
        <path
          d="m325.5 260.31-25-43.3 25-43.3 25 43.3-25 43.3z"
          className="cls-6"
        />
        <path
          d="m275.5 260.31-25-43.3h50l25 43.3h-50z"
          style={{
            fill: '#ffffff',
            stroke: '#ffffff',
            strokeLinejoin: 'round',
          }}
        />
        <path d="M225.5 173.71h-50l25 43.3h50l-25-43.3z" className="cls-8" />
        <path
          d="M275.5 173.71h-50l25 43.3 25-43.3zM275.5 87.1l25 43.3h50l-25-43.3h-50z"
          className="cls-8"
        />
        <path
          d="M300.5 43.8h-50l25 43.3h50l-25-43.3zM425.5 260.31l-25-43.3h-50l25 43.3h50zM375.5 173.71l-25 43.3h50l-25-43.3z"
          className="cls-8"
        />
        <path
          d="M325.5.5h-50l-25 43.3h50l25-43.3zM325.5 173.71h-50l-25 43.3h50l25-43.3z"
          className="cls-9"
        />
        <path
          d="M350.5 130.4h-50l-25 43.31h50l25-43.31zM425.5.5h-50l-25 43.3h50l25-43.3zM375.5 87.1l-25-43.3h50l-25 43.3z"
          className="cls-9"
        />
      </g>
    </g>
  </svg>
);

export default SvgComponent;
