import { Modal } from 'flowbite-react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from '@formspree/react';
import toast from 'react-hot-toast';
import clsx from 'clsx';

import { useUser } from '../../../UserContext';

const FineTuneCreateErrorModal = ({ showModal, closeModal, errorCode }) => {
  const { user } = useUser();
  const [validationError, setValidationError] = useState('');
  const [state, handleSubmit] = useForm('xqkrrdkn');
  const [message, setMessage] = useState(
    <div className="inline-block">
      <span>We tried to start the training job but </span>
      <span>
        something went wrong. Please try again or contact support if the problem
        persists.
      </span>
    </div>,
  );

  const submitContactForm = async (e) => {
    e.preventDefault();
    const provided_contact_info = !!e.target[0].value;
    if (!provided_contact_info) {
      setValidationError('Please provide your email or phone number.');
      return;
    }
    const form_data = new FormData();
    form_data.append('first_name', user.first_name);
    form_data.append('last_name', user.last_name);
    form_data.append('provided_contact_info', e.target[0].value);

    try {
      await handleSubmit(form_data);
      toast.success('Your contact information has been submitted.');
    } catch (error) {
      toast.error('An error occurred. Please try again.');
      if (import.meta.env.DEV) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    switch (errorCode) {
      case 'delinquent_user':
        if (user?.team_role === 'admin') {
          setMessage(
            <>
              <div className="inline-block">
                <span>
                  It seems that you have an outstanding balance, please{' '}
                </span>
                <span>continue to the Billing page to resolve this issue.</span>
              </div>

              <div className="pt-4 flex gap-6">
                <Link
                  className="flex items-center justify-center px-4 w-fit h-10 bg-indigo-200 rounded-md shadow text-zinc-900 hover:bg-indigo-100"
                  to="/tailor/settings#billing"
                  target="_blank"
                  onClick={closeModal}
                >
                  To Billing
                </Link>
                <button
                  className="bg-zinc-900 text-white px-4 py-2 rounded-md shadow hover:bg-zinc-800"
                  onClick={closeModal}
                >
                  Close
                </button>
              </div>
            </>,
          );
        } else if (user?.team_role === 'member') {
          setMessage(
            <>
              <div className="inline-block">
                <span>
                  It seems that the account has an outstanding balance, please{' '}
                </span>
                <span>contact your team admin to resolve this issue.</span>
              </div>
            </>,
          );
        }
        break;
      case 'node.missing':
        setMessage(
          <>
            <div className="inline-block">
              <div>
                We tried to start a fine-tuning job for you with some of our
                machines from the marketplace, unfortunately, all of them are
                currently in use.
              </div>
              <div className="pt-4">
                We are constantly adding new machines to the marketplace and
                feel terrible that you have to wait.
              </div>
              <div className="pt-4">
                We would like to get in touch with you to discuss how we can
                help you with your training needs. If you would like to be
                contacted, please provide your email address or phone number
                below. We will reach out to you as soon as possible.
              </div>
              <div className="pt-4">
                <form
                  className="flex gap-1 relative"
                  onSubmit={submitContactForm}
                >
                  <input
                    className={clsx(
                      'w-full p-2 border border-gray-300 rounded-l-md placeholder:text-sm md:placeholder:text-base',
                      validationError && '!border-red-500',
                    )}
                    placeholder="Email or Phone Number"
                    name="provided_contact_info"
                    onFocus={() => setValidationError('')}
                  />
                  {validationError && (
                    <div className="text-red-500 text-xs md:text-sm absolute -bottom-5 left-0">
                      {validationError}
                    </div>
                  )}
                  <button
                    className="bg-zinc-900 text-white px-4 py-2 rounded-r-md shadow hover:bg-zinc-800 text-sm md:text-base disabled:opacity-50"
                    type="submit"
                    disabled={state.submitting}
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
            <div className="pt-8">
              <button
                className="bg-zinc-900 text-white px-4 py-2 rounded-md shadow hover:bg-zinc-800"
                onClick={closeModal}
              >
                Close
              </button>
            </div>
          </>,
        );
        break;
      case 'pods.create.no_payment_method':
      case 'no_payment_method':
        if (user?.team_role === 'admin') {
          setMessage(
            <>
              <div className="inline-block">
                <span>
                  It seems that you don't have a payment method on file, please
                  continue to the Billing page to set up a payment method.
                </span>
              </div>

              <div className="pt-4 flex gap-6">
                <Link
                  className="flex items-center justify-center px-4 w-fit h-10 bg-indigo-200 rounded-md shadow text-zinc-900 hover:bg-indigo-100"
                  to="/tailor/settings#billing"
                  target="_blank"
                  onClick={closeModal}
                >
                  To Billing
                </Link>
                <button
                  className="bg-zinc-900 text-white px-4 py-2 rounded-md shadow hover:bg-zinc-800"
                  onClick={closeModal}
                >
                  Close
                </button>
              </div>
            </>,
          );
        } else if (user?.team_role === 'member') {
          setMessage(
            <>
              <div className="inline-block">
                <span>
                  It seems that the account doesn't have a payment method on
                  file, please{' '}
                </span>
                <span>contact your team admin to resolve this issue.</span>
              </div>
            </>,
          );
        }
        break;
      case 'insufficient_credits':
        if (user?.team_role === 'admin') {
          setMessage(
            <>
              <div className="inline-block">
                <span>
                  It seems that you don't have enough credits to start the
                  training job, please{' '}
                </span>
                <span>continue to the Billing page to add more credits.</span>
              </div>

              <div className="pt-4 flex gap-6">
                <Link
                  className="flex items-center justify-center px-4 w-fit h-10 bg-indigo-200 rounded-md shadow text-zinc-900 hover:bg-indigo-100"
                  to="/tailor/settings#billing"
                  target="_blank"
                  onClick={closeModal}
                >
                  To Billing
                </Link>
                <button
                  className="bg-zinc-900 text-white px-4 py-2 rounded-md shadow hover:bg-zinc-800"
                  onClick={closeModal}
                >
                  Close
                </button>
              </div>
            </>,
          );
        } else if (user?.team_role === 'member') {
          setMessage(
            <>
              <div className="inline-block">
                <span>
                  It seems that the account doesn't have enough credits to start
                  the training job, please{' '}
                </span>
                <span>contact your team admin to resolve this issue.</span>
              </div>
            </>,
          );
        }
        break;
      default:
        setMessage(
          <div className="inline-block">
            <span>We tried to start the training job but </span>
            <span>
              something went wrong. Please try again or contact support if the
              problem persists.
            </span>
            <div className="pt-6">
              <button
                className="bg-zinc-900 text-white px-4 py-2 rounded-md shadow hover:bg-zinc-800"
                onClick={closeModal}
              >
                Close
              </button>
            </div>
          </div>,
        );
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCode, validationError]);

  return (
    <Modal show={showModal} onClose={closeModal} title="Error" dismissible>
      <Modal.Header>
        <div className="">Something Happened</div>
      </Modal.Header>
      <Modal.Body>
        <div className="text-sm md:text-base">{message}</div>
      </Modal.Body>
      {user?.team_role === 'member' && (
        <Modal.Footer>
          <div className="w-full">
            <div className="flex w-full h-12 space-x-4 text-gray-800 justify-start">
              <button
                onClick={closeModal}
                className="w-40 px-4 py-2 text-gray-100 bg-gray-900 border rounded shadow hover:text-gray-900 hover:bg-gray-100 hover:border-gray-900"
              >
                Close
              </button>
            </div>
          </div>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default FineTuneCreateErrorModal;
