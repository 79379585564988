import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Crisp } from 'crisp-sdk-web';
import { inject } from '@vercel/analytics';
import TagManager from 'react-gtm-module';
import { toast } from 'react-hot-toast';

import { useUser } from './UserContext';
import CommonLayout from './layouts/CommonLayout.jsx';
import BasicLayout from './layouts/BasicLayout.jsx';
// import * as Sentry from '@sentry/react';
import Marketplace from './Pages/Marketplace';
import SignUp from './Pages/SignUp';
import ScrollToTop from './Components/ScrollToTop.jsx';
import SignInModal from './Components/Modals/SignInModal.jsx';
// import MyInstances from './Pages/MyInstances';
import About from './Pages/About.jsx';
import PrivacyPolicy from './Pages/legal/PrivacyPolicy.jsx';
import TandC from './Pages/legal/TandC.jsx';
import Page404 from './Pages/Page404.jsx';
import Articles from './Pages/Articles.jsx';
import ArticleDetail from './Pages/ArticleDetail';
import VerifiedJobs from './Pages/VerifiedJobs';
import ProviderSignup from './Pages/ProviderSignup';
import Confirm from './Pages/Confirm';
import ChangePassword from './Pages/ChangePassword.jsx';
import ForgotPassword from './Pages/ForgotPassword.jsx';
import CheckoutSuccess from './Pages/CheckoutSuccess.jsx';
import ProtectedRouteAdmin from './Components/ProtectedRouteAdmin.jsx';
import NewProviderDashboard from './Pages/NewProviderDashboard.jsx';
import StripeRedirection from './Pages/StripeRedirection.jsx';
import UserDashboard from './Pages/UserDashboard';
import ProtectedRouteLoggedIn from './Components/ProtectedRouteLoggedIn.jsx';
import GetVerifiedModal from './Components/Modals/GetVerifiedModal.jsx';
import GeneratePayment from './Pages/GeneratePayment.jsx';
import Press from './Pages/Press.jsx';
import NewAdminDash from './Pages/NewAdminDash.jsx';
import Toast from './Components/Toast.jsx';
import.meta.env.PROD && TagManager.initialize({ gtmId: 'GTM-NKHXCG7L' });
import Careers from './Pages/Careers.jsx';
import JobPostings from './Pages/JobPostings.jsx';
import JobDetails from './Pages/JobDetails.jsx';
import jobPostingsData from '../jobPostingsData.js';
import ContactUs from './Pages/ContactUs.jsx';
import PartnerWithUs from './Pages/PartnerWithUs.jsx';
import { useCurrencyConverter } from './Hooks/useCurrencyConverter.jsx';
import MultiGpuPage from './Pages/guides/MultiGpuPage.jsx';
import MambaReadyPage from './Pages/guides/MambaOsTemplatePage.jsx';
import AIPolicy from './Pages/legal/AIPolicy.jsx';
import PaymentPolicy from './Pages/legal/PaymentPolicy.jsx';
import FAQPage from './Pages/FAQPage.jsx';
import OauthCallback from './Pages/OauthCallback.jsx';
import Discord from './Pages/Discord.jsx';
import {
  TailorDeploy,
  TailorFinetuning,
  TailorError,
  TailorEvaluate,
  TailorLayout,
  TailorPlayground,
  TailorLogsDatasets,
  TailorApiKeys,
  TailorSelfHosting,
  TailorTrainingMetrics,
  TailorFileFormatTool,
  TailorLocation,
} from './Components/Tailor';
import ScamDetector from './Pages/ScamDetector.jsx';
import CustomImageSetupPage from './Pages/guides/CustomImageGuide.jsx';
import Settings from './Pages/Settings.jsx';
import PlaygroundGame from './Components/PlaygroundGame.jsx';
import PublicPlayground from './Components/Tailor/PublicPlayground.jsx';
import EnterprisePrivacyPolicy from './Pages/legal/EnterprisePrivacyPolicy.jsx';
import PricingPage from './Pages/PricingPage.jsx';
import Organization from './Components/Organization.jsx';
import InvitationsPage from './Pages/InvitationsPage.jsx';
import V2Landing from './V2LandingThings/V2Landing.jsx';
import Models from './Pages/Models.jsx';
import useUploadLogs from './Hooks/useUploadLogs.jsx';
import UploadLogsReportModal from './Components/Tailor/Logs&Datasets/UploadLogsReportModal.jsx';

function App() {
  const { user } = useUser();
  const { report } = useUploadLogs();
  useCurrencyConverter();
  const [showUploadReportModal, setShowUploadReportModal] =
    React.useState(false);

  useEffect(() => {
    if (report.status === 'failed' || report.status === 'success') {
      toast.custom(
        (t) => (
          <div
            className={`${
              t.visible ? 'animate-enter' : 'animate-leave'
            } max-w-md w-full bg-white shadow-lg rounded-md pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
          >
            <div className="flex flex-col justify-evenly p-4 px-8">
              <p className="text-sm font-medium text-gray-900">
                Validation and Upload{' '}
                {`${report.status === 'failed' ? 'Failed' : 'Successful'}`}
              </p>
              <p className="mt-1 text-sm text-gray-500">
                {report.status === 'failed' && report.errorMessage}
              </p>
            </div>
            <div className="flex-1 border-l border-gray-200">
              <button
                onClick={() => setShowUploadReportModal(true)}
                className="flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-indigo-600 border border-transparent rounded-none rounded-r-sm hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              >
                See Report
              </button>
              <button
                onClick={() => toast.dismiss(t.id)}
                className="flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-indigo-600 border border-transparent rounded-none rounded-r-sm hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              >
                Dismiss
              </button>
            </div>
          </div>
        ),
        {
          duration: 20000,
          position: 'top-center',
          id: `validation-failed-${report.id}`,
        },
      );
    }
  }, [report]);

  useEffect(() => {
    if (user) {
      Crisp.configure('0436a4ca-f3f2-49f5-8189-4962d163b5be', {
        autoload: false,
      });
      Crisp.setSafeMode(true);
    }
  }, [user]);

  // initialize analytics for vercel in production
  import.meta.env.PROD && inject();

  return (
    <Router>
      <Toast />
      <SignInModal />
      <UploadLogsReportModal
        showModal={showUploadReportModal}
        closeModal={() => setShowUploadReportModal(false)}
      />
      {user && user?.kyc_status !== 'approved' && (
        <GetVerifiedModal status={user.kyc_status} />
      )}

      <ScrollToTop />
      <Routes>
        <Route element={<BasicLayout />}>
          {/* this layout has nothing in it, its a white page */}
          <Route
            path="/generate_payment_link/:token"
            element={<GeneratePayment />}
          />
          <Route path="/login/github/callback" element={<OauthCallback />} />
          <Route path="/login/google/callback" element={<OauthCallback />} />
          <Route path="/discord" element={<Discord />} />
          <Route path="/jsonl-formatter" element={<TailorFileFormatTool />} />
        </Route>

        {user && (
          <Route element={<TailorLayout />}>
            <>
              {/* <Route path="/" element={<UserDashboard />} /> */}
              <Route path="tailor" element={<UserDashboard />} />
              <Route path="tailor/playground" element={<TailorPlayground />} />
              <Route path="tailor/finetuning" element={<TailorFinetuning />} />
              <Route
                path="tailor/training-metrics"
                element={<TailorTrainingMetrics />}
              />
              <Route path="tailor/settings" element={<Settings />} />
              <Route path="tailor/marketplace" element={<Marketplace />} />
              <Route path="tailor/evaluate" element={<TailorEvaluate />} />
              <Route path="tailor/deploy" element={<TailorDeploy />} />
              <Route path="tailor/deploy/:id" element={<TailorDeploy />} />
              <Route
                path="tailor/logs-datasets"
                element={<TailorLogsDatasets />}
              />
              <Route path="tailor/api-keys" element={<TailorApiKeys />} />
              <Route
                path="tailor/self-hosting"
                element={<TailorSelfHosting />}
              />
              <Route path="tailor/location" element={<TailorLocation />} />
              <Route path="tailor/*" element={<TailorError />} />
            </>
          </Route>
        )}
        <Route element={<CommonLayout />}>
          <Route path="/tailor/*" element={<V2Landing />} />
          <Route path="/" element={<V2Landing />} />
          {/* <Route path="/marketplace" element={<Marketplace />} /> */}
          <Route
            path="/admin"
            element={
              <ProtectedRouteAdmin>
                <NewAdminDash />
              </ProtectedRouteAdmin>
            }
          />
          {/* <Route
            path="/userhome"
            element={
              <ProtectedRouteLoggedIn>
                <UserDashboard />
              </ProtectedRouteLoggedIn>
            }
          /> */}
          <Route
            path="/scam-detection"
            element={
              <ProtectedRouteAdmin>
                <ScamDetector />
              </ProtectedRouteAdmin>
            }
          />
          <Route path="/checkout-success/*" element={<CheckoutSuccess />} />
          <Route path="/reset-password/:token" element={<ChangePassword />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/confirm/:token" element={<Confirm />} />
          <Route path="/invitations/:token" element={<InvitationsPage />} />

          {/* <Route path="/earlyaccess" element={<SignUpFlow />} /> */}
          <Route path="/models" element={<Models />} />
          <Route path="/about" element={<About />} />
          <Route path="/faq" element={<FAQPage />} />
          <Route path="/policy" element={<PrivacyPolicy />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/terms" element={<TandC />} />
          <Route path="/ai-policy" element={<AIPolicy />} />
          <Route
            path="/enterprise-privacy-policy"
            element={<EnterprisePrivacyPolicy />}
          />
          <Route path="/payment-policy" element={<PaymentPolicy />} />
          <Route path="/signup" element={<SignUp />} />

          <Route
            path="/friday-fun/linkedin-bot"
            element={<PublicPlayground />}
          />
          <Route path="/playground-game" element={<PlaygroundGame />} />

          {/* <Route
            path="/instances"
            element={
              <ProtectedRouteLoggedIn>
                <MyInstances />
              </ProtectedRouteLoggedIn>
            }
          /> */}
          <Route path="/articles" element={<Articles />} />
          <Route
            path="/tailor/settings"
            element={
              <ProtectedRouteLoggedIn>
                <Settings />
              </ProtectedRouteLoggedIn>
            }
          />
          <Route path="/articles/:blob" element={<ArticleDetail />} />
          <Route
            path="/verifiedjobs"
            element={
              <ProtectedRouteLoggedIn>
                <VerifiedJobs />
              </ProtectedRouteLoggedIn>
            }
          />
          <Route path="/careers" element={<Careers />} />
          <Route path="/job-postings" element={<JobPostings />} />
          <Route
            path="/job-postings/:jobId"
            element={<JobDetails jobPostingsData={jobPostingsData} />}
          />
          <Route path="/providersignup" element={<ProviderSignup />} />
          <Route
            path="/providerdashboard"
            element={
              <ProtectedRouteLoggedIn>
                <NewProviderDashboard />
              </ProtectedRouteLoggedIn>
            }
          />
          <Route path="/organization" element={<Organization />} />
          <Route path="/press" element={<Press />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/partner" element={<PartnerWithUs />} />
          <Route path="/billing-details" element={<StripeRedirection />} />
          <Route
            path="/templates/multi-gpu-OS-template"
            element={<MultiGpuPage />}
          />
          <Route
            path="/templates/mamba-ready-OS-template"
            element={<MambaReadyPage />}
          />
          <Route
            path="/templates/custom-image-guide"
            element={<CustomImageSetupPage />}
          />
          <Route path="*" element={<Page404 />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
