import axios from 'axios';

import { checkTokenExpiration, getNewToken } from './userUtils';
import { CustomError } from './errors';

const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_FRONTEND_SERVER_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const setupInterceptors = (store) => {
  axiosInstance.interceptors.request.use(
    async (config) => {
      const {
        token,
        refreshToken,
        refreshAuthToken,
        logout,
        setShowSignInModal,
        setShowTokenExpiredModal,
      } = store;
      const isTokenExpired = checkTokenExpiration(token);
      const isRefreshTokenExpired =
        checkTokenExpiration(refreshToken) || !refreshToken;
      if (token) {
        if (isTokenExpired) {
          if (isRefreshTokenExpired) {
            localStorage.setItem('shouldRedirect', 'no');
            setShowTokenExpiredModal(true);
            logout();
            return Promise.reject(
              new CustomError({
                message: 'Tokens are expired',
                code: 'no-valid-token',
              }),
            );
          }
          try {
            const newToken = await getNewToken(refreshToken);
            config.headers['Authorization'] = `Bearer ${newToken}`;
            refreshAuthToken(newToken);
          } catch (error) {
            logout();
            return Promise.reject(
              new CustomError({
                message: 'Failed to get new token',
                code: 'no-valid-token',
              }),
            );
          }
        } else {
          config.headers['Authorization'] = `Bearer ${token}`;
        }
      } else {
        setShowSignInModal(true);
        logout();
        return Promise.reject(
          new CustomError({
            message: 'No token found',
            code: 'no-valid-token',
          }),
        );
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        store.setShowTokenExpiredModal(true);
        store.logout();
      }
      return Promise.reject(
        new CustomError({
          message: 'Failed to get new token',
          code: 'no-valid-token',
        }),
      );
    },
  );
};

export { axiosInstance, setupInterceptors };
