import { useState, useEffect } from 'react';

const DarkModeToggle = ({ setToggleView }) => {
  const [darkMode, setDarkMode] = useState(() => {
    const value = localStorage.getItem('darkMode');
    // Use JSON.parse() to convert the string back to a boolean
    return value === null ? false : JSON.parse(value);
  });

  // Use useEffect to update localStorage when darkMode changes
  useEffect(() => {
    localStorage.setItem('darkMode', JSON.stringify(darkMode));
  }, [darkMode]);

  // Toggle dark mode and store it in local storage
  const handleToggle = () => {
    setDarkMode(!darkMode);
  };

  // Add or remove the 'dark' class based on the darkMode state
  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
    setToggleView(darkMode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [darkMode]);

  return (
    <label className="relative inline-flex items-center cursor-pointer">
      <input
        type="checkbox"
        className="sr-only peer"
        checked={darkMode}
        onChange={handleToggle}
      />
      <div className="w-11 h-6 bg-gray-200 rounded-full  dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-black"></div>
      <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
        {darkMode ? 'Provider View' : 'User View'}
      </span>
    </label>
  );
};

export default DarkModeToggle;
