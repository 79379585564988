// import '../src/utils/instrument.js';
import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App.jsx';
import './index.css';

import { UserProvider } from './UserContext';
import { UploadServiceProvider } from './context/UploadService.jsx';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <UserProvider>
      <UploadServiceProvider>
        <App />
      </UploadServiceProvider>
    </UserProvider>
  </React.StrictMode>,
);
