import { useState } from 'react';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/24/outline';

import { useUser } from '../../UserContext';
import Spinner from '../Spinner';
import TailorApiKeys from '../Tailor/TailorApiKeys';

const SSHKeysContent = () => {
  const { user, setUser, customAxios, fetchAndUpdateUserDetails } = useUser();
  const [publicKey, setPublicKey] = useState('');
  const [loading, setLoading] = useState(false);
  const [keyToDelete, setKeyToDelete] = useState(null);
  const [loadingDeleteKey, setLoadingDeleteKey] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const handleAddSSHKey = async (event) => {
    event.preventDefault();
    setLoading(true);
    const sshKeyData = {
      public_key: publicKey,
    };

    try {
      await customAxios.post('ssh_keys', sshKeyData);

      // console.log('SSH Key added:', response.data);
      setPublicKey('');
      // TODO: ** the API call should return the updated user details
      await fetchAndUpdateUserDetails();
      toast.success('Public key added successfully');
    } catch (error) {
      console.error('Error adding SSH Key:', error);
      if (error.code === 'ERR_NETWORK') {
        toast.error('Network error. Please try again later.');
        return;
      }
      toast.error('Error adding SSH Key');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteSSHKey = async (keyId) => {
    setLoadingDeleteKey(true);
    try {
      const body = {
        _method: 'DELETE',
        key_id: keyId,
      };

      await customAxios.post('ssh_keys', body);
      // TODO: ** the API call should return the updated user details
      await fetchAndUpdateUserDetails();
      // console.log('SSH Key deleted:', keyId);
      // Update the user's ssh_keys in state to reflect the deletion
      const updatedKeys = user.ssh_keys.filter((key) => key.id !== keyId);
      setUser({ ...user, ssh_keys: updatedKeys });
      toast.success('SSH Key deleted');
    } catch (error) {
      console.error('Error deleting SSH Key:', error);
      if (error.code === 'ERR_NETWORK') {
        toast.error('Network error. Please try again later.');
        return;
      }
      toast.error('Error deleting SSH Key');
    } finally {
      setLoadingDeleteKey(false);
    }
  };

  const confirmDelete = (key) => {
    setKeyToDelete(key);
  };

  const cancelDelete = () => {
    setKeyToDelete(null);
  };

  return (
    <div className="min-h-screen font-dmSans">
      <div className="flex-col justify-center flex-1 w-full p-5 mx-auto -mb-10 text-xs border rounded-lg rightside">
        <h2 className="mb-4 text-xl font-medium leading-tight tracking-tight text-slate-900 sm:mb-6 dark:text-white">
          Public Keys
        </h2>
        <div className="break-all">
          <div className="w-full mb-10 text-sm text-left text-indigo-500 dark:text-gray-300">
            <Link to="/articles/3">Need help with SSH keys?</Link>
          </div>
          {user?.ssh_keys?.length === 0 ? (
            <div className="pb-10 text-sm text-center text-gray-500 dark:text-gray-300">
              You currently have no active public keys. Please press the button
              below to add one.
            </div>
          ) : (
            <ul>
              {user?.ssh_keys?.map((key, index) => (
                <div
                  className="flex items-center py-2 px-2 my-2 border rounded-lg max-w-[600px] dark:text-gray-300"
                  key={index}
                >
                  {keyToDelete && keyToDelete.id === key.id ? (
                    <div className="flex items-center justify-between w-full">
                      <span className="flex items-center w-1/2 text-sm break-words md:w-full md:text-base">
                        Are you sure you want to delete this key?
                      </span>
                      <button
                        onClick={() => handleDeleteSSHKey(key.id)}
                        className="px-2 py-1 mx-2 text-sm text-red-500 rounded md:text-base hover:bg-red-100 min-w-fit"
                      >
                        {loadingDeleteKey ? (
                          <Spinner
                            borderColor={'pink'}
                            borderTopColor={'red'}
                            size={'14px'}
                          />
                        ) : (
                          <span>Yes</span>
                        )}
                      </button>
                      <button
                        onClick={cancelDelete}
                        className="px-2 py-1 text-sm text-gray-500 rounded md:text-base hover:bg-gray-100 min-w-fit"
                      >
                        Cancel
                      </button>
                    </div>
                  ) : (
                    <>
                      <button
                        onClick={() => confirmDelete(key)}
                        className="px-2 py-1 mr-4 text-sm text-white rounded shadow"
                      >
                        <svg
                          className="w-4 h-4 text-gray-700 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 18 20"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M1 5h16M7 8v8m4-8v8M7 1h4a1 1 0 0 1 1 1v3H6V2a1 1 0 0 1 1-1ZM3 5h12v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V5Z"
                          />
                        </svg>
                      </button>
                      <div className="flex-1 min-w-0">
                        <span className="inline-block w-full overflow-hidden whitespace-nowrap text-ellipsis">
                          {key.public_key.slice(0, 200)}
                        </span>
                      </div>
                    </>
                  )}
                </div>
              ))}
            </ul>
          )}
        </div>

        <form onSubmit={handleAddSSHKey}>
          <div className="grid gap-5 mt-12 mb-6 md:mt-64 sm:grid-cols-2">
            <div className="sm:col-span-2">
              <label
                htmlFor="public-key"
                className="block mb-2 text-sm font-medium text-slate-900 dark:text-white"
              >
                Public Key
              </label>
              <textarea
                name="public-key"
                id="public-key"
                placeholder="Paste your SSH public key here"
                value={publicKey}
                onChange={(e) => setPublicKey(e.target.value.trim())}
                className="flex-1 block w-full border-gray-300 rounded-md shadow-sm form-input focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 dark:bg-gray-800 dark:text-gray-100 dark:placeholder-gray-300"
                rows="4"
                required
              ></textarea>
            </div>
          </div>

          <div className="flex space-x-3">
            <button
              type="submit"
              className="flex items-center justify-center w-1/4 min-w-[200px] h-12 px-5 text-sm font-medium text-center text-white bg-gray-900 rounded-md hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-indigo-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-indigo-800 whitespace-nowrap"
            >
              {' '}
              {loading ? (
                <Spinner />
              ) : (
                <div className="flex items-center justify-center">
                  {' '}
                  <PlusIcon className="w-4 h-4 mr-2 text-zinc-100" />
                  Add New Public Key
                </div>
              )}
            </button>
            {showSuccessMessage && (
              <span className="flex items-center px-2 text-green-600 rounded-lg shadow whitespace-nowrap">
                {successMessage}
              </span>
            )}
          </div>
        </form>
      </div>
      <div className="flex flex-col justify-center w-full p-5 mx-auto mt-16 text-xs border rounded-lg ">
        <TailorApiKeys />
      </div>
    </div>
  );
};

export default SSHKeysContent;
