import { useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { toast } from 'react-hot-toast';

import { useUser } from '../UserContext';
const stripe_publishable_key = import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY;
const stripePromise = loadStripe(stripe_publishable_key);

const StripeRedirection = () => {
  const {
    user,
    setShowTokenExpiredModal,
    setShowSignInModal,
    customAxios,
    checkTokenExpiration,
  } = useUser();

  const handleCheckout = async () => {
    const stripe = await stripePromise;

    try {
      const response = await customAxios.post('create-session', {});

      const session = response.data;

      await stripe.redirectToCheckout({
        sessionId: session.session_id,
      });
    } catch (error) {
      console.error('Error during checkout:', error);
      if (error.code === 'ERR_NETWORK') {
        toast.error('Network error. Please try again later.');
        return;
      }
      toast.error('Error during checkout, please try again');
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      handleCheckout();
    }, 4000);
    if (user) {
      if (checkTokenExpiration()) {
        localStorage.setItem('shouldRedirect', 'no');
        setShowTokenExpiredModal(true);
        clearTimeout(timer);
      }

      return () => clearTimeout(timer);
    } else {
      localStorage.setItem('shouldRedirect', 'no');
      setShowSignInModal(true);
      clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <div
      style={{ minHeight: 'calc(100vh - 162px)' }}
      className="dark:bg-gray-800"
    >
      <div className="absolute inset-x-0 top-1/2 -z-10 flex -translate-y-1/2 justify-center overflow-hidden [mask-image:radial-gradient(50%_45%_at_50%_55%,white,transparent)] ">
        <svg
          className="h-[40rem] w-[80rem] flex-none stroke-gray-200 "
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="e9033f3e-f665-41a6-84ef-756f6778e6fe"
              width={200}
              height={200}
              x="50%"
              y="50%"
              patternUnits="userSpaceOnUse"
              patternTransform="translate(-100 0)"
            >
              <path d="M.5 200V.5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y="50%" className="overflow-visible fill-gray-50 ">
            <path
              d="M-300 0h201v201h-201Z M300 200h201v201h-201Z"
              strokeWidth={0}
            />
          </svg>
          <rect
            width="100%"
            height="100%"
            strokeWidth={0}
            fill="url(#e9033f3e-f665-41a6-84ef-756f6778e6fe)"
          />
        </svg>
      </div>
      <div className="flex items-center justify-center mt-20 font-spaceG">
        <div className="flex flex-col items-center justify-center h-56 text-sm text-white bg-gray-700 rounded-lg shadow-xl md:text-base md:w-96 w-80">
          <div className="flex items-center justify-center">
            You are now being redirected to Stripe...
          </div>
          <div className="flex">
            <img
              src="/StripeLogo.svg"
              alt="stripe logo"
              className="h-20 w-36"
            />
          </div>
          <div>
            If you don't wish to wait,{' '}
            <span
              className="text-indigo-400 underline cursor-pointer"
              onClick={handleCheckout}
            >
              {' '}
              click here.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StripeRedirection;
