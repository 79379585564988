import { useState, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { toast } from 'react-hot-toast';

import { useUser } from '../UserContext';
import PriceBreakdown from '../Components/PriceBreakdown.jsx';
import EmailModal from '../Components/Modals/EmailModal.jsx';
import OsTemplateSelection from '../Components/OsTemplateSelection.jsx';
import osTemplates from '../Components/OsTemplateSelect/OsType.jsx';
import TabsContainer from '../Components/TabsContainer.jsx';
import RentalModalManager from '../Components/RentalModalManager.jsx';
import RentalModals from '../Components/Modals/RentalModals.jsx';
import { applyDarkModePreference, normalAxios } from '../utils/generalUtils.js';
import CustomImageDetails from '../Components/CustomImageDetails.jsx';

const Marketplace = () => {
  const [selectedRental, setSelectedRental] = useState(null);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [listings, setListings] = useState([]);
  const [displayedListings, setDisplayedListings] = useState([]);
  const [minGpuCount, setMinGpuCount] = useState(1);
  const [osTemplate, setOsTemplate] = useState('');
  const { user, fetchAndUpdateUserDetails, customAxios } = useUser();
  const [searchParams] = useSearchParams();
  const searchParamsObj = Object.fromEntries(searchParams);
  const firstRender = useRef(true);
  searchParamsObj.isValid =
    'template' in searchParamsObj &&
    osTemplates.some((template) => template.name === searchParamsObj.template);
  searchParamsObj.firstRender = firstRender;
  searchParamsObj.gpu = searchParamsObj.gpu
    ?.split('%20')
    .join(' ')
    .toLowerCase();

  useEffect(() => {
    const template =
      'template' in searchParamsObj && searchParamsObj.isValid
        ? searchParamsObj.template
        : osTemplate
          ? osTemplate
          : osTemplates[0].name;

    setOsTemplate(template);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.documentElement.classList.remove('dark');
    return () => applyDarkModePreference();
  }, []);

  useEffect(() => {
    const fetchListings = async () => {
      if (!osTemplate) {
        return;
      }
      try {
        const response = await normalAxios.get(`/nodes?template=${osTemplate}`);

        const receivedListings = response.data.nodes.filter(
          (node) => node.available_gpus >= response.data.min_gpu_count,
        );

        setListings(receivedListings);
        setMinGpuCount(response.data.min_gpu_count);

        if (response.data.nodes.length > 0 && osTemplates.length > 0) {
          handleSelectOsTemplate(osTemplate, receivedListings);
        }
      } catch (error) {
        console.error('Error fetching listings:', error);
        if (error.code === 'ERR_NETWORK') {
          toast.error('Network error. Please try again later.');
          return;
        }
        toast.error('Could not fetch listings, please try again later');
      }
    };
    fetchListings();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [osTemplate]);

  useEffect(() => {
    if (user && user.kyc_status === 'unverified') {
      (async () => {
        await fetchAndUpdateUserDetails();
      })();
    }

    return () => localStorage.removeItem('shouldRedirect');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectOsTemplate = (template, newListings) => {
    // window.history.replaceState({}, '', `${window.location.pathname}`);

    handleUnselectRental();
    setOsTemplate(template);
    setDisplayedListings(newListings ?? listings);
  };

  const handleSelectRentalNew = (rental) => {
    if (selectedRental && rental.id === selectedRental.id) {
      setSelectedRental(null);
      localStorage.removeItem('selectedRental');
    } else {
      setSelectedRental(rental);
      localStorage.setItem('selectedRental', JSON.stringify(rental));
      setTimeout(() => {
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: 'smooth',
        });
      }, 200);
    }
  };

  const handleUnselectRental = () => {
    setSelectedRental(null);
    localStorage.removeItem('selectedRental');
  };

  return (
    <>
      {/* modals */}
      <EmailModal
        userEmail={user?.email ?? ''}
        showModal={showEmailModal}
        onClose={() => setShowEmailModal(false)}
        alternativeMessage="If you're facing any problems using the marketplace, have any ideas for new features, or want to contact us for any reason, please get in touch and we'll get back to you as soon as possible."
      />
      <div className="w-full h-full bg-zinc-50">
        {/* rest of code */}
        <div className="flex flex-col w-full h-full min-h-screen max-w-[1300px] mx-auto items-center pt-12 font-spaceG overflow-x-hidden">
          <div className="w-full mt-8 mb-4 ml-16 text-3xl italic text-left font-extralight text-slate-800 dark:text-gray-100">
            Create a Machine
          </div>
          <div className="w-full font-sans">
            <div className="w-full px-8 mb-10 text-sm text-left md:ml-8 md:px-0 dark:text-gray-300 md:w-1/2 text-slate-600">
              You'll need to have an SSH key saved to successfully create a
              machine -{' '}
              <Link
                to="/articles/3"
                className="text-indigo-600 hover:cursor-pointer dark:text-indigo-400"
              >
                see how here
              </Link>
            </div>
            <div className="w-full px-8 mb-10 text-sm text-left md:ml-8 md:px-0 dark:text-gray-300 md:w-1/2 text-slate-600">
              Begin by choosing your preferred operating system and configuring
              your hardware to meet your needs. If the options available don't
              match your requirements, please don't hesitate to{' '}
              <a
                onClick={() => setShowEmailModal(true)}
                className="text-indigo-600 hover:cursor-pointer dark:text-indigo-400"
              >
                contact us
              </a>{' '}
              for assistance.
            </div>
          </div>

          <OsTemplateSelection
            handleSelectOsTemplate={handleSelectOsTemplate}
            preselected={searchParamsObj}
          />

          <div id="custom-image-details" className="w-full">
            {osTemplate === 'custom_image' && <CustomImageDetails />}
          </div>

          <div id="tabs-container" className="w-full">
            <TabsContainer
              listings={displayedListings}
              selectedRental={selectedRental?.id}
              handleSelectRental={handleSelectRentalNew}
              selectedOsTemplate={osTemplate}
              minGpuCount={minGpuCount}
              handleUnselectRental={handleUnselectRental}
              preselected={searchParamsObj}
            />
          </div>

          <RentalModalManager>
            <RentalModals />
            <PriceBreakdown
              selectedRental={selectedRental}
              selectedOsTemplate={osTemplate}
              handleUnselectRental={handleUnselectRental}
            />
          </RentalModalManager>
        </div>
      </div>
    </>
  );
};

export default Marketplace;
