import React, { useState } from 'react';
import axios from 'axios';
import { Modal } from 'flowbite-react';
import { Link } from 'react-router-dom';

import { useUser } from '../UserContext';
import Spinner from './Spinner';
const frontendServerUrl = import.meta.env.VITE_FRONTEND_SERVER_URL;

const RentMachineErrorModal = ({
  showRentErrorModal,
  onClose,
  errorMessage,
  onSSHKeyUploadSuccess,
}) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [publicKey, setPublicKey] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [sshSuccessMessage, setSshSuccessMessage] = useState('');

  const { user, setUser, customAxios } = useUser();

  const resendConfirmation = async () => {
    if (!user || !user.email) {
      console.error('User email is not available');
      return;
    }

    try {
      setLoading(true);
      setMessage('Confirmation Sent');
      const response = await axios.post(
        `${frontendServerUrl}/resend_confirmation`,
        { email: user.email },
      );
      setLoading(false);

      // console.log('Confirmation resent:', response.data);
    } catch (error) {
      if (import.meta.env.DEV) {
        console.error('Error resending confirmation:', error);
      }
      setMessage('Error Sending Confirmation');
    }
  };

  const handleAddSSHKey = async (event) => {
    event.preventDefault();
    setLoading(true);
    const sshKeyData = {
      public_key: publicKey,
    };

    try {
      await customAxios.post('ssh_keys', sshKeyData);

      // console.log('SSH Key added:', response.data);

      setPublicKey('');
      await fetchAndUpdateUserDetails(customAxios, setUser);
      setShowSuccessMessage(true);
      setSshSuccessMessage('Public key added successfully');

      await Promise.all([
        new Promise((resolve) => setTimeout(resolve, 1500)),
        await fetchAndUpdateUserDetails(customAxios, setUser),
      ]);

      setShowSuccessMessage(false);
      await onSSHKeyUploadSuccess();
      setLoading(false);
      onClose();
    } catch (error) {
      console.error('Error adding SSH Key:', error.message || error);
    }
  };

  const fetchAndUpdateUserDetails = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No authentication token found');
      }

      const userDetailsResponse = await axios.get(
        `${frontendServerUrl}/profile`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      const userDetails = userDetailsResponse.data;
      setUser(userDetails);
      localStorage.setItem('userDetails', JSON.stringify(userDetails)); // Update local storage
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  const renderMessage = () => {
    switch (errorMessage) {
      case 'User is unconfirmed':
        return (
          <>
            <div className="text-black font-spaceG">
              Please verify your email to rent a machine - don't forget to check
              spam!
            </div>
            <div>
              <button
                disabled={loading || message}
                onClick={resendConfirmation}
                className="flex items-center justify-center h-10 my-4 text-sm text-white bg-gray-800 border rounded-md w-72 font-spaceG"
              >
                {loading ? <Spinner /> : message || 'Resend Verification Email'}
              </button>
            </div>
          </>
        );
      case 'Missing ssh key':
        return (
          <>
            <div className="font-spaceG dark:text-gray-100">
              Before renting an instance, please upload an SSH key. This
              precaution helps prevent unnecessary expenses due to instances
              running without access.{' '}
              <Link
                to="/articles/3"
                className="text-blue-500 hover:text-blue-600"
              >
                Learn more
              </Link>
            </div>
            <div className="mt-4 font-spaceG dark:text-gray-100">
              You can add or modify your keys in{' '}
              <Link
                to="/settings"
                className="text-blue-500 hover:text-blue-600"
              >
                Settings
              </Link>{' '}
              or upload a key below.
            </div>
            <form onSubmit={handleAddSSHKey}>
              <div className="grid gap-5 my-6 sm:grid-cols-2">
                <div className="sm:col-span-2 font-spaceG">
                  <label
                    htmlFor="public-key"
                    className="block mb-2 text-sm font-medium text-slate-900 dark:text-white"
                  >
                    {/* Public Key */}
                  </label>
                  <textarea
                    name="public-key"
                    id="public-key"
                    placeholder="Paste your SSH public key here"
                    value={publicKey}
                    onChange={(e) => setPublicKey(e.target.value)}
                    className="bg-slate-50 border border-slate-300 text-slate-900 sm:text-sm rounded-lg focus:ring-slate-600 focus:border-slate-600 block w-full p-2.5 dark:bg-gray-700 dark:border-slate-600 dark:placeholder-gray-400 dark:text-white"
                    rows="4"
                    required
                  ></textarea>
                </div>
              </div>

              <div className="flex space-x-3">
                <button
                  type="submit"
                  className="flex items-center justify-center w-1/4 min-w-[200px] h-12 px-5 text-sm font-medium text-center text-white bg-gray-600 rounded-md hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800 whitespace-nowrap"
                >
                  {' '}
                  {loading ? <Spinner /> : <span>Upload SSH Key</span>}
                </button>
                {showSuccessMessage && (
                  <span className="flex items-center px-2 text-green-600 rounded-lg shadow whitespace-nowrap">
                    {sshSuccessMessage}
                  </span>
                )}
              </div>
            </form>
          </>
        );

      case 'A server error occurred (500). Please try again later.':
        return (
          <div className="text-red-500">
            I'm sorry we were unable to complete this request for you. Please
            get in contact with us to report this problem.
          </div>
        );
      case 'User is delinquent':
        return (
          <div className="text-gray-800">
            We're sorry, you are unable to rent an instance due to overdue
            charges. Please clear your balance{' '}
            <span className="text-indigo-600">here </span> to continue. If you
            believe this is an error, please get in contact with us.
          </div>
        );
      default:
        return <div className="text-red-500">{errorMessage}</div>;
    }
  };

  return (
    <Modal show={showRentErrorModal} onClose={onClose}>
      <Modal.Header>
        {errorMessage === 'User is unconfirmed' ||
        errorMessage === 'Missing ssh key'
          ? 'Reminder'
          : 'Error'}
      </Modal.Header>
      <Modal.Body>{renderMessage()}</Modal.Body>
    </Modal>
  );
};

export default RentMachineErrorModal;
