import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-hot-toast';

import { useUser } from '../UserContext';
const frontendServerUrl = import.meta.env.VITE_FRONTEND_SERVER_URL;

const Confirm = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { user, fetchAndUpdateUserDetails } = useUser();

  useEffect(() => {
    const confirmUser = async () => {
      const pathParts = location.pathname.split('/');
      const token = pathParts[pathParts.length - 1];

      if (!token) {
        setLoading(false);
        setError(true);
        return;
      }

      try {
        await axios.get(`${frontendServerUrl}/confirm/${token}`);

        if (user) {
          await fetchAndUpdateUserDetails();
        }

        setTimeout(() => {
          navigate('/');
        }, 2000);
      } catch (error) {
        console.error('Error confirming user:', error);
        if (error.code === 'ERR_NETWORK') {
          toast.error('Network error. Please try again later.');
          return;
        }
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    confirmUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, location.pathname]);

  // TODO: ** consider making this component similar tot the CheckoutSuccess component
  return (
    <div className="flex w-full font-sans sm:min-h-[calc(100vh-88px-118px)] min-h-[calc(100vh-88px-130px)] lg:min-h-[calc(100vh-96px-118px)]">
      <div className="flex justify-center w-full">
        <div className="my-12 rounded-lg shadow-lg md:mx-10 h-fit">
          <div className="flex items-center justify-center w-full p-1 rounded-lg h-fit wavy shadow-3xinner">
            <div className="w-full h-full bg-white rounded-md">
              {loading ? (
                <div className="flex flex-col w-full p-12">
                  <div className="mx-auto mb-10 text-lg text-gray-800">
                    Confirming Account...
                  </div>
                  <div
                    className="justify-center mx-auto spinner"
                    style={{
                      border: '4px solid rgba(255, 255, 255, 0.3)',
                      borderTop: '4px solid gray',
                      borderRadius: '50%',
                      width: '24px',
                      height: '24px',
                      animation: 'spin 1s linear infinite',
                    }}
                  ></div>
                </div>
              ) : error ? (
                <div className="p-4 text-left">
                  <div className="flex items-center mb-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-6 h-6 text-red-800"
                    >
                      <path
                        fillRule="evenodd"
                        d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z"
                        clipRule="evenodd"
                      />
                    </svg>

                    <p className="ml-4 text-lg font-semibold text-red-800 ">
                      There was an error with this confirmation.
                    </p>
                  </div>
                  <p className="mb-2 ml-10 text-sm text-gray-800">
                    The confirmation link you used expired. <br /> Log in to
                    your account to request a new confirmation link or contact
                    support for assistance.
                  </p>
                </div>
              ) : (
                <div className="p-12 text-center">
                  <div className="flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-6 h-6 text-green-800"
                    >
                      <path
                        fillRule="evenodd"
                        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <p className="mx-auto ml-4 text-gray-800 md:text-lg">
                      Account Confirmed
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Confirm;
