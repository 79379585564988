import { useEffect, useRef, useState } from 'react';

const HeatmapComponent = ({ matrix, x_axis, y_axis }) => {
  const canvasRef = useRef(null);
  const containerRef = useRef(null);
  const [newXAxis, setNewXAxis] = useState([]);
  const [newYAxis, setNewYAxis] = useState([]);

  useEffect(() => {
    if (x_axis.length > 0 && y_axis.length > 0) {
      setNewYAxis(y_axis.map((value) => `${value}%`));

      setNewXAxis(
        x_axis.map((value) => {
          if (value < 1000) {
            return `${value}`;
          } else if (value < 1000000) {
            return `${(value / 1000).toFixed(1)}k`;
          } else {
            return `${(value / 1000000).toFixed(1)}M`;
          }
        }),
      );
    }
  }, [x_axis, y_axis]);

  function getColor(value) {
    if (value < 0 || value > 100) {
      throw new RangeError('Value must be between 0 and 100');
    }

    const startHue = 0; // Red
    const endHue = 164; // Green

    const startSaturation = 85;
    const endSaturation = 91;

    const startLightness = 61;
    const endLightness = 44;

    const interpolate = (start, end, factor) => start + (end - start) * factor;

    const factor = value / 100;

    let hue;
    if (factor < 0.5) {
      hue = interpolate(startHue, 90, factor * 2); // Red to a midpoint avoiding neon green
    } else {
      hue = interpolate(90, endHue, (factor - 0.5) * 2); // Midpoint to green
    }

    const saturation = interpolate(startSaturation, endSaturation, factor);
    const lightness = interpolate(startLightness, endLightness, factor);

    return `hsl(${hue.toFixed(0)}, ${saturation.toFixed(0)}%, ${lightness.toFixed(0)}%)`;
  }

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    const drawCanvas = () => {
      if (!canvasRef.current) {
        return;
      }

      const cols = matrix.length;
      const rows = matrix[0].length;

      const logicalWidth = canvas.clientWidth;
      const logicalHeight = canvas.clientHeight;

      const labelColumnWidth = logicalWidth * 0.15;
      const dataCellWidth = (logicalWidth - labelColumnWidth) / cols;
      const cellHeight = logicalHeight / (rows + 2.8);

      ctx.clearRect(0, 0, canvas.width, canvas.height);

      ctx.textAlign = 'right';
      ctx.font = '0.7rem Helvetica';
      newYAxis.forEach((label, index) => {
        ctx.fillText(
          label,
          labelColumnWidth - 10,
          (index + 1) * cellHeight + cellHeight / 2 + cellHeight / 10,
        );
      });

      for (let i = 0; i < rows; i++) {
        for (let j = 0; j < cols; j++) {
          const value = matrix[j][i] * 10;
          const color = getColor(value);
          ctx.fillStyle = color;
          ctx.fillRect(
            labelColumnWidth + j * dataCellWidth,
            (i + 1) * cellHeight,
            dataCellWidth,
            cellHeight,
          );

          ctx.fillStyle = '#000';
          ctx.font = '900 0.7rem Helvetica';
          ctx.textAlign = 'center';
          ctx.fillText(
            value !== 100 ? `${value + '%'}` : '',
            labelColumnWidth + j * dataCellWidth + dataCellWidth / 2,
            (i + 1) * cellHeight + cellHeight / 2 + cellHeight / 10,
          );
        }
      }

      ctx.fillStyle = '#3F3F46';
      ctx.font = '0.7rem Helvetica';
      ctx.textAlign = 'center';
      newXAxis.forEach((label, index) => {
        ctx.fillText(
          label,
          labelColumnWidth + index * dataCellWidth + dataCellWidth / 2,
          (rows + 1) * cellHeight + 15,
        );
      });

      ctx.fillStyle = '#3F3F46';
      ctx.font = '900 0.7rem Helvetica';
      ctx.textAlign = 'center';
      ctx.fillText(
        'Context Length (# of Tokens)',
        labelColumnWidth + (cols * dataCellWidth) / 2,
        rows * cellHeight + cellHeight + 35,
      );

      ctx.save();
      ctx.translate(20, ((rows + 1) * cellHeight) / 2);
      ctx.rotate(-Math.PI / 2);
      ctx.textAlign = 'center';
      ctx.fillText('Document Depth', -cellHeight / 2, -11.5);
      ctx.restore();
    };

    const handleResize = () => {
      const devicePixelRatio = window.devicePixelRatio || 1;

      const width = canvas.parentElement.clientWidth;
      const height = canvas.parentElement.clientHeight;

      canvas.width = width * devicePixelRatio;
      canvas.height = height * devicePixelRatio;

      canvas.style.width = `${width}px`;
      canvas.style.height = `${height}px`;

      ctx.scale(devicePixelRatio, devicePixelRatio);

      drawCanvas();
    };

    const observer = new ResizeObserver(handleResize);
    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      observer.disconnect();
      window.removeEventListener('resize', handleResize);
    };
  }, [matrix, newXAxis, newYAxis]);

  return (
    <div ref={containerRef} className="border rounded-lg p-4 md:col-span-1">
      <h2 className="text-lg font-bold mb-2">Needle in a Haystack</h2>

      <div className="w-full h-[300px]">
        <canvas
          ref={canvasRef}
          id="heatmap"
          className="w-full h-full heatmap-canvas"
        ></canvas>
      </div>
      <div className="w-full max-w-4xl flex justify-between mt-16">
        {Array.from({ length: 101 }, (_, index) => {
          const color = getColor(index);
          return (
            <div
              key={index}
              className="w-[9px] h-8"
              style={{ backgroundColor: color }}
              title={`${index}%`}
            />
          );
        })}
      </div>
      <div className="flex max-w-4xl text-gray-500">
        <div>
          <p className="text-sm text-left mt-2">0%</p>
        </div>
        <div className="flex-grow">
          <p className="text-sm text-center mt-2">Accuracy</p>
        </div>
        <div>
          <p className="text-sm text-right mt-2">100%</p>
        </div>
      </div>
    </div>
  );
};

export default HeatmapComponent;
