import {
  Fragment,
  useState,
  useEffect,
  useRef,
  useCallback,
  // useMemo,
} from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import {
  ChatBubbleLeftEllipsisIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  Cog6ToothIcon,
  PencilIcon,
} from '@heroicons/react/24/outline';
import toast from 'react-hot-toast';
import { set, get } from 'idb-keyval';
import clsx from 'clsx';
import { EmojiConvertor } from 'emoji-js';

import NoDeployedModelModal from './Playground/NoDeployedModelModal';
// import { applyDarkModePreference } from '../../utils/generalUtils';
import { useUser } from '../../UserContext';
import CreatePromptModal from './Playground/CreatePromptModal';
import GenerateApiKeyModal from '../Modals/GenerateApiKeyModal';
// import { CustomError } from '../../utils/errors';
import { areAllCreditCardsExpired } from '../../utils/userUtils';
import FineTuneCreateErrorModal from './FineTunning/FineTuneCreateErrorModal';
import CodeSnippet2 from '../../utils/CodeSnippet2';
import ApiWidget from './Playground/ApiWidget';
import GroupedModelsList from './Playground/GroupedModelsList';

const serverStreamingUrl = import.meta.env.VITE_SERVER_STREAMING_URL;

const TailorPlayground = () => {
  const emoji = new EmojiConvertor();
  emoji.replace_mode = 'unified';
  const [selectedPrompt, setSelectedPrompt] = useState({
    name: '',
    value: '',
  });
  const [messages, setMessages] = useState([]);
  const [secondMessages, setSecondMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLeftSideVisible, setIsLeftSideVisible] = useState(true);
  const [availableModels, setAvailableModels] = useState([]);
  const [adapter, setAdapter] = useState('');
  const [secondAdapter, setSecondAdapter] = useState(null);
  const [showModelList, setShowModelList] = useState(false);
  const [conversation, setConversation] = useState([]);
  const [isApiKeyModalOpen, setIsApiKeyModalOpen] = useState(false);
  const [apiKeys, setApiKeys] = useState([]);
  const [hasApiKeys, setHasApiKeys] = useState(false);
  const [apiCreated, setApiCreated] = useState(false);
  const [secondConversation, setSecondConversation] = useState([]);
  const [isParametersMinimized, setIsParametersMinimized] = useState(true);
  const [showNoDeployedModelModal, setShowNoDeployedModelModal] =
    useState(false);
  const [promptToEdit, setPromptToEdit] = useState(null);
  const [isSplitView, setIsSplitView] = useState(false);
  const [streamingController, setStreamingController] = useState(null);
  const [isRequestCancelled, setIsRequestCancelled] = useState(false);

  const chatContainerRef = useRef(null);
  const secondChatContainerRef = useRef(null);
  const refreshButtonRef = useRef(null);
  const isAutoScrollEnabledRef = useRef(true); // For the main chat container
  const isSecondAutoScrollEnabledRef = useRef(true); // For the second chat container
  const modelnameRef = useRef(null);
  const secondModelnameRef = useRef(null);

  const {
    customAxios,
    checkTokenExpiration,
    setShowTokenExpiredModal,
    setShowSignInModal,
    user,
  } = useUser();
  const [prompts, setPrompts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inferenceErrorMessage, setInferenceErrorMessage] = useState(null);
  const [showCreditCardModal, setShowCreditCardModal] = useState(false);

  useEffect(() => {
    const chatContainer = chatContainerRef.current;

    if (chatContainer) {
      const handleScroll = () => {
        requestAnimationFrame(() => {
          const isNearBottom =
            chatContainer.scrollHeight - chatContainer.scrollTop <=
            chatContainer.clientHeight + 30;

          // Disable auto-scroll if the user scrolls up
          isAutoScrollEnabledRef.current = isNearBottom;
        });
      };

      chatContainer.addEventListener('scroll', handleScroll);

      return () => {
        chatContainer.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  useEffect(() => {
    const secondChatContainer = secondChatContainerRef.current;
    if (secondChatContainer) {
      const handleScroll = () => {
        const isNearBottom =
          secondChatContainer.scrollHeight - secondChatContainer.scrollTop <=
          secondChatContainer.clientHeight + 30;

        // Disable auto-scroll if the user scrolls up
        isSecondAutoScrollEnabledRef.current = isNearBottom;
      };

      secondChatContainer.addEventListener('scroll', handleScroll);

      return () => {
        secondChatContainer.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  const fetchBaseModels = async () => {
    let models = [];
    try {
      const response = await customAxios.get(
        'tailor/v1/base_models?inference=true',
      );
      models = response?.data?.models;
      return models;
    } catch (error) {
      models = [
        {
          id: 1,
          display_name: 'mistral-7b',
          // model_name: 'mistral-7b-instruct-v0.3'
          model_name: 'mistral-7b',
        },
        {
          id: 2,
          display_name: 'mixtral-8x7b',
          // model_name: 'mixtral-8x7b-instruct-v0.1'
          model_name: 'mixtral-8x7b',
        },
      ];
      return models;
    }
  };

  useEffect(() => {
    const fetchApiKeys = async () => {
      try {
        const response = await customAxios.get('tailor/v1/keys');
        setApiKeys(response.data.message);
        setHasApiKeys(response.data.message.length > 0);
        if (response.data.message.length === 0) {
          setIsApiKeyModalOpen(true);
        }
      } catch (error) {
        console.error('Error fetching API keys:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchApiKeys();
  }, [customAxios, apiCreated]);

  const handleGenerateKey = async () => {
    setLoading(true);
    try {
      const nameToUse = 'playground';
      const response = await customAxios.post('tailor/v1/keys', {
        api_key_name: nameToUse,
      });

      setApiKeys((prevKeys) => [...prevKeys, response.data]);
      setHasApiKeys(true);
      setIsApiKeyModalOpen(false);
    } catch (error) {
      console.error('Error generating new API key, please try again later');
    } finally {
      setLoading(false);
    }
  };

  // Auto-scroll for the main chat container when messages update
  useEffect(() => {
    if (isAutoScrollEnabledRef.current && chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  // Auto-scroll for the second chat container when secondMessages update
  useEffect(() => {
    if (
      isSecondAutoScrollEnabledRef.current &&
      secondChatContainerRef.current
    ) {
      secondChatContainerRef.current.scrollTop =
        secondChatContainerRef.current.scrollHeight;
    }
  }, [secondMessages]);

  const [parameters, setParameters] = useState({
    max_tokens: 100,
    temperature: 0.5,
    top_p: 0.5,
    top_k: 40,
    presence_penalty: 0.1,
    repetition_penalty: 0.5,
  });

  useEffect(() => {
    document.documentElement.classList.remove('dark');
  }, []);

  const saveInCaseOfTokenExpiration = useCallback(() => {
    if (availableModels.length === 0) {
      return;
    }
    const isTokenExpired = checkTokenExpiration();
    if (isTokenExpired) {
      localStorage.setItem(
        'tailorData',
        JSON.stringify({
          messages,
          secondMessages,
          newMessage,
          conversation,
          secondConversation,
        }),
      );
    } else {
      const tailorData = JSON.parse(localStorage.getItem('tailorData'));
      if (tailorData) {
        const {
          messages,
          secondMessages,
          newMessage,
          conversation,
          secondConversation,
        } = tailorData;
        setMessages(messages);
        setSecondMessages(secondMessages);
        setNewMessage(newMessage);
        setConversation(conversation);
        setSecondConversation(secondConversation);
      }
      localStorage.removeItem('tailorData');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableModels, checkTokenExpiration]);

  const addPromptToTheList = async (name, value) => {
    if (!('indexedDB' in window)) {
      console.error('This browser does not support IndexedDB');
      return;
    }
    try {
      const newPrompt = { name, value };
      const existingPrompts = (await get('SystemPrompt')) || [];

      if (existingPrompts.length === 0) {
        set('SystemPrompt', [{ name: '(none)', value: '' }, newPrompt]);
      } else {
        const promptExists = existingPrompts.find(
          (prompt) => prompt.name === newPrompt.name,
        );
        if (promptExists?.name === '(none)') {
          return;
        }
        if (promptExists) {
          const updatedPrompts = existingPrompts.map((prompt) =>
            prompt.name === newPrompt.name ? newPrompt : prompt,
          );
          await set('SystemPrompt', updatedPrompts);
        } else {
          await set('SystemPrompt', [...existingPrompts, newPrompt]);
        }
        if (existingPrompts[0].name !== '(none)') {
          set('SystemPrompt', [
            { name: '(none)', value: '' },
            ...existingPrompts,
          ]);
        }
      }
      setSelectedPrompt(newPrompt); // Optional: Select the new prompt
      setPrompts(await get('SystemPrompt'));
      setIsModalOpen(false);
      setPromptToEdit(null);
    } catch (error) {
      if (import.meta.env.DEV) {
        console.error(error);
      }
      toast.error('Failed to add prompt');
    }
  };

  const fetchModels = async () => {
    if (user && user.confirmed !== true) {
      toast.error('Please confirm your email address to access this feature.');
      return;
    }
    try {
      const baseModels = await fetchBaseModels();
      let modifiedBaseModels = [];
      if (baseModels.length > 0) {
        modifiedBaseModels = baseModels.map((model) => {
          if (!model.model_name && model.display_name) {
            model.model_name = model.display_name;
          }
          return model;
        });
      }
      const response = await customAxios.get('tailor/v1/models');
      let models = response?.data?.message;
      models = models.filter(
        (model) => model.base_model_data.available_for_inference,
      );

      if (models.length) {
        const deployedModels = models
          .filter((model) => model.state === 'deployed')
          .map((model) => ({
            ...model,
            display_name: model.model_name,
          }));
        if (deployedModels.length === 0) {
          setAvailableModels(baseModels);
          setAdapter(baseModels[0]);
        } else {
          setAvailableModels([...deployedModels, ...modifiedBaseModels]);
          setAdapter(deployedModels[0]);
        }
      } else {
        setAvailableModels(baseModels);
        setAdapter(baseModels[0]);
      }
    } catch (error) {
      if (import.meta.env.DEV) {
        console.error(error);
      }

      // toast.error('Failed to fetch models');
    }
  };

  const fetchPrompts = useCallback(async () => {
    const existingPrompts = await get('SystemPrompt');
    if (existingPrompts) {
      setPrompts(existingPrompts);
    }
  }, []);

  useEffect(() => {
    fetchModels();
    fetchPrompts();
    saveInCaseOfTokenExpiration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openModal = () => {
    setPromptToEdit(null);
    setIsModalOpen(true);
  };

  const handleSelectModel = (model) => {
    if (streamingController) {
      streamingController.abort();
    }
    setMessages([]);
    setSecondMessages([]);
    setConversation([]);
    setSecondConversation([]);
    setLoading(false);
    setIsRequestCancelled(true);
    setAdapter(model);
  };

  useEffect(() => {
    if (isRequestCancelled) {
      setTimeout(() => {
        setIsRequestCancelled(false);
      }, 500);
    }
  }, [isRequestCancelled]);

  const handleSelectSecondModel = (model) => {
    setSecondAdapter(model);
    setShowModelList(false);
  };

  const parseMixedContent = (text) => {
    // Regex to match code blocks wrapped in triple backticks
    const regex = /(```.*?\n[\s\S]*?\n```)/g;
    let parts = text.split(regex);

    return parts.flatMap((part) => {
      if (part.startsWith('```')) {
        const languageMatch = part.match(/```(.*?)(\n|$)/);
        const language = languageMatch ? languageMatch[1].trim() : '';
        const code = part
          .replace(/```.*?\n/, '') // Remove the opening triple backticks and language
          .replace(/```\n?$/, ''); // Remove the closing triple backticks
        return [{ type: 'code', language, content: code }];
      }

      return part.split('\n').map((line) => ({ type: 'text', content: line }));
    });
  };

  const clearChat = () => {
    if (streamingController) {
      streamingController.abort();
    }
    setMessages([]);
    setSecondMessages([]);
    setConversation([]);
    setSecondConversation([]);
    setNewMessage('');
    setSelectedPrompt({ name: '', value: '' });
  };

  const checkForTokenExpiration = () => {
    const isTokenExpired = checkTokenExpiration();
    const token = localStorage.getItem('token');
    if (token && isTokenExpired) {
      localStorage.setItem('shouldRedirect', 'no');
      setShowTokenExpiredModal(true);
      return true;
    }
    if (isTokenExpired && !token) {
      localStorage.setItem('shouldRedirect', 'no');
      setShowSignInModal(true);
      return true;
    }
    return false;
  };

  const hasCreditCardOnFile = () => {
    if (
      user.payment_methods.length === 0 ||
      areAllCreditCardsExpired(user.payment_methods)
    ) {
      return false;
    } else {
      return true;
    }
  };

  const sendMessage = async (e) => {
    e.preventDefault();
    setInferenceErrorMessage(null);
    if (checkForTokenExpiration()) {
      return;
    }
    if (!hasCreditCardOnFile()) {
      setShowCreditCardModal(true);
      return;
    }

    isAutoScrollEnabledRef.current = true;
    isSecondAutoScrollEnabledRef.current = true;

    if (newMessage.trim() === '') {
      return;
    }
    saveInCaseOfTokenExpiration();
    setLoading(true);

    const userMessageWithEmojis = {
      text: emoji.replace_colons(newMessage),
      isUser: true,
    };

    const loadingMessage = { loading: true, isUser: false };

    setMessages((messages) => [
      ...messages,
      userMessageWithEmojis,
      loadingMessage,
    ]);
    if (isSplitView && secondAdapter) {
      setSecondMessages((messages) => [
        ...messages,
        userMessageWithEmojis,
        loadingMessage,
      ]);
    }
    setNewMessage('');

    const updatedConversation = [
      ...conversation,
      { role: 'user', content: newMessage },
    ];
    setConversation(updatedConversation);

    const payloads = [
      {
        adapter_name: adapter.model_name,
        messages: updatedConversation,
      },
    ];

    if (isSplitView && secondAdapter) {
      const updatedSecondConversation = [
        ...secondConversation,
        { role: 'user', content: newMessage },
      ];
      setSecondConversation(updatedSecondConversation);
      payloads.push({
        adapter_name: secondAdapter.model_name,
        messages: updatedSecondConversation,
      });
    }

    if (!isParametersMinimized) {
      payloads.forEach(
        (payload) =>
          (payload.parameters = Object.fromEntries(
            Object.entries(parameters).filter(([, value]) => value !== -1),
          )),
      );
    }

    if (isSplitView && secondAdapter) {
      try {
        const responses = await Promise.all(
          payloads.map((payload) =>
            customAxios.post('tailor/v1/generate', payload),
          ),
        );
        const newMessages = responses.map((response) => {
          const responseText = response.data.generated_text;
          const totalTokens = response.data.usage.completion_tokens;

          const assistantMessageSegments = responseText
            .split('\n')
            .map((segment, index) => (
              <div key={index}>
                {emoji.replace_unified(segment)}
                {index < responseText.split('\n').length - 1 && <br />}
              </div>
            ));

          return {
            text: assistantMessageSegments,
            isUser: false,
            totalTokens: totalTokens,
            originalText: responseText,
          };
        });
        setMessages((messages) => {
          const updatedMessages = messages.slice(0, -1); // Remove the loading message
          return [
            ...updatedMessages,
            {
              text: newMessages[0].text,
              isUser: false,
              totalTokens: newMessages[0].totalTokens,
            },
          ];
        });

        setConversation((conversation) => [
          ...conversation,
          { role: 'assistant', content: newMessages[0].originalText },
        ]);

        if (isSplitView && secondAdapter) {
          setSecondMessages((messages) => {
            const updatedMessages = messages.slice(0, -1); // Remove the loading message
            return [
              ...updatedMessages,
              {
                text: newMessages[1].text,
                isUser: false,
                totalTokens: newMessages[1].totalTokens,
              },
            ];
          });

          // Ensure the second adapter's conversation is updated correctly
          setSecondConversation((secondConversation) => [
            ...secondConversation,
            { role: 'assistant', content: newMessages[1].originalText },
          ]);
        }
      } catch (error) {
        if (import.meta.env.DEV) {
          console.error(error);
        }
        setMessages((messages) => messages.slice(0, -1)); // Removes the loading message
        setConversation((conversation) => conversation.slice(0, -1));
        if (isSplitView && secondAdapter) {
          setSecondMessages((messages) => messages.slice(0, -1)); // Removes the loading message
          setSecondConversation((secondConversation) =>
            secondConversation.slice(0, -1),
          );
          setInferenceErrorMessage(
            'There was an error processing your request',
          );
        }
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const controller = new AbortController();
        setStreamingController(controller);
        const response = await fetch(serverStreamingUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: JSON.stringify(payloads[0]),
          signal: controller.signal,
        });

        if (!response.ok) {
          throw new Error('Failed to generate response');
        }

        const reader = response.body.getReader();
        let decoder = new TextDecoder();
        let buffer = '';
        let accumulatedText = '';
        let details = '';
        let totalTokens = 0;

        // eslint-disable-next-line no-constant-condition
        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            break;
          }
          if (isRequestCancelled) {
            break;
          }
          buffer += decoder.decode(value, { stream: true });
          const parts = buffer.split('\n');
          for (let i = 0; i < parts.length - 1; i++) {
            const line = parts[i];
            if (line.startsWith('data:')) {
              const data = JSON.parse(line.substring(5));
              if (data.token && data.token.text) {
                accumulatedText += data.token.text;

                const parsedContent = parseMixedContent(accumulatedText);

                // const assistantMessageSegments = accumulatedText
                //   .split('\n')
                //   .map((segment, index) => (
                //     <div key={index}>
                //       {emoji.replace_unified(segment)}
                //       {index < accumulatedText.split('\n').length - 1 && <br />}
                //     </div>
                //   ));

                const assistantMessageSegments = parsedContent.map(
                  (part, index) => {
                    if (part.type === 'code') {
                      return (
                        <div key={`code-${index}`}>
                          <CodeSnippet2
                            code={part.content}
                            language={part.language || 'text'}
                          />
                        </div>
                      );
                    }
                    return (
                      <div key={`text-${index}`}>
                        {emoji.replace_unified(part.content)}
                        {index < parsedContent.length - 1 && <br />}
                      </div>
                    );
                  },
                );

                setMessages((messages) => {
                  const updatedMessages = messages.slice(0, -1);
                  return [
                    ...updatedMessages,
                    {
                      text: assistantMessageSegments,
                      isUser: false,
                      tokens: accumulatedText.length,
                      totalTokens: totalTokens,
                    },
                  ];
                });
              }
              if (data.generated_text) {
                details = data.details;
                totalTokens = details.prompt_tokens + details.generated_tokens;
                accumulatedText = data.generated_text;

                // const assistantMessageSegments = accumulatedText
                //   .split('\n')
                //   .map((segment, index) => (
                //     <Fragment key={index}>
                //       {emoji.replace_unified(segment)}
                //       {index < accumulatedText.split('\n').length - 1 && <br />}
                //     </Fragment>
                //   ));

                const parsedContent = parseMixedContent(accumulatedText);

                const assistantMessageSegments = parsedContent.map(
                  (part, index) => {
                    if (part.type === 'code') {
                      return (
                        <div key={`code-${index}`}>
                          <CodeSnippet2
                            code={part.content}
                            language={part.language || 'text'}
                          />
                        </div>
                      );
                    }
                    return (
                      <div key={`text-${index}`}>
                        {emoji.replace_unified(part.content)}
                        {index < parsedContent.length - 1 && <br />}
                      </div>
                    );
                  },
                );

                const assistantMessage = {
                  text: assistantMessageSegments,
                  isUser: false,
                  tokens: details.generated_tokens,
                  totalTokens: totalTokens,
                };

                setMessages((messages) => {
                  const updatedMessages = messages.slice(0, -1);
                  return [...updatedMessages, assistantMessage];
                });
              }
            }
          }
          buffer = parts[parts.length - 1];
        }

        setConversation((prev) => [
          ...prev,
          { role: 'assistant', content: accumulatedText },
        ]);
      } catch (error) {
        if (import.meta.env.DEV) {
          console.error(error);
        }
        if (error.name === 'AbortError') {
          setMessages((messages) => messages.slice(0, -2));
          setConversation((conversation) => conversation.slice(0, -2));
        } else {
          setMessages((messages) => messages.slice(0, -1)); // Removes the loading message
          setConversation((conversation) => conversation.slice(0, -1));
          setInferenceErrorMessage(
            'There was an error processing your request',
          );
        }
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    return () => {
      if (streamingController) {
        streamingController.abort();
      }
    };
  }, [streamingController]);

  const toggleLeftSide = () => {
    setIsLeftSideVisible(!isLeftSideVisible);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsLeftSideVisible(true);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [setIsLeftSideVisible]);

  const handleSelectPrompt = (prompt) => {
    setSelectedPrompt(prompt);
  };

  const handleEditPrompt = (prompt, e) => {
    e.stopPropagation();
    setPromptToEdit(prompt);
    setIsModalOpen(true);
  };

  const handleDeletePrompt = async (prompt) => {
    const existingPrompts = await get('SystemPrompt');
    const updatedPrompts = existingPrompts.filter(
      (existingPrompt) => existingPrompt.name !== prompt.name,
    );
    set('SystemPrompt', updatedPrompts);
    setPrompts(updatedPrompts);
    // if the deleted prompt was selected, deselect it
    if (selectedPrompt?.name === prompt.name) {
      setSelectedPrompt({ name: '', value: '' });
    }
    if (updatedPrompts.length === 1 && updatedPrompts[0].name === '(none)') {
      set('SystemPrompt', []);
      setPrompts([]);
    }
  };
  useEffect(() => {
    const updateConversation = (conv, setConv) => {
      const updatedConversation = [...conv];
      if (selectedPrompt.name === '(none)') {
        if (
          updatedConversation.length > 0 &&
          updatedConversation[0].role === 'system'
        ) {
          updatedConversation.shift();
          setConv(updatedConversation);
        }
        return;
      }
      if (selectedPrompt.name !== '') {
        if (
          updatedConversation.length > 0 &&
          updatedConversation[0].role === 'system'
        ) {
          updatedConversation[0].content = selectedPrompt.value;
        } else {
          updatedConversation.unshift({
            role: 'system',
            content: selectedPrompt.value,
          });
        }
        setConv(updatedConversation);
      } else {
        if (conv.length > 0 && conv[0].role === 'system') {
          const updatedConversation = conv.slice(1);
          setConv(updatedConversation);
        }
      }
    };

    updateConversation(conversation, setConversation);

    if (isSplitView && secondAdapter) {
      updateConversation(secondConversation, setSecondConversation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPrompt, isSplitView, secondAdapter]);

  const handleNewMessageChange = (e) => {
    setNewMessage(e.target.value);
  };

  const handleSplitView = (e) => {
    clearChat();
    setInferenceErrorMessage(null);
    setIsSplitView(e.target.checked);
  };

  return (
    <>
      <div className="w-full h-[calc(100vh)] pt-14 md:pt-4 font-dmSans text-zinc-500 bg-zinc-50 overflow-hidden">
        <div className="flex items-center w-full h-12 mt-4 md:mt-10 md:mx-10 lg:mt-0">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="flex w-5 h-5 my-auto ml-4 text-zinc-500"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 0 1-.825-.242m9.345-8.334a2.126 2.126 0 0 0-.476-.095 48.64 48.64 0 0 0-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0 0 11.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155"
            />
          </svg>
          <span className="hidden mx-2 my-auto sm:flex text-zinc-500">
            Chat Playground
          </span>{' '}
          <Transition
            show={!isSplitView}
            enter="transition-opacity duration-75"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-0"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <span className="items-center justify-center hidden w-auto px-2 py-1 my-auto border-b md:flex shadow-zinc-200 text-zinc-500">
              {adapter.display_name}
            </span>
          </Transition>
          <Transition
            show={!isSplitView}
            enter="transition-opacity duration-150"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <span className="flex items-center justify-center w-auto px-2 py-1 mx-2 my-auto border-b md:hidden shadow-zinc-200 text-zinc-500">
              {adapter?.display_name?.length > 30
                ? `${adapter?.display_name?.slice(0, 30)}...`
                : adapter?.display_name}
            </span>
          </Transition>
          <div className="flex items-center justify-start p-4">
            <input
              id="split-view"
              type="checkbox"
              checked={isSplitView}
              onChange={handleSplitView}
              className="w-4 h-4 border-gray-300 rounded text-zinc-800 focus:ring-transparent"
            />
            <label htmlFor="split-view" className="ml-2 text-sm text-zinc-500">
              Compare Side-by-Side
            </label>
          </div>
          <button
            className="flex items-center justify-center w-16 h-8 ml-4 mr-2 border md:hidden"
            onClick={toggleLeftSide}
          >
            {isLeftSideVisible ? (
              <Cog6ToothIcon className="w-6 h-6 text-indigo-400" />
            ) : (
              <ChatBubbleLeftEllipsisIcon className="w-6 h-6 text-indigo-400" />
            )}
          </button>
        </div>
        <div
          className={`grid h-full md:p-10 grid-cols-5 md:-mt-12  ${isLeftSideVisible ? 'md:grid-cols-6' : 'md:col-span-6'}`}
        >
          <div
            className={`col-span-6  ${isLeftSideVisible ? 'md:col-span-4' : 'hidden'} flex flex-col`}
          >
            {/* Left Column Content (75%) */}
            <div
              className={`relative p-2 lg:p-4 h-[73%] md:h-3/4 ${isSplitView ? 'flex' : ''}`}
            >
              <div
                data-name="chat-container-1"
                ref={chatContainerRef}
                className={clsx(
                  'h-full p-6 pr-12 overflow-y-auto border border-gray-300 max-h-[68vh] rounded-tl-xl relative bg-white box-border',
                  isSplitView ? 'flex-1 md:pr-6' : 'w-full md:pr-20',
                )}
                style={{
                  scrollBehavior: 'smooth',
                  transform: 'translateX(0px)',
                }}
              >
                <ApiWidget
                  id="1"
                  isSplitView={isSplitView}
                  model={adapter.model_name}
                  containerRef={chatContainerRef}
                  refreshButtonRef={refreshButtonRef}
                  parameters={parameters}
                  conversation={conversation}
                  isParametersMinimized={isParametersMinimized}
                  modelNameRef={modelnameRef}
                />
                <div
                  ref={modelnameRef}
                  className={clsx(
                    'absolute top-2 right-2 opacity-0 transition-opacity ease-in',
                    isSplitView ? '!opacity-100 duration-300' : 'duration-0',
                  )}
                >
                  <span className="flex items-center justify-center w-auto px-2 py-1 mx-2 my-auto border-b shadow-zinc-200 text-zinc-500">
                    {adapter.display_name}
                  </span>
                </div>
                {messages.map((message, index) => (
                  <div
                    key={index}
                    className={`flex mt-4 ${message.isUser ? 'justify-end' : 'justify-start'}`}
                  >
                    {message.loading ? (
                      <div className="animate-blink">
                        <div className="w-3 h-3 m-2 bg-indigo-200 border-2 border-indigo-100 rounded-full">
                          {/* Loading indicator */}
                        </div>
                      </div>
                    ) : (
                      <div
                        className={`m-2 p-2 text-zinc-600  whitespace-pre-wrap ${
                          message.isUser
                            ? 'border rounded-tl-lg rounded-tr-lg rounded-bl-lg border-indigo-200 bg-indigo-50 max-w-10/12'
                            : ''
                        }`}
                      >
                        {message.text}
                        {!message.isUser && message.totalTokens > 0 && (
                          <div className="mt-1 text-xs text-zinc-400">
                            {message.totalTokens} Total Tokens
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ))}
                {inferenceErrorMessage && (
                  <div className="flex justify-end m-2 mt-4">
                    <div className="p-2 text-sm text-red-500 border border-red-500 rounded-md">
                      {inferenceErrorMessage}
                    </div>
                  </div>
                )}
              </div>

              {isSplitView && (
                <div
                  data-name="chat-container-2"
                  ref={secondChatContainerRef}
                  className={clsx(
                    'flex-1 h-full p-6 pr-12 md:pr-20 overflow-y-auto border border-gray-300 max-h-[68vh] bg-white box-border',
                  )}
                  style={{
                    scrollBehavior: 'smooth',
                    // transform: 'translateX(0px)',
                  }}
                >
                  {secondAdapter && !showModelList && (
                    <ApiWidget
                      id="2"
                      isSplitView={isSplitView}
                      model={secondAdapter.model_name}
                      containerRef={secondChatContainerRef}
                      refreshButtonRef={refreshButtonRef}
                      parameters={parameters}
                      conversation={secondConversation}
                      isParametersMinimized={isParametersMinimized}
                      modelNameRef={secondModelnameRef}
                    />
                  )}
                  {secondAdapter ? (
                    <>
                      <div
                        ref={secondModelnameRef}
                        className="z-50 absolute top-2 right-2"
                      >
                        <button
                          className=" flex items-center justify-start w-auto px-2 py-1 mx-2 my-auto border-b shadow-zinc-200 text-zinc-500 text-left"
                          onClick={() => setShowModelList(!showModelList)}
                        >
                          {secondAdapter.display_name}
                        </button>
                      </div>
                      {showModelList && (
                        <div className="absolute z-[60] bg-white border border-gray-300 rounded-md shadow-lg top-10 right-2">
                          <h2 className="p-4 mb-2 text-base sm:text-lg">
                            Choose a model to compare with
                          </h2>
                          <Listbox
                            value={secondAdapter?.display_name || ''}
                            onChange={handleSelectSecondModel}
                          >
                            <div className="relative p-4 mt-1">
                              <Listbox.Button className="relative py-2 pl-3 pr-10 text-left bg-white shadow cursor-default w-72 focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-indigo-300 sm:text-sm">
                                <span className="block truncate">
                                  {secondAdapter?.display_name ||
                                    'Select a model'}
                                </span>
                                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                  <ChevronUpDownIcon
                                    className="w-5 h-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </span>
                              </Listbox.Button>
                              <Transition
                                as={Fragment}
                                leave={clsx('transition ease-in duration-100')}
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options className="absolute z-50 py-1 mt-1 overflow-auto text-base bg-white shadow-lg w-72 max-h-60 ring-1 ring-black/5 focus:outline-none sm:text-sm">
                                  <GroupedModelsList
                                    availableModels={availableModels}
                                  />
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </Listbox>
                        </div>
                      )}
                      {secondMessages.map((message, index) => (
                        <div
                          key={index}
                          className={`flex mt-4 ${message.isUser ? 'justify-end' : 'justify-start'}`}
                        >
                          {message.loading ? (
                            <div className="animate-blink">
                              <div className="w-3 h-3 m-2 bg-indigo-200 border-2 border-indigo-100 rounded-full">
                                {/* Loading indicator */}
                              </div>
                            </div>
                          ) : (
                            <div
                              className={`m-2 p-2 text-zinc-600  whitespace-pre-wrap ${
                                message.isUser
                                  ? 'border rounded-tl-lg rounded-tr-lg rounded-bl-lg border-indigo-200 bg-indigo-50 max-w-10/12'
                                  : ''
                              }`}
                            >
                              {message.text}
                              {!message.isUser && message.totalTokens > 0 && (
                                <div className="mt-1 text-xs text-zinc-400">
                                  {message.totalTokens} Total Tokens
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      ))}
                      {inferenceErrorMessage && (
                        <div className="flex justify-end m-2 mt-4">
                          <div className="p-2 text-sm text-red-500 border border-red-500 rounded-md">
                            {inferenceErrorMessage}
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="-mt-3">
                      <h2 className="mb-2 text-base sm:text-lg ">
                        Choose a model to compare with
                      </h2>
                      <Listbox
                        value={secondAdapter?.display_name || ''}
                        onChange={handleSelectSecondModel}
                      >
                        <div className="relative mt-1">
                          <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white shadow cursor-default focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-indigo-300 sm:text-sm">
                            <span className="block truncate">
                              {secondAdapter?.display_name || 'Select a model'}
                            </span>
                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                              <ChevronUpDownIcon
                                className="w-5 h-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </span>
                          </Listbox.Button>
                          <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="absolute z-50 w-full py-1 mt-1 overflow-auto text-base bg-white shadow-lg max-h-60 ring-1 ring-black/5 focus:outline-none sm:text-sm">
                              <GroupedModelsList
                                availableModels={availableModels}
                              />
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </Listbox>
                    </div>
                  )}
                </div>
              )}

              <div
                ref={refreshButtonRef}
                className="absolute z-[49] flex items-center justify-center w-10 h-10 m-2 border md:h-16 md:w-16 hover:shadow hover:shadow-zinc-200 md:right-7 right-5 bottom-7 group"
              >
                <button
                  className="flex items-center justify-center w-full h-full"
                  onClick={clearChat}
                >
                  {/* Invisible button */}
                </button>
                {/* Position SVG as overlay */}
                <div className="absolute inset-0 flex items-center justify-center pointer-events-none">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 transition-transform duration-500 ease-in-out text-zinc-500 group-hover:-rotate-90 group-hover:text-indigo-500"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className="relative p-2 pt-0 md:p-4 md:h-1/4 h-1/6">
              <textarea
                className="w-full h-full pt-4 pl-8 pr-20 bg-white border border-gray-300 shadow resize-none md:pr-36 rounded-bl-xl shadow-zinc-200 focus:outline-none focus:border-indigo-200 focus:ring-1 focus:ring-indigo-200"
                placeholder="Enter text here"
                value={newMessage}
                onChange={handleNewMessageChange}
                disabled={hasApiKeys === false}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey && !loading) {
                    e.preventDefault();
                    sendMessage(e);
                  }
                }}
              ></textarea>
              <div className="absolute flex items-center justify-center w-10 h-10 m-2 border md:h-16 md:w-16 hover:shadow hover:shadow-zinc-200 md:right-7 right-5 bottom-7 hover:border-indigo-200 hover:rounded-t-lg hover:rounded-l-lg group hover:bg-indigo-50">
                <button
                  className="flex items-center justify-center w-full h-full "
                  disabled={loading}
                  onClick={(e) => {
                    sendMessage(e);
                  }}
                >
                  {/* Invisible button */}
                </button>
                {/* Position SVG as overlay */}
                <div className="absolute inset-0 flex items-center justify-center pointer-events-none ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 transition-transform duration-500 ease-in-out text-zinc-500 group-hover:-rotate-90 group-hover:text-indigo-500"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`py-4  md:block ${isLeftSideVisible ? 'hidden' : 'col-span-6 pr-2 ml-2'} min-w-80 overflow-y-auto `}
          >
            {/* Control Panel Content */}
            <div className="w-full min-h-[calc(100%-60px)] mb-20 md:mb-0 md:h-full  px-8 py-4 bg-white border border-gray-300 shadow-lg shadow-zinc-200 rounded-r-xl flex flex-col gap-y-8">
              <div>
                <div className="mb-4 text-lg">Model</div>
                <div className="w-full">
                  <Listbox
                    value={adapter?.display_name || ''}
                    onChange={handleSelectModel}
                  >
                    <div className="relative mt-1">
                      <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white shadow cursor-default focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-indigo-300 sm:text-sm">
                        <span className="block truncate">
                          {adapter?.display_name || 'Select a model'}
                        </span>
                        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                          <ChevronUpDownIcon
                            className="w-5 h-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>
                      <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute z-50 w-full py-1 mt-1 overflow-auto text-base bg-white shadow-lg max-h-60 ring-1 ring-black/5 focus:outline-none sm:text-sm">
                          <GroupedModelsList
                            availableModels={availableModels}
                          />
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </Listbox>
                </div>
                <div className="flex justify-between w-full mt-8 mb-4">
                  <span className="text-lg ">System Prompt</span>
                  <button
                    onClick={openModal}
                    className="flex items-center justify-center w-6 h-6 border hover:bg-indigo-50 hover:shadow hover:shadow-zinc-200"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5 text-zinc-500"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </button>
                  {isModalOpen && (
                    <CreatePromptModal
                      onClose={() => setIsModalOpen(false)}
                      addNewPrompt={addPromptToTheList}
                      prompt={promptToEdit}
                      deletePrompt={handleDeletePrompt}
                    />
                  )}
                </div>
                <div className="">
                  <Listbox value={selectedPrompt} onChange={handleSelectPrompt}>
                    <div className="relative mt-1">
                      <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white shadow cursor-default focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-indigo-300 sm:text-sm">
                        <span className="block truncate">
                          {selectedPrompt.name !== ''
                            ? selectedPrompt.name
                            : 'Select a prompt'}
                        </span>
                        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                          <ChevronUpDownIcon
                            className="w-5 h-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>
                      <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white shadow-lg max-h-60 ring-1 ring-black/5 focus:outline-none sm:text-sm">
                          {prompts.map((prompt, promptIdx) => (
                            <Listbox.Option
                              key={promptIdx}
                              className={({ active }) =>
                                `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                  active
                                    ? 'bg-indigo-100 text-indigo-900'
                                    : 'text-gray-900'
                                }`
                              }
                              value={prompt}
                            >
                              {({ active }) => (
                                <>
                                  <span className="block font-normal truncate">
                                    {prompt.name}
                                  </span>
                                  {prompt.name !== '(none)' && active ? (
                                    <button
                                      className="absolute inset-y-0 right-0 px-2 text-indigo-600 hover:bg-indigo-200 hover:shadow hover:shadow-zinc-200 flex-center"
                                      onClick={(e) =>
                                        handleEditPrompt(prompt, e)
                                      }
                                    >
                                      <PencilIcon className="w-4 h-4" />
                                    </button>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </Listbox>
                </div>
              </div>
              <div>
                <div className="flex items-center justify-between mb-4">
                  <span className="text-lg">
                    Parameters{' '}
                    <span
                      className={clsx(
                        'text-zinc-400 text-xs',
                        isParametersMinimized ? 'inline-block' : 'hidden',
                      )}
                    >
                      (Off)
                    </span>
                  </span>
                  <button
                    onClick={() =>
                      setIsParametersMinimized(!isParametersMinimized)
                    }
                  >
                    {isParametersMinimized ? (
                      <ChevronUpIcon className="w-5 h-5" />
                    ) : (
                      <ChevronDownIcon className="w-5 h-5" />
                    )}
                  </button>
                </div>
                {isParametersMinimized ? null : (
                  <div className="">
                    {/* max_tokens */}
                    <div className="flex items-center justify-between mb-1 ">
                      <label className="block text-sm text-zinc-600 dark:text-white">
                        Max Tokens
                      </label>
                      <input
                        type="text"
                        onChange={(e) => {
                          e.target.value === ''
                            ? setParameters({
                                ...parameters,
                                max_tokens: -1,
                              })
                            : setParameters({
                                ...parameters,
                                max_tokens: parseInt(e.target.value) || -1,
                              });
                        }}
                        className="w-16 h-6 border border-zinc-200 ring-zinc-200 text-center text-zinc-600 dark:text-white focus:border-0 focus:!ring-zinc-400 focus:ring-offset-1 focus:rounded-sm focus:ring-2 px-1"
                        value={
                          parameters?.max_tokens === -1
                            ? '-'
                            : parameters?.max_tokens
                        }
                      />
                    </div>
                    <input
                      type="range"
                      min="1"
                      max="4095"
                      value={parameters.max_tokens}
                      step="1"
                      onChange={(e) =>
                        setParameters({
                          ...parameters,
                          max_tokens: parseInt(e.target.value),
                        })
                      }
                      className="w-full h-0.5 mb-6 rounded-lg appearance-none cursor-pointer bg-gray-200"
                    />

                    {/* temperature */}
                    <div className="flex items-center justify-between mb-1 ">
                      <label
                        className="block text-sm text-zinc-600 dark:text-white"
                        htmlFor="temperature-range"
                      >
                        Temperature
                      </label>
                      <input
                        type="text"
                        onChange={(e) => {
                          e.target.value === ''
                            ? setParameters({
                                ...parameters,
                                temperature: -1,
                              })
                            : setParameters({
                                ...parameters,
                                temperature: parseFloat(e.target.value),
                              });
                        }}
                        className="w-16 h-6 border border-zinc-200 ring-zinc-200 text-center text-zinc-600 dark:text-white focus:border-0 focus:!ring-zinc-400 focus:ring-offset-1 focus:rounded-sm focus:ring-2 px-1"
                        value={
                          parameters?.temperature === -1
                            ? '-'
                            : parameters?.temperature
                        }
                      />
                    </div>
                    <input
                      id="temperature-range"
                      type="range"
                      min="0"
                      max="1"
                      value={parameters?.temperature}
                      step="0.1"
                      onChange={(e) =>
                        setParameters({
                          ...parameters,
                          temperature: parseFloat(e.target.value),
                        })
                      }
                      className="w-full h-0.5 mb-6 rounded-lg appearance-none cursor-pointer bg-gray-200"
                    />

                    {/* top_p */}
                    <div className="flex items-center justify-between mb-1 ">
                      <label
                        htmlFor="top_p-range"
                        className="block text-sm text-zinc-600 dark:text-white"
                      >
                        Top-P
                      </label>
                      <input
                        type="text"
                        className="w-16 h-6 border border-zinc-200 ring-zinc-200 text-center text-zinc-600 dark:text-white focus:border-0 focus:!ring-zinc-400 focus:ring-offset-1 focus:rounded-sm focus:ring-2 px-1"
                        value={
                          parameters?.top_p === -1 ? '-' : parameters?.top_p
                        }
                        onChange={(e) =>
                          e.target.value === ''
                            ? setParameters({
                                ...parameters,
                                top_p: -1,
                              })
                            : setParameters({
                                ...parameters,
                                top_p: parseFloat(e.target.value),
                              })
                        }
                      />
                    </div>
                    <input
                      id="top_p-range"
                      type="range"
                      min="0"
                      max="1"
                      value={parameters?.top_p}
                      step="0.1"
                      // style={thumbStyle}
                      onChange={(e) =>
                        setParameters({
                          ...parameters,
                          top_p: parseFloat(e.target.value),
                        })
                      }
                      className="w-full h-0.5 mb-6 rounded-lg appearance-none cursor-pointer bg-gray-200"
                    />

                    {/* top_k */}
                    <div className="flex items-center justify-between mb-1 ">
                      <label
                        htmlFor="top_k-range"
                        className="block text-sm text-zinc-600 dark:text-white"
                      >
                        Top-K
                      </label>
                      <input
                        type="text"
                        className="w-16 h-6 border border-zinc-200 ring-zinc-200 text-center text-zinc-600 dark:text-white focus:border-0 focus:!ring-zinc-400 focus:ring-offset-1 focus:rounded-sm focus:ring-2 px-1"
                        value={
                          parameters?.top_k === -1 ? '-' : parameters?.top_k
                        }
                        onChange={(e) =>
                          e.target.value === ''
                            ? setParameters({
                                ...parameters,
                                top_k: -1,
                              })
                            : setParameters({
                                ...parameters,
                                top_k: parseInt(e.target.value),
                              })
                        }
                      />
                    </div>
                    <input
                      id="top_k-range"
                      type="range"
                      min="0"
                      max="100"
                      value={parameters?.top_k}
                      step="1"
                      // style={thumbStyle}
                      onChange={(e) =>
                        setParameters({
                          ...parameters,
                          top_k: parseInt(e.target.value),
                        })
                      }
                      className="w-full h-0.5 mb-6 rounded-lg appearance-none cursor-pointer bg-gray-200"
                    />

                    {/* presence_penalty */}
                    <div className="flex items-center justify-between mb-1 ">
                      <label
                        htmlFor="presence_penalty-range"
                        className="block text-sm text-zinc-600 dark:text-white"
                      >
                        Presence Penalty
                      </label>
                      <input
                        type="text"
                        className="w-16 h-6 border border-zinc-200 ring-zinc-200 text-center text-zinc-600 dark:text-white focus:border-0 focus:!ring-zinc-400 focus:ring-offset-1 focus:rounded-sm focus:ring-2 px-1"
                        value={
                          parameters?.presence_penalty === -1
                            ? '-'
                            : parameters?.presence_penalty
                        }
                        onChange={(e) =>
                          e.target.value === ''
                            ? setParameters({
                                ...parameters,
                                presence_penalty: -1,
                              })
                            : setParameters({
                                ...parameters,
                                presence_penalty: parseFloat(e.target.value),
                              })
                        }
                      />
                    </div>
                    <input
                      id="presence_penalty-range"
                      type="range"
                      min="0"
                      max="1"
                      value={parameters?.presence_penalty}
                      step="0.1"
                      // style={thumbStyle}
                      onChange={(e) =>
                        setParameters({
                          ...parameters,
                          presence_penalty: parseFloat(e.target.value),
                        })
                      }
                      className="w-full h-0.5 mb-6 rounded-lg appearance-none cursor-pointer bg-gray-200"
                    />

                    {/* repetition_penalty */}
                    <div className="flex items-center justify-between mb-1 ">
                      <label
                        htmlFor="repetition_penalty-range"
                        className="block text-sm text-zinc-600 dark:text-white"
                      >
                        Repetition Penalty
                      </label>
                      <input
                        type="text"
                        className="w-16 h-6 border border-zinc-200 ring-zinc-200 text-center text-zinc-600 dark:text-white focus:border-0 focus:!ring-zinc-400 focus:ring-offset-1 focus:rounded-sm focus:ring-2 px-1"
                        value={
                          parameters?.repetition_penalty === -1
                            ? '-'
                            : parameters?.repetition_penalty
                        }
                        onChange={(e) =>
                          e.target.value === ''
                            ? setParameters({
                                ...parameters,
                                repetition_penalty: -1,
                              })
                            : setParameters({
                                ...parameters,
                                repetition_penalty: parseFloat(e.target.value),
                              })
                        }
                      />
                    </div>
                    <input
                      id="repetition_penalty-range"
                      type="range"
                      min="0"
                      max="1"
                      value={parameters?.repetition_penalty}
                      step="0.1"
                      onChange={(e) =>
                        setParameters({
                          ...parameters,
                          repetition_penalty: parseFloat(e.target.value),
                        })
                      }
                      className="w-full h-0.5 mb-6 rounded-lg appearance-none cursor-pointer bg-gray-200"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <GenerateApiKeyModal
        isOpen={isApiKeyModalOpen}
        onClose={() => setIsApiKeyModalOpen(false)}
        onGenerateKey={handleGenerateKey}
      />
      <NoDeployedModelModal
        showModal={showNoDeployedModelModal}
        closeModal={() => setShowNoDeployedModelModal(false)}
      />
      <FineTuneCreateErrorModal
        showModal={showCreditCardModal}
        closeModal={() => setShowCreditCardModal(false)}
        errorCode={'no_payment_method'}
      />
    </>
  );
};

export default TailorPlayground;
