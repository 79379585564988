import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { useUser } from '../../UserContext';
import CompletedJob from './TrainingMetrics/CompletedJob';
import Spinner from '../Spinner';
import { getLogMessage } from '../../utils/TailorUtils';
import ModelEvaluationsResults from './ModelEvaluations/ModelEvaluationsResults';
import ModelEvaluations from './ModelEvaluations/ModelEvaluations';
// import ModelLeaderboard from './ModelEvaluations/ModelLeaderboard';

const TailorEvaluate = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('tab') || 'evaluation';

  const [finetuningJobs, setFinetuningJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const { customAxios } = useUser();
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [selectedMenuOption, setSelectedMenuOption] = useState('');

  const navigate = useNavigate();

  const readyStates = useMemo(
    () => [
      'dormant',
      'deploying',
      'deployed',
      'undeploying',
      'failed_deploy',
      'failed_undeploy',
    ],
    [],
  );

  const menuOptions = useMemo(
    () => ['Model Evaluations', 'Training Metrics'],
    // () => ['Training Metrics', 'Model Evaluations', 'Custom Evaluations'],
    [],
  );

  const fetchDeployList = useCallback(async () => {
    setLoading(true);
    try {
      const response = await customAxios.get('tailor/v1/models');
      const models = response?.data?.message || [];
      // sort models by created_at with the latest first
      models.sort(
        (a, b) => new Date(b.created_at_unix) - new Date(a.created_at_unix),
      );
      let modelsToDisplay = [];
      models.forEach((model) => {
        // just add the models that the model.state is one of the ones in readyStates
        if (readyStates.includes(model.state.toLowerCase())) {
          modelsToDisplay.push(model);
        }
      });

      setFinetuningJobs(modelsToDisplay);
    } catch (error) {
      if (import.meta.env.DEV) {
        console.error(error);
      }
    } finally {
      setLoading(false);
    }
  }, [customAxios, readyStates]);

  useEffect(() => {
    fetchDeployList();
  }, [customAxios, fetchDeployList]);

  // useEffect that each time a selectedJob is changed, we will change the selectedMenuOption to 'Training Metrics'
  useEffect(() => {
    if (
      tab.toLowerCase() === 'evaluate' ||
      tab.toLowerCase() === 'model-evaluations' ||
      tab.toLowerCase() === 'model evaluations' ||
      tab.toLowerCase() === 'evaluations' ||
      tab.toLowerCase() === 'evaluation' ||
      tab.toLowerCase() === 'model evaluation'
    ) {
      setSelectedMenuOption('Model Evaluations');
    } else {
      setSelectedMenuOption('Training Metrics');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedJob]);

  const handleClickToSelectJob = (job) => {
    setSelectedJob((prev) => (prev?.model_id === job.model_id ? null : job));
  };

  const updateIsMobile = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', updateIsMobile);
    return () => {
      window.removeEventListener('resize', updateIsMobile);
    };
  }, []);

  const handleSelectMenuOption = (option) => {
    // change the tab in the url to the selected option
    setSearchParams({ tab: option });
    setSelectedMenuOption(option);
  };

  return (
    <div className="flex flex-col min-h-screen bg-zinc-50 font-dmSans">
      <header className="flex flex-col items-center justify-between h-16 px-4 text-xl font-medium md:flex-row text-zinc-800">
        <div className="items-baseline hidden gap-4 md:flex h-fit">
          <div>Model Evaluations</div>
          <small className="text-sm font-light text-zinc-600">
            These models completed training successfully
          </small>
        </div>
        <div className=""></div>
      </header>
      <hr className="border-t border-zinc-300" />

      <div className="flex flex-col md:flex-row overflow-y-hidden h-[calc(100vh-68px)] divide-y md:divide-y-0 md:divide-x-2">
        <div className="w-full p-4 overflow-y-scroll md:w-1/4 md:max-w-96 md:min-w-60 text-zinc-700">
          {loading && (
            <div className="flex items-center justify-around h-full">
              <div className="flex flex-col items-center gap-2">
                <Spinner size={'36px'} borderTopColor={'gray'} />
              </div>
            </div>
          )}
          {!loading && finetuningJobs.length === 0 ? (
            <div className="flex items-center justify-around h-full">
              <div className="flex flex-col items-center gap-2">
                <div>No fine-tuning jobs found</div>
                <button
                  className="px-3 py-1 mx-auto text-base font-normal bg-indigo-200 rounded-md text-zinc-900 h-fit-content"
                  onClick={() => navigate('/tailor/finetuning')}
                >
                  Create new
                </button>
              </div>
            </div>
          ) : (
            <>
              {finetuningJobs.map((job) => (
                <div key={job.model_id} className="w-full mb-4">
                  <button
                    className={clsx(
                      'flex flex-col w-full p-4 bg-white border rounded border-zinc-300',
                      selectedJob?.model_id === job.model_id &&
                        '!outline-zinc-600 !shadow !shadow-zinc-300 outline outline-1 border-none',
                    )}
                    onClick={() => handleClickToSelectJob(job)}
                  >
                    <div className="flex items-baseline justify-between w-full">
                      <div className="font-semibold text-left text-zinc-900 grow">
                        {job.model_name}
                      </div>
                    </div>
                    <div className="mt-1 text-xs text-left grow min-h-6 md:text-sm">
                      {job.base_model_data?.display_name
                        ? `Base: ${job.base_model_data?.display_name}`
                        : ''}
                    </div>
                    <div className="flex gap-1 text-xs text-left min-h-6 md:text-sm md:gap-2">
                      <div className="min-w-fit">
                        Training Data: {getLogMessage(job)}
                      </div>
                    </div>
                    <div className="flex items-baseline justify-between w-full mt-2">
                      <div className="text-sm text-zinc-500">
                        Created on{' '}
                        {new Date(job.created_at).toLocaleDateString('en-US', {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                        })}
                      </div>
                    </div>
                  </button>
                  {isMobile && selectedJob?.model_id === job.model_id && (
                    <div className="w-full p-4 mt-2 border rounded bg-gray-50 border-zinc-300">
                      <div className="flex w-full gap-6 justify-left">
                        {menuOptions.map((option, index) => (
                          <button
                            key={index}
                            className={clsx(
                              'text-zinc-700 h-10 text-lg font-normal',
                              selectedMenuOption === option &&
                                'underline underline-offset-8 font-semibold text-zinc-900',
                            )}
                            onClick={() => handleSelectMenuOption(option)}
                          >
                            {option}
                          </button>
                        ))}
                      </div>
                      {readyStates.includes(selectedJob.state) &&
                        selectedMenuOption === 'Training Metrics' && (
                          <CompletedJob
                            job={selectedJob}
                            status={'completed'}
                          />
                        )}
                      {selectedMenuOption === 'Model Evaluations' && (
                        <ModelEvaluations job={selectedJob} />
                        // <ModelEvaluationsResults job={selectedJob} />
                      )}
                    </div>
                  )}
                </div>
              ))}
            </>
          )}
        </div>
        {!isMobile && (
          <div className="w-full h-full p-4 overflow-y-hidden text-zinc-700">
            {!selectedJob && (
              <div className="flex flex-col items-center w-full h-full text-zinc-700">
                <div>Select a model to view training details</div>
                {/* <ModelLeaderboard /> */}
              </div>
            )}
            {selectedJob && readyStates.includes(selectedJob.state) && (
              <>
                {(selectedJob.state === 'deployed' ||
                  selectedJob.state === 'deploying' ||
                  selectedJob.state === 'failed_undeploy') && (
                  <div className="self-start w-full mb-4 text-xs md:text-base">
                    <div className="flex items-center gap-8 px-4 py-2 mx-auto border rounded-lg w-fit">
                      <div>This model is currently deployed</div>
                      <Link
                        to={`../tailor/deploy/${selectedJob.model_id}`}
                        className="px-4 py-2 font-semibold rounded-lg md:text-sm bg-zinc-200"
                      >
                        Go to Deploy
                      </Link>
                    </div>
                  </div>
                )}
                {/* create a menu that can be selected and selected menu is bold and underline */}
                <div className="flex gap-4">
                  {menuOptions.map((option, index) => (
                    <button
                      key={index}
                      className={clsx(
                        'text-zinc-700 h-10 text-lg font-normal',
                        selectedMenuOption === option &&
                          'underline underline-offset-8 font-semibold text-zinc-900',
                      )}
                      onClick={() => handleSelectMenuOption(option)}
                    >
                      {option}
                    </button>
                  ))}
                </div>
                <hr className="my-2 border-t border-zinc-300" />
                {selectedMenuOption === 'Training Metrics' && (
                  <CompletedJob job={selectedJob} status={'completed'} />
                )}
                {selectedMenuOption === 'Model Evaluations' && (
                  <ModelEvaluations job={selectedJob} />
                  // <ModelEvaluationsResults job={selectedJob} />
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TailorEvaluate;
