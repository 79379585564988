import { useState, Fragment, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import toast from 'react-hot-toast';

import { useUser } from '../../../UserContext';
import Spinner from '../../Spinner';

const SynthesizeDataModal = ({ isOpen, onClose, filters, fetchLogs }) => {
  const [areFiltersApplied, setAreFiltersApplied] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successfullySubmitted, setSuccessfullySubmitted] = useState(false);
  const { customAxios } = useUser();

  function closeModal() {
    onClose();
  }

  useEffect(() => {
    setAreFiltersApplied(
      filters.models.length ||
        filters.tags.length ||
        filters.startDate ||
        filters.endDate,
    );
  }, [filters]);

  const handleSubmit = async () => {
    setError(null);
    setLoading(true);
    const payload = {
      api_provider: 'together',
    };
    if (filters.models.length) {
      payload.models = filters.models;
    }
    if (filters.tags.length) {
      payload.tags = filters.tags;
    }
    if (filters.startDate) {
      payload.from_date = filters.startDate;
    }
    if (filters.endDate) {
      payload.to_date = filters.endDate;
    }
    try {
      const res = await customAxios.post(
        'tailor/v1/synthetic_data/generate',
        payload,
      );
      if (import.meta.env.DEV) {
        console.log('res', res);
      }
      toast.success('Log synthesis initiated');
      fetchLogs();
      // closeModal();
      setSuccessfullySubmitted(true);
    } catch (error) {
      if (import.meta.env.DEV) {
        console.error('Error synthesizing logs:', error);
      }
      toast.error('Failed to synthesize logs');
    } finally {
      setLoading(false);
    }
  };

  const handleCloseModal = () => {
    setError(null);
    setSuccessfullySubmitted(false);
    closeModal();
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={handleCloseModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white rounded-md shadow-xl font-dmSans">
                <Dialog.Title
                  as="h3"
                  className="mb-4 text-lg font-medium leading-6 text-zinc-800"
                >
                  Synthesize logs data
                </Dialog.Title>
                <div className="py-2 mb-4 text-sm drop-shadow-sm text-zinc-600">
                  <span className="font-semibold text-zinc-800">Filters</span>
                  {areFiltersApplied ? (
                    Object.entries(filters).map(
                      ([key, value]) =>
                        (Array.isArray(value) ? value.length > 0 : !!value) && (
                          <div
                            key={key}
                            className="flex flex-wrap items-center gap-2 my-4"
                          >
                            <span className="capitalize text-zinc-800">
                              {key === 'startDate'
                                ? 'Start Date'
                                : key === 'endDate'
                                  ? 'End Date'
                                  : key}
                              :{' '}
                            </span>
                            {key === 'startDate' || key === 'endDate' ? (
                              <span className="px-3 py-1 text-sm font-medium border border-indigo-200 rounded-full text-zinc-700 bg-zinc-50/50">
                                {new Date(value).toLocaleDateString('en-US', {
                                  year: 'numeric',
                                  month: 'short',
                                  day: 'numeric',
                                })}{' '}
                                {new Date(value).toLocaleTimeString('en-US', {
                                  hour: '2-digit',
                                  minute: '2-digit',
                                })}
                              </span>
                            ) : (
                              value.map((item, index) => (
                                <span
                                  key={index}
                                  className="px-3 py-1 text-sm font-medium border border-indigo-200 rounded-full text-zinc-700 bg-zinc-50/50"
                                >
                                  {item}
                                </span>
                              ))
                            )}
                          </div>
                        ),
                    )
                  ) : (
                    <div className="flex flex-wrap items-center gap-2 my-4">
                      <span className="capitalize text-zinc-800">
                        No filters were applied. All logs will be used.
                      </span>
                    </div>
                  )}
                </div>

                <div className="mt-2 text-sm text-zinc-600">
                  If this data was collected from a model which outputs cannot
                  be used for training, we recommend you create a rephrased
                  synthetic version of the logs which can be used to improve the
                  model's quality.
                </div>
                {error && (
                  <div className="mt-2 text-sm text-red-500">{error}</div>
                )}

                <div className="flex items-center mt-4 ">
                  {successfullySubmitted ? (
                    <button
                      type="button"
                      className="inline-flex items-center justify-center h-10 px-4 py-2 text-sm font-medium text-indigo-900 bg-indigo-100 border border-transparent rounded-md w-36 hover:bg-indigo-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2"
                      onClick={handleCloseModal}
                    >
                      Dismiss
                    </button>
                  ) : (
                    <>
                      <button
                        type="button"
                        className="inline-flex items-center justify-center h-10 px-4 py-2 text-sm font-medium text-indigo-900 bg-indigo-100 border border-transparent rounded-md w-36 hover:bg-indigo-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2"
                        onClick={handleSubmit}
                        disabled={loading}
                      >
                        {loading ? (
                          <Spinner size="20px" borderTopColor="black" />
                        ) : (
                          'Synthesize'
                        )}
                      </button>
                      <button
                        type="button"
                        className="inline-flex items-center justify-center h-10 px-4 py-2 ml-2 text-sm font-medium text-red-900 border border-gray-300 rounded-md hover:bg-red-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2"
                        onClick={handleCloseModal}
                      >
                        Cancel
                      </button>
                    </>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default SynthesizeDataModal;
