import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import clsx from 'clsx';
import { Button } from 'flowbite-react';

import { useUser } from '../../UserContext';
import CompletedJob from './TrainingMetrics/CompletedJob';
import Spinner from '../Spinner';
import { getLogMessage } from '../../utils/TailorUtils';

const TailorTrainingMetrics = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const filter = searchParams.get('filter') || 'running';
  const [finetuningJobs, setFinetuningJobs] = useState([]);
  const [displayedJobs, setDisplayedJobs] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState('all');
  const [selectedJob, setSelectedJob] = useState(null);
  const { customAxios } = useUser();
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [fetchNow, setFetchNow] = useState(false);

  const navigate = useNavigate();

  const failedStates = useMemo(() => ['failed_training', 'cancelled'], []);

  const trainingStates = useMemo(() => ['start_training', 'training'], []);

  const handleFilterJobs = useCallback(
    (e) => {
      setSelectedJob(null);
      const filter = e.target.value;
      setSelectedFilter(filter);
      if (!filter || filter !== 'failed') {
        setDisplayedJobs(finetuningJobs);
        setSearchParams({ filter: 'running' });
      }
      if (filter === 'all') {
        setDisplayedJobs(finetuningJobs);
        setSearchParams({ filter: 'all' });
      } else {
        const filteredJobs = finetuningJobs.filter(
          (job) => job.training_status.toLowerCase() === filter,
        );
        setDisplayedJobs(filteredJobs);
        setSearchParams({ filter });
      }
    },
    [finetuningJobs, setSearchParams],
  );

  useEffect(() => {
    setSelectedFilter(filter ? filter?.toLowerCase() : 'running');
    handleFilterJobs({ target: { value: filter } });
  }, [filter, handleFilterJobs]);

  const getModelStatus = (job) => {
    if (job.state.toLowerCase().includes('fail')) {
      return 'failed';
    } else if (job.state.toLowerCase().includes('train')) {
      return 'running';
    } else {
      return 'completed';
    }
  };

  const fetchDeployList = useCallback(async () => {
    setLoading(true);
    try {
      const response = await customAxios.get('tailor/v1/models');
      const models = response?.data?.message || [];
      // sort models by created_at with the latest first
      models.sort(
        (a, b) => new Date(b.created_at_unix) - new Date(a.created_at_unix),
      );
      let modelsToDisplay = [];
      models.forEach((model) => {
        // just add the models that the model.state is one of the ones in trainingStates and failedStates
        if (
          trainingStates.includes(model.state) ||
          failedStates.includes(model.state)
        ) {
          model.training_status = getModelStatus(model);
          modelsToDisplay.push(model);
        }
      });

      setFinetuningJobs(modelsToDisplay);
    } catch (error) {
      if (import.meta.env.DEV) {
        console.error(error);
      }
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customAxios]);

  useEffect(() => {
    fetchDeployList();
  }, [customAxios, fetchDeployList]);

  useEffect(() => {
    if (fetchNow) {
      setFetchNow(false);
      setDisplayedJobs((prev) =>
        prev.filter((job) => job.model_id !== selectedJob.model_id),
      );
      setSelectedJob(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchNow, fetchDeployList]);

  const handleClickToSelectJob = (job) => {
    setSelectedJob((prev) => (prev?.model_id === job.model_id ? null : job));
  };

  const updateIsMobile = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', updateIsMobile);
    return () => {
      window.removeEventListener('resize', updateIsMobile);
    };
  }, []);

  const Buttons = () => (
    <Button.Group className="h-8">
      <Button
        onClick={() => handleFilterJobs({ target: { value: 'running' } })}
        color={selectedFilter === 'running' ? '#C7D2FE' : 'gray'}
        className={clsx(
          selectedFilter === 'running' ? 'bg-indigo-200 shadow' : 'gray',
          'py-0 focus:text-gray-900 focus:ring-0 enabled:hover:text-gray-900  min-w-28 ',
        )}
        size="sm"
      >
        Running
      </Button>
      <Button
        onClick={() => handleFilterJobs({ target: { value: 'failed' } })}
        color={selectedFilter === 'failed' ? '#C7D2FE' : 'gray'}
        className={clsx(
          selectedFilter === 'failed' ? 'bg-indigo-200 shadow' : 'gray',
          'py-0 focus:text-gray-900 focus:ring-0 enabled:hover:text-gray-900 min-w-28 ',
        )}
        size="sm"
      >
        Failed
      </Button>
    </Button.Group>
  );

  return (
    <div className="bg-zinc-50 font-dmSans">
      <header className="pt-10 lg:pt-0">
        <div className="flex justify-between h-16 p-4 text-xl font-medium text-zinc-800">
          <div className="flex items-center gap-4 h-fit">
            <div>Fine-tuning Jobs</div>
            <span className="hidden sm:flex">
              {' '}
              <Buttons />
            </span>
          </div>
        </div>
        <hr className="border-t border-zinc-300" />
      </header>
      <div className="flex justify-around mt-4 sm:hidden">
        <Buttons />
      </div>
      <div className="flex flex-col md:flex-row overflow-y-hidden h-[calc(100vh-68px)] divide-y md:divide-y-0 md:divide-x-2">
        <div className="w-full p-4 overflow-y-scroll md:w-1/3 text-zinc-700">
          {loading && (
            <div className="flex items-center justify-around h-full">
              <div className="flex flex-col items-center gap-2">
                <Spinner size={'36px'} borderTopColor={'gray'} />
              </div>
            </div>
          )}
          {!loading && finetuningJobs.length === 0 ? (
            <div className="flex items-center justify-around h-full">
              <div className="flex flex-col items-center gap-2">
                <div>No fine-tuning jobs found</div>
                <button
                  className="px-3 py-1 mx-auto text-base font-normal bg-indigo-200 rounded-md text-zinc-900 h-fit-content"
                  onClick={() => navigate('/tailor/finetuning')}
                >
                  Create new
                </button>
              </div>
            </div>
          ) : displayedJobs.length === 0 ? (
            <div className="flex items-center justify-around h-full">
              <div className="flex flex-col items-center gap-2">
                <div>
                  No models found in the{' '}
                  <span className="capitalize">{selectedFilter}</span> state
                </div>
              </div>
            </div>
          ) : (
            <>
              {displayedJobs.map((job) => (
                <div key={job.model_id} className="mb-4">
                  <button
                    className={clsx(
                      'flex flex-col w-full p-4 bg-white border rounded border-zinc-300',
                      selectedJob?.model_id === job.model_id &&
                        '!outline-zinc-600 !shadow !shadow-zinc-300 outline outline-1 border-none',
                    )}
                    onClick={() => handleClickToSelectJob(job)}
                  >
                    <div className="flex items-baseline justify-between w-full">
                      <div className="font-semibold text-left truncate text-zinc-900 grow">
                        {job.model_name}
                      </div>
                      <div
                        className={clsx(
                          'text-sm min-w-fit pl-2 text-zinc-600 capitalize',
                          job.training_status === 'failed' && '!text-red-500',
                          job.training_status === 'completed' &&
                            '!text-indigo-500',
                          job.training_status === 'running' && '!text-zinc-500',
                        )}
                      >
                        {getModelStatus(job)}
                      </div>
                    </div>
                    <div className="mt-1 text-xs text-left grow min-h-6 md:text-sm">
                      {job.base_model_data?.display_name
                        ? `Base: ${job.base_model_data?.display_name}`
                        : ''}
                    </div>
                    <div className="flex gap-1 text-xs text-left min-h-6 md:text-sm md:gap-2">
                      <div className="min-w-fit">
                        Training Data: {getLogMessage(job)}
                      </div>
                    </div>
                    <div className="flex items-baseline justify-between w-full mt-2">
                      <div className="text-sm text-zinc-500">
                        Created on{' '}
                        {new Date(job.created_at).toLocaleDateString('en-US', {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                        })}
                      </div>
                    </div>
                  </button>
                  {isMobile && selectedJob?.model_id === job.model_id && (
                    <div className="p-4 mt-2 border rounded bg-gray-50 border-zinc-300">
                      {failedStates.includes(selectedJob.state) && (
                        <CompletedJob
                          job={selectedJob}
                          status={'failed'}
                          setFetchNow={setFetchNow}
                        />
                      )}
                      {trainingStates.includes(selectedJob.state) && (
                        <CompletedJob
                          job={selectedJob}
                          status={'running'}
                          setFetchNow={setFetchNow}
                        />
                      )}
                    </div>
                  )}
                </div>
              ))}
            </>
          )}
        </div>
        {!isMobile && (
          <div className="w-full p-4 overflow-y-scroll md:w-2/3">
            <div className="h-full text-zinc-700">
              {!selectedJob && (
                <div className="flex flex-col items-center justify-around h-full text-zinc-700">
                  <div>Select a model to view training details</div>
                </div>
              )}
              {selectedJob && failedStates.includes(selectedJob.state) && (
                <CompletedJob
                  job={selectedJob}
                  status={'failed'}
                  setFetchNow={setFetchNow}
                />
              )}
              {selectedJob && trainingStates.includes(selectedJob.state) && (
                <CompletedJob
                  job={selectedJob}
                  status={'running'}
                  setFetchNow={setFetchNow}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TailorTrainingMetrics;
