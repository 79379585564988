const TailorError = () => {
  return (
    <div>
      <section className="sm:min-h-[calc(100vh-88px-118px)] min-h-[calc(100vh-88px-130px)] lg:min-h-[calc(100vh-118px)] bg-white dark:bg-zinc-900 font-dmSans pt-20">
        <div className="max-w-screen-xl px-4 py-8 mx-auto lg:py-16 lg:px-6">
          <div className="max-w-screen-sm mx-auto text-center">
            <h1 className="mb-4 font-extrabold tracking-tight text-indigo-600 text-7xl lg:text-9xl dark:text-indigo-500">
              404
            </h1>
            <p className="mb-4 text-3xl font-bold tracking-tight text-zinc-900 md:text-4xl dark:text-white">
              Something's missing.
            </p>
            <p className="mb-4 text-lg font-light text-zinc-500 dark:text-zinc-400">
              Sorry, we can't find that page.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TailorError;
