import { useCallback, useEffect, useState, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { TrashIcon, XMarkIcon } from '@heroicons/react/24/outline';
import toast from 'react-hot-toast';
import clsx from 'clsx';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';

import TokenUsageChart from './Deploy/ModelUsage';
import { useUser } from '../../UserContext';
import Spinner from '../Spinner';
import DeleteModelWaringModal from './Deploy/DeleteModelWaringModal';
import UndeployWarningModal from './Deploy/UndeployWarningModal';
import DeployInstructions from './Deploy/DeployInstructions';
import SelfHostedOptionsModal from './Deploy/SelfHostedOptionsModal';
import ModelList from './Deploy/ModelList';
import ThreeDotsOptions from './Deploy/ThreeDotsOptions';
import DeleteModelDropdown from './Deploy/DeleteModelDropdown';
import BaseModelList from './Deploy/BaseModelList';

const TailorDeploy = () => {
  const { id } = useParams();
  const [deployList, setDeployList] = useState([]);
  const [selectedModel, setSelectedModel] = useState(null);
  const [loading, setLoading] = useState(true);
  const [containerHeight, setContainerHeight] = useState('h-0');
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [showUndeployWarning, setShowUndeployWarning] = useState(false);
  const [showDeployInstructions, setShowDeployInstructions] = useState(false);
  const [originalDeployList, setOriginalDeployList] = useState([]);
  const [showSelfHostedOptionsModal, setShowSelfHostedOptionsModal] =
    useState(false);
  const [selfHostedServers, setSelfHostedServers] = useState([]);
  const [serverToUseForDeployment, setServerToUseForDeployment] = useState();
  const { customAxios, user } = useUser();
  const [modelNotFound, setModelNotFound] = useState(false);
  const [modelToDeploy, setModelToDeploy] = useState(null);
  const [error, setError] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(
    user.location_preference,
  );
  const [baseModels, setBaseModels] = useState([]);

  const fetchDeployList = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await customAxios.get('tailor/v1/models');
      let models = response?.data?.message;
      models = models.filter(
        (model) =>
          (model.state === 'dormant' ||
            model.state === 'deployed' ||
            model.state === 'failed_deploy' ||
            model.state === 'undeploying' ||
            model.state === 'deploying' ||
            model.state === 'failed_undeploy') &&
          model.base_model_data.available_for_inference,
      );
      setOriginalDeployList(models);
      setDeployList(originalSort(models));
      if (id) {
        const foundModel = models.find((model) => model.model_id === id);
        if (foundModel) {
          setSelectedModel(foundModel);
        }
      }
    } catch (error) {
      if (import.meta.env.DEV) {
        console.error(error);
      }
      if (error.code === 'ERR_NETWORK') {
        toast.error('Network error. Please try again later.');
      } else {
        setError(
          'There was an error retrieving the models. Please try again later.',
        );
      }
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, customAxios]);

  useEffect(() => {
    fetchDeployList();
    getSelfHostedServers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchDeployList]);

  useEffect(() => {
    if (!showDeleteWarning) {
      setSelectedModel(null);
      fetchDeployList();
    }
  }, [showDeleteWarning, fetchDeployList]);

  useEffect(() => {
    if (!showUndeployWarning || showDeployInstructions) {
      fetchDeployList();
    }
  }, [showUndeployWarning, showDeployInstructions, fetchDeployList]);

  const fetchBaseModels = async () => {
    try {
      const response = await customAxios.get(
        'tailor/v1/base_models?finetuning=true',
      );
      let models = response?.data?.models;

      // Filter models by supported locations matching user location preference
      // and only include those available for inference
      // Filter models by available for inference and location preference
      models = models.filter(
        (model) =>
          model.available_for_inference &&
          (user.location_preference === 'default' ||
            model.supported_locations.includes(user.location_preference)),
      );

      // Apply additional logic to set log requirements
      models.forEach((model) => {
        if (model.model_name.includes('mistral')) {
          model.min_logs_required = 1_000;
          model.good_number_of_logs_required = 2_000;
          model.excellent_number_of_logs_required = 5_000;
        } else if (model.model_name.includes('mixtral')) {
          model.min_logs_required = 2_000;
          model.good_number_of_logs_required = 4_000;
          model.excellent_number_of_logs_required = 8_000;
        } else if (model.model_name.includes('llama')) {
          model.min_logs_required = 8_000;
          model.good_number_of_logs_required = 12_000;
          model.excellent_number_of_logs_required = 18_000;
        }
      });

      setBaseModels(models);
    } catch (error) {
      if (import.meta.env.DEV) {
        console.error(error);
      }
      // toast.error('An error occurred fetching base models. Please try again.');
    }
  };

  useEffect(() => {
    fetchBaseModels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const originalSort = useCallback((models) => {
    return models.sort((a, b) => {
      const stateOrder = [
        'deployed',
        'deploying',
        'dormant',
        'training',
        'failed',
        'failed_deploy',
        'failed_undeploy',
        'start_training',
        'failed_training',
      ];
      const stateIndexA = stateOrder.indexOf(a.state);
      const stateIndexB = stateOrder.indexOf(b.state);
      if (stateIndexA !== stateIndexB) {
        return stateIndexA - stateIndexB;
      }
      return (
        new Date(b.created_at_unix).getTime() -
        new Date(a.created_at_unix).getTime()
      );
    });
  }, []);

  const handleSortModels = useCallback(
    (models, selectedModel = null) => {
      if (!selectedModel) {
        return models;
      }
      const sortedModels = models.filter(
        (model) => model.model_id !== selectedModel.model_id,
      );
      return [selectedModel, ...originalSort(sortedModels)];
    },
    [originalSort],
  );

  useEffect(() => {
    if (selectedModel) {
      setDeployList(handleSortModels([...originalDeployList], selectedModel));
    } else {
      setDeployList(originalSort([...originalDeployList]));
    }

    const scrollBase = document.getElementById('scroll-base');
    if (scrollBase) {
      scrollBase.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }

    if (selectedModel) {
      if (
        selectedModel.state !== 'deployed' &&
        !selectedModel.last_deployed_on
      ) {
        setContainerHeight('h-72');
      } else {
        setContainerHeight('md:h-[33rem] h-[39rem]');
      }
    } else {
      setContainerHeight('h-0');
    }
  }, [
    selectedModel,
    originalDeployList,
    modelNotFound,
    handleSortModels,
    originalSort,
  ]);

  const handleSetSelectedModel = useCallback((model) => {
    setSelectedModel((prevModel) =>
      prevModel?.model_id === model?.model_id ? null : model,
    );
  }, []);

  const getSelfHostedServers = useCallback(async () => {
    if (!user?.confirmed) {
      toast.error('Please confirm your email address to access this feature.');
      return [];
    }
    try {
      const response = await customAxios.get('tailor/v1/hosted_servers');
      const availableServers = response.data.message.filter(
        (server) => server.url,
      );
      setSelfHostedServers(availableServers);
      return availableServers;
    } catch (error) {
      if (import.meta.env.DEV) {
        console.error(error);
      }
      toast.error('Error fetching servers, try again later.');
    }
    return [];
  }, [customAxios, user.confirmed]);

  const toggleDeployStatus = useCallback(
    async (e, model) => {
      setModelToDeploy(model);
      e.stopPropagation();
      if (model.state === 'deployed' || model.state === 'failed_undeploy') {
        setShowUndeployWarning(true);
      } else if (model.state === 'dormant' || model.state === 'failed_deploy') {
        const servers = await getSelfHostedServers();
        if (servers.length > 0) {
          setShowSelfHostedOptionsModal(true);
        } else {
          deployModel(model);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getSelfHostedServers],
  );

  const deployModel = useCallback(
    async (model) => {
      const payload = {
        model_name: model.model_name,
      };
      if (serverToUseForDeployment && serverToUseForDeployment.id !== 0) {
        payload.self_hosted_id = serverToUseForDeployment.id;
      }
      try {
        const response = await customAxios.post(
          'tailor/v1/deploy_model',
          payload,
        );
        if (response.status === 200) {
          toast.success('Model deployment initiated.');
          setShowDeployInstructions(true);
          setModelToDeploy(null);
          setServerToUseForDeployment(null);
          setShowSelfHostedOptionsModal(false);
        }
      } catch (error) {
        if (import.meta.env.DEV) {
          console.error(error);
        }
        switch (error.code) {
          case 'api_key.not_found':
            toast.error('Failed to deploy: API key not found.');
            break;
          case 'base_model.inference.unsupported':
            toast.error(
              'Failed to deploy: Inference not supported for this Base Model.',
            );
            break;
          case 'model.sate_invalid':
            toast.error('Failed to deploy: Model is not in a valid state.');
            break;
          case 'model.files.not_found':
            toast.error('Failed to deploy: Internal issue with model files.');
            break;
          case 'server.not_found':
            toast.error('Failed to deploy: self-hosted server not found.');
            break;
          case 'servers.not_available':
            toast.error(
              'Failed to deploy: No servers available, try again later.',
            );
            break;
          default:
            toast.error('Failed to deploy model, try again later.');
            break;
        }
      }
    },
    [customAxios, serverToUseForDeployment],
  );

  const handleCloseDeployModal = useCallback(() => {
    setShowDeployInstructions(false);
    setShowUndeployWarning(false);
    setModelToDeploy(null);
  }, []);
  const modelStateMessage = {
    start_training: "This model can't be deployed yet",
    failed_training: 'The training of this model failed',
    deploying: 'This model is being deployed',
    deployed: 'This model is deployed',
    undeploying: 'This model is being un-deployed',
    training: 'This model is being trained',
    failed: 'Training of this model failed',
    dormant: 'This model is ready to be deployed',
    failed_deploy: 'The deployment of this model failed, try again later',
    failed_undeploy: 'This model could not be un-deployed at the moment',
  };

  const locationFilter = [
    { name: 'default', href: '#' },
    { name: 'uk', href: '#' },
    { name: 'europe', href: '#' },
  ];

  const filteredDeployList = deployList.filter((model) => {
    if (user.location_preference === 'default') {
      return true;
    }
    return model.location === user.location_preference;
  });

  //  if (filteredDeployList.length === 0) {
  //    return null;
  //  }

  if (error) {
    return (
      <div className="flex flex-col max-h-screen min-h-screen overflow-y-hidden bg-zinc-50 font-dmSans">
        <header className="pt-10 lg:pt-0">
          <div className="flex items-baseline justify-between h-16 p-4 text-xl font-medium text-zinc-800">
            <div>Deploy</div>
          </div>
          <hr className="border-t border-zinc-300" />
        </header>
        <div className="flex items-center justify-center h-full px-12 text-lg text-center grow text-zinc-800 w-fit">
          {error}
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="flex flex-col max-h-screen min-h-screen overflow-y-hidden bg-zinc-50 font-dmSans">
        <header className="pt-10 lg:pt-0">
          <div className="flex items-baseline justify-between h-16 p-4 text-xl font-medium text-zinc-800">
            <div>Deploy</div>
          </div>
          <hr className="border-t border-zinc-300" />
        </header>
        <div className="flex items-center justify-center w-full h-full px-12 text-lg text-center grow text-zinc-800">
          <Spinner size={'36px'} borderTopColor={'gray'} />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="max-h-screen min-h-screen overflow-y-hidden bg-zinc-50 font-dmSans">
        <header className="pt-10 lg:pt-0">
          <div className="flex items-baseline justify-between h-16 p-4 text-xl font-medium text-zinc-800">
            <div>Deploy</div>
          </div>
          <hr className="border-t border-zinc-300" />
        </header>
        <div
          className="flex flex-col w-full md:px-4 md:h-[calc(100vh-68px)] h-screen overflow-y-scroll  font-dmSans pb-32 "
          id="scroll-base"
        >
          {filteredDeployList.length > 0 && (
            <div className="p-4 text-xs text-zinc-600">
              Select a model to see more information
            </div>
          )}

          {deployList?.length === 0 && !loading && (
            <div className="flex items-center justify-center h-64">
              <div className="flex items-center justify-around w-full text-sm text-center lg:text-lg grow text-zinc-800">
                No models have been fine-tuned yet
              </div>
            </div>
          )}

          <div
            className={clsx(
              'transition-height duration-500 ease-in-out shrink-0 relative',
              containerHeight,
            )}
          >
            {selectedModel &&
              !loading &&
              (selectedModel.state === 'deployed' ||
              selectedModel.last_deployed_on ? (
                <>
                  <TokenUsageChart
                    model={selectedModel}
                    setModelNotFound={setModelNotFound}
                    closeModel={() => handleSetSelectedModel(null)}
                  />
                  {selectedModel.state !== 'deployed' && (
                    <>
                      <div className="flex justify-around items-center absolute top-1 right-4 md:top-[41px] md:right-2">
                        <ThreeDotsOptions
                          handleDelete={() => setShowDeleteWarning(true)}
                        />
                        <DeleteModelDropdown
                          handleDelete={() => setShowDeleteWarning(true)}
                        />
                      </div>
                    </>
                  )}
                </>
              ) : (
                <div
                  className={clsx(
                    'flex flex-col items-center justify-around h-64 text-zinc-600 relative px-4 ',
                    containerHeight === 'h-72' ? 'opacity-100' : 'opacity-0',
                    'transition-opacity delay-300 ease-in-out',
                  )}
                >
                  <span className="w-full h-6 text-lg text-center">
                    {selectedModel.state === 'dormant' &&
                      'Model is not deployed'}
                  </span>
                  <div className="w-full text-center ">
                    <div className="text-lg font-medium text-zinc-800">
                      {selectedModel.model_name}
                    </div>
                    <div className="font-medium capitalize text-zinc-500">
                      {selectedModel?.state.split('_').join(' ')}:{' '}
                      {modelStateMessage[selectedModel?.state]}
                    </div>

                    {(selectedModel.state !== 'training' ||
                      selectedModel.state !== 'start_training') && (
                      <div className="flex justify-around mt-10">
                        <button
                          className="flex items-center gap-1 px-1 border-b text-zinc-900 border-zinc-300"
                          onClick={() => setShowDeleteWarning(true)}
                        >
                          <TrashIcon className="w-4 h-4" /> Delete Model
                        </button>
                      </div>
                    )}
                  </div>

                  <button
                    className="absolute p-1 rounded-full -top-9 right-2 bg-zinc-100 text-zinc-800 md:hidden"
                    onClick={() => handleSetSelectedModel(null)}
                  >
                    <XMarkIcon className="w-5 h-5" />
                  </button>
                </div>
              ))}
          </div>
          {deployList.length > 0 && (
            <>
              {/* desktop */}
              <div className="hidden h-full pb-12 md:block font-dmSans">
                <div className="flex items-center justify-end w-full h-12 mt-4 mb-8 border-b">
                  <div className="h-10 ">
                    {' '}
                    <div className="flex flex-col">
                      <div>
                        Showing results for{' '}
                        <span className="capitalize text-zinc-600">
                          {user.location_preference === 'uk'
                            ? 'UK'
                            : user.location_preference === 'default'
                              ? 'everywhere'
                              : user.location_preference}
                        </span>
                      </div>
                      <div className="text-xs">
                        Change your location preference in{' '}
                        <Link
                          to="/tailor/settings#location"
                          className="text-indigo-600"
                        >
                          Settings
                        </Link>
                      </div>
                    </div>
                    {/* <div className="inline-flex rounded-md ">
                      <div className="relative inline-flex items-center px-3 py-2 text-sm font-medium capitalize bg-white text-zinc-800 rounded-l-md ring-1 ring-inset ring-gray-300">
                        {selectedLocation === 'uk' ? 'UK' : selectedLocation}
                      </div>

                      <Menu as="div" className="relative block -ml-px">
                        <Menu.Button className="relative inline-flex items-center px-2 py-2 text-gray-400 bg-white rounded-r-md ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10">
                          <span className="sr-only">Open options</span>
                          <ChevronDownIcon
                            aria-hidden="true"
                            className="w-5 h-5"
                          />
                        </Menu.Button>
                        <Menu.Items
                          as={Fragment}
                          className="absolute right-0 z-10 w-56 mt-2 -mr-1 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                        >
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <div className="py-1">
                              {locationFilter.map((location) => (
                                <Menu.Item key={location.name}>
                                  <a
                                    href="#"
                                    onClick={() =>
                                      setSelectedLocation(location.name)
                                    }
                                    className="block px-4 py-2 text-sm text-gray-700 capitalize hover:bg-gray-100 hover:text-gray-900"
                                  >
                                    {location.name === 'uk'
                                      ? 'UK'
                                      : location.name}
                                  </a>
                                </Menu.Item>
                              ))}
                            </div>
                          </Transition>
                        </Menu.Items>
                      </Menu>
                    </div> */}
                  </div>
                </div>
                <div className="grid grid-cols-6 px-4 pb-1 mx-1">
                  <div className="text-sm font-medium xl:text-base text-zinc-800 md:col-span-2">
                    Model
                  </div>
                  <div className="text-sm font-medium xl:text-base text-zinc-800 justify-self-center">
                    Created On
                  </div>

                  <div className="text-sm font-medium xl:text-base text-zinc-800 justify-self-center">
                    Deployed On
                  </div>
                  <div className="text-sm font-medium xl:text-base text-zinc-800 justify-self-center">
                    Last used
                  </div>
                  <div className="text-sm font-medium xl:text-base text-zinc-800 justify-self-end">
                    Deploy
                  </div>
                </div>
                {filteredDeployList?.length === 0 && !loading && (
                  <div className="flex items-center justify-center h-64">
                    <div className="flex items-center justify-around w-full text-sm text-center lg:text-lg grow text-zinc-800">
                      No models have been fine-tuned yet
                    </div>
                  </div>
                )}
                {filteredDeployList.length > 0 && (
                  <div
                    className={clsx(
                      'flex flex-wrap w-full gap-2 grow content-start min-h-[35vh] pb-12',
                    )}
                  >
                    <ModelList
                      deployList={deployList}
                      handleSetSelectedModel={handleSetSelectedModel}
                      toggleDeployStatus={toggleDeployStatus}
                      selectedModel={selectedModel}
                      selfHostedServers={selfHostedServers}
                    />
                  </div>
                )}
                <div>Base Models</div>
                <BaseModelList
                  deployList={baseModels}
                  // handleSetSelectedModel={handleSetSelectedModel}
                  // toggleDeployStatus={toggleDeployStatus}
                  // selectedModel={selectedModel}
                  // selfHostedServers={selfHostedServers}
                />
              </div>

              {/* mobile */}
              <div className="h-full pb-12 md:hidden">
                <div
                  className={clsx(
                    'flex flex-wrap w-full gap-2 content-start pb-72',
                  )}
                >
                  <ModelList
                    deployList={deployList}
                    handleSetSelectedModel={handleSetSelectedModel}
                    toggleDeployStatus={toggleDeployStatus}
                    selectedModel={selectedModel}
                    selfHostedServers={selfHostedServers}
                  />
                  <div>Base Models</div>
                  <BaseModelList
                    deployList={baseModels}
                    // handleSetSelectedModel={handleSetSelectedModel}
                    // toggleDeployStatus={toggleDeployStatus}
                    // selectedModel={selectedModel}
                    // selfHostedServers={selfHostedServers}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <DeleteModelWaringModal
        showModal={showDeleteWarning}
        onClose={() => setShowDeleteWarning(false)}
        model={selectedModel}
      />
      <UndeployWarningModal
        showModal={showUndeployWarning}
        onClose={handleCloseDeployModal}
        model={modelToDeploy}
      />
      <DeployInstructions
        showModal={showDeployInstructions}
        onClose={handleCloseDeployModal}
        model={modelToDeploy}
      />
      <SelfHostedOptionsModal
        showModal={showSelfHostedOptionsModal}
        closeModal={() => setShowSelfHostedOptionsModal(false)}
        model={modelToDeploy}
        deployModel={deployModel}
        setServerToUseForDeployment={setServerToUseForDeployment}
        serverToUseForDeployment={serverToUseForDeployment}
        selfHostedServers={selfHostedServers}
      />
    </>
  );
};

export default TailorDeploy;
