/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState, useEffect } from 'react';
import moment from 'moment';
import { motion } from 'framer-motion';
// import DashboardAreaChart from '../Components/DashboardAreaChart';
import { Link } from 'react-router-dom';
import { toast } from 'react-hot-toast';

import EmailModal from '../Components/Modals/EmailModal';
// import DashboardAreaChartDark from '../Components/DashboardAreaChartDark';
import { useCurrencyConverter } from '../Hooks/useCurrencyConverter';
import { useUser } from '../UserContext';
import { calculateDuration } from '../utils/InstanceUtils';
import LiveInstancesList from '../Components/UserDashboard/LiveInstancesList';
import TopupModal from '../Components/Modals/TopupModal';
import { amountToFrontend } from '../utils/CurrencyUtils';
// import EmergencyBanner from '../Components/UserDashboard/EmergencyBanner';

const UserDashboard = () => {
  const { user, customAxios, refresh, setRefresh } = useUser();
  const money = useCurrencyConverter();
  const [updatedData, setUpdatedData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    credits: '',
  });
  const [currentTime, setCurrentTime] = useState(moment().format('lll'));
  const [chartData, setChartData] = useState({ series: [], categories: [] });
  const [averageCostPerHour, setAverageCostPerHour] = useState(0);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [open, setOpen] = useState(false);
  const userEmail = localStorage.getItem('email') || '';
  const [emailModalMessage, setEmailModalMessage] = useState(
    "If you're running into technical difficulties while trying to rent an instance - reach out and we'll find a solution.",
  );
  const [liveInstances, setLiveInstances] = useState([]);
  const [historicalInstances, setHistoricalInstances] = useState([]);
  const [instances, setInstances] = useState([]);
  const [shouldFetch, setShouldFetch] = useState(true);
  const [deployList, setDeployList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [originalDeployList, setOriginalDeployList] = useState([]);
  const [selfHostedServers, setSelfHostedServers] = useState([]);

  const handleToggleEmailModal = (message = '') => {
    setEmailModalMessage(message);
    setShowEmailModal((prevState) => !prevState);
  };

  // Animation variants for rest of the content
  const headerVariants = {
    initial: { opacity: 0 },
    animate: { opacity: 1, transition: { duration: 3, delay: 0 } },
  };

  const combinedVariants = {
    initial: {
      // Properties from containerVariants' initial state
      backgroundColor: 'rgba(255, 255, 255, 0)',
      boxShadow: 'none',
      // Properties from dashboardVariants' hidden state
      y: 20,
      opacity: 0,
    },
    animate: {
      // Properties from containerVariants' animate state
      backgroundColor: 'rgba(250, 250, 250, 1)',
      // boxShadow:
      //   '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1)',
      // Properties from dashboardVariants' visible state
      y: 0,
      opacity: 1,
      transition: {
        // Merge the transition properties. Adjust as needed.
        duration: 1,
        delay: 0.5,
      },
    },
  };

  useEffect(() => {
    const timerId = setInterval(() => {
      setCurrentTime(moment().format('lll'));
    }, 30000);

    return () => clearInterval(timerId);
  }, []);

  useEffect(() => {
    if (user) {
      setUpdatedData({
        firstName: user.first_name || '',
        lastName: user.last_name || '',
        email: user.email || '',
        credits: user.new_credits_available || '',
      });
    }
  }, [user]);

  useEffect(() => {
    const fetchInstances = async () => {
      try {
        const response = await customAxios.get('pods');

        const fetchedInstances = response.data.pods;
        setInstances(fetchedInstances);

        const { active, historical } = fetchedInstances.reduce(
          (result, instance) => {
            if (
              instance.state !== 'ended' &&
              instance.state !== 'ending' &&
              instance.state !== 'failed' &&
              instance.state !== 'failed-starting' &&
              instance.state !== 'failed-ending'
            ) {
              result.active.push(instance);
            } else {
              result.historical.push(instance);
            }
            return result;
          },
          { active: [], historical: [] },
        );

        historical.sort((a, b) => {
          const endA = a.updated_at;
          const endB = b.updated_at;
          return new Date(endB) - new Date(endA);
        });

        setLiveInstances(active);
        setHistoricalInstances(historical);
      } catch (error) {
        console.error('Could not fetch instance:', error);
        toast.error('Error fetching instances. Please try again');
      }
    };

    if (shouldFetch) {
      fetchInstances();
      setShouldFetch(false);
    }
  }, [shouldFetch, customAxios]);

  useEffect(() => {
    if (refresh) {
      setShouldFetch(true);
      setRefresh(false);
    }
  }, [refresh]);

  const stats = [
    // {
    //   name: 'Delinquent',
    //   value: `${dashboardData.delinquent}`,
    //   change: '',
    // },
    // {
    //   name: dashboardData.delinquent ? 'Overdue Charges' : 'Current Charges',
    //   value:
    //     typeof dashboardData.amountOwed === 'number' &&
    //     dashboardData.amountOwed > 0
    //       ? money(dashboardData.amountOwed)
    //       : money(0.0),
    //   change: '',
    //   // currency: '£',
    // },
    {
      name: 'Balance',
      value:
        typeof updatedData.credits === 'number'
          ? money(updatedData.credits)
          : money(0.0),
      change: '',
    },
    {
      name: 'Average Cost',
      value: money(averageCostPerHour, 'hr'),
      change: '',
    },
  ];

  const processDataForChart = (data) => {
    const costByDate = data.reduce((acc, instance) => {
      const dateKey = moment(instance.created_at).format('YYYY-MM-DD');
      acc[dateKey] = (acc[dateKey] || 0) + instance.current_cost;
      return acc;
    }, {});

    // Ensure today's date is included in the dataset
    const today = moment().format('YYYY-MM-DD');
    costByDate[today] = costByDate[today] || 0;

    // Sort dates and prepare series data with toFixed(2)
    const sortedDates = Object.keys(costByDate).sort(
      (a, b) => new Date(a) - new Date(b),
    );
    const seriesData = sortedDates.map((date) => costByDate[date].toFixed(2));

    return {
      series: [{ name: 'Cost', data: seriesData, color: 'gray' }],
      categories: sortedDates,
    };
  };

  useEffect(() => {
    const processedData = processDataForChart(instances);
    setChartData(processedData);
  }, [instances]);

  const calculateAverageCostPerHour = () => {
    let totalCost = 0;
    let totalDurationInHours = 0;

    historicalInstances.forEach((instance) => {
      // Parsing the dates
      const createdAt = new Date(instance.created_at);
      const endedAt = new Date(instance.ended_at);

      // Calculate the duration in hours
      const durationInMilliseconds = endedAt - createdAt;
      const durationInHours = durationInMilliseconds / 1000 / 3600; // Convert from milliseconds to hours

      // console.log('Instance ID:', instance.id);
      // console.log('Duration in Hours:', durationInHours);
      // console.log('Current Cost:', instance.current_cost);

      totalCost += instance.total_cost / 100;
      totalDurationInHours += durationInHours;
    });

    // console.log('Total Cost:', totalCost);
    // console.log('Total Duration in Hours:', totalDurationInHours);

    const averageCost =
      totalDurationInHours > 0 ? totalCost / totalDurationInHours : 0;
    // console.log('Calculated Average Cost:', averageCost);

    return averageCost;
  };

  useEffect(() => {
    const averageCost = calculateAverageCostPerHour();
    setAverageCostPerHour(averageCost);
  }, [historicalInstances]);

  const fetchDeployList = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await customAxios.get('tailor/v1/models');
      let models = response?.data?.message;
      models = models.filter(
        (model) =>
          (model.state === 'dormant' ||
            model.state === 'deployed' ||
            model.state === 'failed_deploy' ||
            model.state === 'undeploying' ||
            model.state === 'deploying' ||
            model.state === 'failed_undeploy') &&
          model.base_model_data.available_for_inference,
      );
      setOriginalDeployList(models);
      setDeployList(originalSort(models));
    } catch (error) {
      if (import.meta.env.DEV) {
        console.error(error);
      }
      handleErrors(error);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customAxios]);

  useEffect(() => {
    fetchDeployList();
    getSelfHostedServers();
  }, [fetchDeployList]);

  const handleErrors = (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        // history.push('/login');
      } else if (error.response.status === 403) {
        // history.push('/login');
      } else if (error.response.status === 404) {
        // history.push('/404');
      } else if (error.response.status === 500) {
        setError('There was an error on the server. Please try again later.');
      }
    } else {
      setError('There was an error. Please try again later.');
    }
  };

  const originalSort = useCallback((models) => {
    return models.sort((a, b) => {
      const stateOrder = [
        'deployed',
        'deploying',
        'dormant',
        'training',
        'failed',
        'failed_deploy',
        'failed_undeploy',
        'start_training',
        'failed_training',
      ];
      const stateIndexA = stateOrder.indexOf(a.state);
      const stateIndexB = stateOrder.indexOf(b.state);
      if (stateIndexA !== stateIndexB) {
        return stateIndexA - stateIndexB;
      }
      return (
        new Date(b.created_at_unix).getTime() -
        new Date(a.created_at_unix).getTime()
      );
    });
  }, []);

  const getSelfHostedServers = useCallback(async () => {
    if (!user?.confirmed) {
      toast.error('Please confirm your email address to access this feature.');
      return [];
    }
    try {
      const response = await customAxios.get('tailor/v1/hosted_servers');
      const availableServers = response.data.message.filter(
        (server) => server.url,
      );
      setSelfHostedServers(availableServers);
      return availableServers;
    } catch (error) {
      if (import.meta.env.DEV) {
        console.error(error);
      }
      toast.error('Error fetching servers, try again later.');
    }
    return [];
  }, [customAxios, user.confirmed]);

  return (
    <div className="sm:min-h-[calc(100vh-88px-118px)] min-h-[calc(100vh-88px-130px)] lg:min-h-[calc(100vh-96px-118px)] bg-zinc-50">
      {/* <EmergencyBanner /> */}
      {/* top banner */}
      <motion.div
        variants={headerVariants}
        initial="initial"
        animate="animate"
        className="w-full h-96 wavyextraslow shadow-3xinner dark:bg-gray-800 TOPBANNER"
      >
        <div className="absolute top-0 bottom-0 left-0 right-0 w-full overlay dark:bg-gray-800 h-96"></div>
        <div className="absolute top-0 bottom-0 left-0 right-0  overlay bg-[radial-gradient(ellipse_at_bottom,_var(--tw-gradient-stops))] from-transparent via-zinc-50/50 to-zinc-50 "></div>
        <div className="absolute top-0 bottom-0 left-0 right-0 overlay md:bg-gradient-to-t from-zinc-50 via-transparent to-zinc-50 "></div>
      </motion.div>

      {/* whole dashboard  */}
      <motion.div
        variants={combinedVariants}
        initial="initial"
        animate="animate"
        className={`container relative z-0 p-4 mx-auto rounded-lg border  -mt-96 sm:-mt-[22rem] lg:bg-zinc-50/50 font-dmSans backdrop-blur-xl `}
      >
        <div className="grid grid-cols-4 gap-4 ">
          {/* Window 1 */}
          <div className="container relative col-span-4 p-2 bg-transparent min-h-40 rounded-xl">
            <div className="grid grid-cols-1 gap-y-4 lg:gap-4 xl:grid-cols-3 md:grid-rows-2">
              {/* Welcome Box */}
              <div className="col-span-3 row-span-2 lg:col-span-1 welcomebox rounded-xl ">
                <div className="h-full bg-white border rounded-lg dark:bg-gray-900">
                  <div className="p-2 px-4 pb-4 xl:p-6 dark:text-gray-200">
                    {/* Left side content */}
                    <div>
                      <div className="text-xl font-semibold text-gray-800 dark:text-gray-200">
                        Welcome, {updatedData.firstName}
                      </div>
                      {/* <div className="text-2xl font-bold tracking-wide text-gray-700 sm:text-3xl dark:text-gray-200">
                        {updatedData.firstName}
                      </div> */}
                      <div className="text-gray-500 dark:text-gray-200 whitespace-nowrap">
                        {currentTime}
                      </div>
                      <div className="mt-10 lg:mt-16">
                        <div className="text-gray-500 dark:text-gray-200">
                          Balance
                        </div>
                        <div className="flex items-center justify-between h-12 mt-2 text-2xl font-bold tracking-wide text-gray-700 sm:text-2xl dark:text-gray-200">
                          <div>{money(updatedData.credits)}</div>

                          <div>
                            {user.team_role === 'admin' && (
                              <button
                                onClick={() => setOpen(true)}
                                className="h-10 px-2 mb-1 text-sm font-medium text-gray-100 bg-gray-900 rounded-md sm:px-4 hover:bg-gray-800 whitespace-nowrap"
                              >
                                + Manage balance
                              </button>
                            )}
                          </div>
                        </div>
                        <div className="items-center w-full mt-4 text-sm text-gray-500 rounded-md xl:mt-8">
                          {user.new_credits_top_up_threshold > 0 &&
                          user.new_credits_top_up_amount > 0 ? (
                            <>
                              <div>
                                Your balance will Auto-top-up with $
                                {amountToFrontend(
                                  user.new_credits_top_up_amount,
                                ).toFixed(2)}
                              </div>
                              <div>
                                when it falls below $
                                {amountToFrontend(
                                  user.new_credits_top_up_threshold,
                                ).toFixed(2)}
                                . VAT will also be added to this Auto-top-up.
                              </div>
                            </>
                          ) : (
                            <div className="inline-flex items-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 16 16"
                                fill="currentColor"
                                className="w-4 h-4 mr-2 text-yellow-300"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M6.701 2.25c.577-1 2.02-1 2.598 0l5.196 9a1.5 1.5 0 0 1-1.299 2.25H2.804a1.5 1.5 0 0 1-1.3-2.25l5.197-9ZM8 4a.75.75 0 0 1 .75.75v3a.75.75 0 1 1-1.5 0v-3A.75.75 0 0 1 8 4Zm0 8a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
                                  clipRule="evenodd"
                                />
                              </svg>
                              Auto-top-up is disabled.
                            </div>
                          )}
                        </div>

                        {/* <div className="mt-8 text-gray-500 dark:text-gray-200">
                          Average Cost
                        </div>
                        <div className="w-full h-12 mt-4 border rounded "></div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Deployed Models Box */}
              <div className="col-span-1 lg:col-span-2 lg:row-span-2 min-h-96 max-h-72 BIG">
                <div className="h-full px-2 overflow-y-auto bg-white border rounded-lg xl:px-6 dark:bg-gray-900">
                  <div className="sticky top-0 z-10 bg-white">
                    <div className="pt-6 mt-0 mb-2 ml-1 text-xl font-semibold text-gray-800 dark:text-gray-200">
                      Deployed Models
                    </div>
                  </div>
                  <div className="w-full h-auto px-2 py-4">
                    <div className="grid grid-cols-1 gap-4 md+:grid-cols-2 ">
                      {deployList.filter((model) => model.state === 'deployed')
                        .length > 0 ? (
                        deployList
                          .filter((model) => model.state === 'deployed') // Filter models by deployed state
                          .map((model, index) => (
                            <Link
                              as="div"
                              to={`/tailor/deploy/${model.model_id}`}
                              key={model.model_id}
                              className="relative flex flex-col justify-between w-full h-40 p-4 text-gray-700 border rounded-lg md+:max-w-1/2 bg-zinc-50 hover:border-zinc-800/50  shadow-3xinner"
                            >
                              {/* Deployed Chip */}
                              <span className="absolute px-1 py-1 text-xs font-semibold bg-green-200 border rounded-full border-green-300/50 top-2 right-2 animate-pulse"></span>

                              {/* Model Name (Header) */}
                              <div className="mt-4 text-left">
                                {model.model_name || `Model ${model.model_id}`}
                              </div>

                              {/* Additional Information */}
                              <div className="text-sm text-left text-zinc-500">
                                <p>{model.base_model_data.display_name}</p>
                                <div className="flex items-center">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    className="mr-2 size-3 stroke-black"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                                    />
                                  </svg>
                                  Last updated: {model.updated_at || 'N/A'}
                                </div>
                              </div>
                            </Link>
                          ))
                      ) : (
                        <Link
                          to="/tailor/deploy"
                          type="button"
                          className="relative block w-full p-12 text-center border border-dashed rounded-md hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          <svg
                            className="w-6 h-6 mx-auto text-gray-400"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 18 18"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M9 1v16M1 9h16"
                            />
                          </svg>
                          <div className="block mt-2 text-sm font-semibold text-gray-900 dark:text-gray-200">
                            Deploy a model
                          </div>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* Data Display */}
              {/* <div className="p-2 pb-0 bg-white shadow-md md:col-span-3 md:row-span-2 rounded-xl datadisplay dark:bg-gray-900">
                <dl className="grid grid-cols-1 gap-px mx-auto my-2 bg-gray-900/5 xl:grid-cols-2 dark:bg-gray-900">
                  {stats.map((stat) => (
                    <div
                      key={stat.name}
                      className={`flex ${stat.name === 'Balance' ? 'flex-row items-center' : 'flex-col'} justify-between px-4 pb-4 bg-white sm:px-6 xl:px-8 dark:bg-gray-900`}
                    >
                      <div className="flex flex-col">
                        <dt
                          className={`text-base font-medium leading-6 text-gray-500  dark:text-gray-200 ${stat.name === 'Overdue Charges' ? 'text-red-700' : ''}`}
                        >
                          {stat.name}
                        </dt>
                        <dd className="mt-2 text-3xl font-bold text-gray-700 dark:text-gray-200">
                          {stat.value}
                        </dd>
                      </div>
                      {stat.name === 'Balance' && (
                        <div className='flex flex-row h-12 mt-6'>
                        {/* <button
                        // onClick={() => setOpen(true)}
                          className="px-4 py-2 mr-2 text-sm font-medium text-gray-900 border rounded-md shadow-sm hover:bg-gray-100">
                         Enabled
                        </button>
                        <button
                        onClick={() => setOpen(true)}
                          className="px-4 py-2 text-sm font-medium text-gray-100 bg-gray-900 rounded-md hover:bg-gray-800 whitespace-nowrap">
                          + Manage balance
                        </button></div>
                      )}
                    </div>
                  ))}
                </dl>
              </div> */}
            </div>
          </div>

          {/*Active Instances section*/}
          <div className="container relative col-span-4 p-2 bg-transparent rounded-xl ">
            <div className="bg-transparent md:col-span-2 md:row-span-2 min-h-56 BIG">
              <div className="p-2 bg-white border rounded-lg xl:p-6 dark:bg-gray-900">
                <div className="mt-0 mb-2 ml-1 text-xl font-semibold text-gray-800 dark:text-gray-200 ">
                  Active Instances
                </div>{' '}
                {liveInstances && liveInstances?.length > 0 ? (
                  <LiveInstancesList liveInstances={liveInstances} />
                ) : (
                  <Link
                    to="/tailor/marketplace"
                    type="button"
                    className="relative block w-full p-12 text-center border border-dashed rounded-md hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    <svg
                      className="w-6 h-6 mx-auto text-gray-400"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 18 18"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M9 1v16M1 9h16"
                      />
                    </svg>
                    <div className="block mt-2 text-sm font-semibold text-gray-900 dark:text-gray-200">
                      Rent an instance
                    </div>
                  </Link>
                )}
              </div>
            </div>
          </div>
          {/*Tables*/}
          <div className="container relative col-span-4 p-2 bg-transparent rounded-xl ">
            <div className="grid grid-cols-1 gap-4 md:grid-cols-4 md:grid-rows-2">
              {/* Div with "Usage Chart" */}
              {/* <div className="bg-transparent md:col-span-2 md:row-span-2 min-h-56 BIG">
                <div className="h-full p-2 bg-white shadow-md rounded-xl dark:bg-gray-900">
                  <div className="mt-0 mb-2 ml-1 text-xl font-semibold text-gray-800 dark:text-gray-200">
                    Usage
                  </div>{' '}
                  <div className="w-full dark:bg-gray-900 ">
                    {rentalHistoryInstances &&
                    rentalHistoryInstances.length > 0 ? (
                      <>
                        <div className="block dark:hidden">
                          <DashboardAreaChart
                            series={chartData.series}
                            categories={chartData.categories}
                          />{' '}
                        </div>
                        <div className="hidden dark:block">
                          <DashboardAreaChartDark
                            series={chartData.series}
                            categories={chartData.categories}
                          />
                        </div>{' '}
                      </>
                    ) : (
                      <div className="flex flex-col items-center justify-center p-4 h-[250px] w-full border-2 border-gray-300 border-dashed rounded-lg">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="36"
                          height="36"
                          className="fill-gray-400 bi bi-graph-up"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M0 0h1v15h15v1H0zm14.817 3.113a.5.5 0 0 1 .07.704l-4.5 5.5a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61 4.15-5.073a.5.5 0 0 1 .704-.07"
                          />
                        </svg>
                      </div>
                    )}
                  </div>
                </div>
              </div> */}

              {/* Div with table */}
              <div className="col-span-4 row-span-2 bg-transparent min-h-56 TABLE">
                <div className="h-full p-2 bg-white border rounded-lg xl:p-6 dark:bg-gray-900">
                  {/* Table container with scroll */}
                  <div className="mt-0 mb-2 ml-1 text-xl font-semibold text-gray-800 dark:text-gray-200">
                    Rental History
                  </div>
                  {historicalInstances && historicalInstances.length > 0 ? (
                    <div className="overflow-x-auto max-h-[240px]">
                      <table className="relative w-full text-sm text-left text-gray-500 font-dmSans dark:text-gray-400">
                        <thead className="sticky top-0 text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                          <tr>
                            <th scope="col" className="w-1/5 pl-2">
                              Instance ID
                            </th>
                            <th scope="col" className="w-1/5 py-3">
                              GPU Type
                            </th>
                            <th scope="col" className="w-1/5 py-3">
                              Rented on
                            </th>
                            <th scope="col" className="w-1/5 py-3">
                              Duration
                            </th>
                            <th scope="col" className="w-1/5 py-3 mr-4">
                              Cost
                            </th>
                            {/* <th scope='col' className='px-[0.rem] '></th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {historicalInstances &&
                            historicalInstances.map((instance, index) => (
                              <tr
                                key={instance.id}
                                className="border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700"
                              >
                                <td className="w-1/5 py-3 pl-2">
                                  {instance.id}
                                </td>
                                <td className="w-1/5 py-3">
                                  {instance.node?.normalized_gpu_type} x{' '}
                                  {instance.gpu_count}
                                </td>
                                <td className="w-1/5 py-3">
                                  {moment(instance.created_at).format(
                                    'YYYY-MM-DD',
                                  )}
                                </td>
                                <td className="w-1/5 py-3">
                                  {calculateDuration(
                                    instance.created_at,
                                    instance.ended_at,
                                    true,
                                  )}
                                </td>
                                <td className="w-1/5 py-3">
                                  {money(instance.total_cost)}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    // Render the alternative message and button if there are no active instances
                    <div className="flex flex-col items-center justify-center p-4 h-[250px] w-full border border-dashed rounded-lg">
                      <p className="block mt-2 mb-4 text-sm font-semibold text-gray-900 dark:text-gray-200">
                        Looks like you haven't rented an instance before.
                      </p>
                      <div className="flex space-x-4">
                        <Link
                          to="/tailor/marketplace"
                          className="flex text-gray-700 hover:text-white border border-gray-900 hover:bg-gray-900 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:border-gray-500 dark:text-gray-200 dark:hover:text-white dark:hover:bg-gray-600 whitespace-nowrap justify-center "
                        >
                          Visit Marketplace
                        </Link>

                        <Link
                          to="/contact"
                          className="text-gray-700 hover:text-white border border-gray-900 hover:bg-gray-900 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:border-gray-500 dark:text-gray-200 dark:hover:text-white dark:hover:bg-gray-600 whitespace-nowrap "
                        >
                          Get Help
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
      {/* <ActionModal
        // action={modalAction}
        showModal={showActionModal}
        onConfirm={() => endInstance(selectedInstanceId)}
        closeModal={() => setShowActionModal(false)}
      /> */}
      <EmailModal
        userEmail={userEmail}
        showModal={showEmailModal}
        onClose={handleToggleEmailModal}
        alternativeMessage={emailModalMessage}
      />
      <TopupModal open={open} setOpen={setOpen} />

      {/* {showKeyCopyModal && (
        <KeyCopyModal
          sshKey={sshKey}
          showKeyCopyModal={showKeyCopyModal}
          onClose={() => setShowKeyCopyModal(false)}
        />
      )} */}

      {/* {showJupyterModal && (
        <JupyterModal
          jupyter_port={
            liveInstances.find((i) => {
              return i.id === selectedInstanceId;
            }).ports.jupyter
          }
          jupyter_token={
            liveInstances.find((i) => {
              return i.id === selectedInstanceId;
            }).jupyter_token
          }
          ip_address={
            liveInstances.find((i) => {
              return i.id === selectedInstanceId;
            }).ip_address
          }
          showJupyterModal={showJupyterModal}
          onClose={() => setShowJupyterModal(false)}
        />
      )} */}
    </div>
  );
};

export default UserDashboard;
