import { useState, useEffect } from 'react';

import DatasetsComponent from './Logs&Datasets/DatasetsComponent';
import { useUser } from '../../UserContext';

import { useSearchParams } from 'react-router-dom';

import LogsComponent from './Logs&Datasets/LogsComponent';
import UploadLogsModal from './Logs&Datasets/UploadLogsModal';
import useUploadLogs from '../../Hooks/useUploadLogs';

const TailorLogsDatasets = () => {
  const { validationInProgress, percentComplete } = useUploadLogs();
  const [activeTab, setActiveTab] = useState('logs');
  const [loading, setLoading] = useState(true);
  const [retrievedLogs, setRetrievedLogs] = useState([]);
  const { customAxios } = useUser();
  const [searchParams, setSearchParams] = useSearchParams();
  const [totalPages, setTotalPages] = useState(0);
  const [totalLogs, setTotalLogs] = useState(0);
  const [uniqueModels, setUniqueModels] = useState([]);
  const [uniqueTags, setUniqueTags] = useState([]);
  const [showUploadLogsModal, setShowUploadLogsModal] = useState(false);

  const [filters, setFilters] = useState({
    models: [],
    tags: [],
    startDate: '',
    endDate: '',
  });

  useEffect(() => {
    const items_per_page = searchParams.get('items_per_page');
    const page_number = searchParams.get('page_number');

    if (!items_per_page || !page_number) {
      const updatedParams = new URLSearchParams(searchParams);
      if (!items_per_page) {
        updatedParams.set('items_per_page', '50');
      }
      if (!page_number) {
        updatedParams.set('page_number', '1');
      }
      setSearchParams(updatedParams);
    }
  }, [searchParams, setSearchParams, activeTab]);

  const itemsPerPage = parseInt(searchParams.get('items_per_page'));
  const page = parseInt(searchParams.get('page_number'));

  const fetchLogs = async (clearFilters = false, controller) => {
    setLoading(true);

    const params = {
      items_per_page: itemsPerPage,
      page_number: page,
    };
    if (filters.models.length && !clearFilters) {
      params.model = filters.models;
    }
    if (filters.tags.length && !clearFilters) {
      params.tag = filters.tags;
    }
    if (filters.startDate && !clearFilters) {
      params.from_date = filters.startDate;
    }
    if (filters.endDate && !clearFilters) {
      params.to_date = filters.endDate;
    }

    try {
      const response = await customAxios.get(
        'tailor/v1/data',
        controller ? { params, signal: controller.signal } : { params },
      );
      setRetrievedLogs(response.data?.logs ?? []);
      setTotalPages(Math.ceil(response.data?.total / itemsPerPage));
      setTotalLogs(response.data?.total);
      const tags =
        response?.data?.tags
          ?.map((tag) => (tag === '[]' ? null : tag.replace(/[[\]{}"]+/g, '')))
          .filter(Boolean)
          .sort() || [];
      setUniqueTags([...new Set(tags)]);
      const models =
        response.data?.models
          ?.map((model) => model.trim())
          .filter(Boolean)
          .sort() || [];
      setUniqueModels([...new Set(models)]);
    } catch (error) {
      if (import.meta.env.DEV) {
        console.error(error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (searchParams.get('items_per_page') && searchParams.get('page_number')) {
      fetchLogs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, itemsPerPage]);

  useEffect(() => {
    let fetchLogsTimeout;
    const controller = new AbortController();
    if (!loading) {
      fetchLogsTimeout = setTimeout(() => {
        fetchLogs(false, controller);
      }, 500);
    }
    return () => {
      clearTimeout(fetchLogsTimeout);
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const handlePageChange = (newPage) => {
    searchParams.set('page_number', newPage);
    setSearchParams(searchParams);
  };

  const handleItemsPerPageChange = (newItemsPerPage) => {
    searchParams.set('items_per_page', newItemsPerPage);
    setSearchParams(searchParams);
  };

  const handleSelectTab = (tab) => {
    if (activeTab === tab) {
      searchParams.set('page_number', '1');
      searchParams.set('items_per_page', '50');
      setSearchParams(searchParams);
      setFilters({
        models: [],
        tags: [],
        startDate: '',
        endDate: '',
      });
    }
    setActiveTab(tab);
  };

  return (
    <>
      <div className=" bg-zinc-50 font-dmSans">
        <header className="pt-10 lg:pt-0">
          <div className="flex justify-between h-16 p-4 text-xl font-medium gap-x-4 text-zinc-800">
            <div className="whitespace-nowrap min-w-fit">Data</div>
            {/* <div className="inline-flex items-center px-3 py-2 text-sm font-medium text-center rounded-md md:mr-2 bg-white border focus:ring-0 focus:outline-none focus:ring-indigo-300 md:justify-evenly text-zinc-800"> */}
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4 mr-2 text-indigo-600"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z"
                />
              </svg> */}
              {/* <span className="mr-2">Synthesizing logs... {50}%</span> */}

              {/* Progress bar */}
              {/* <div className="relative w-32 h-2 bg-zinc-200 rounded-full overflow-hidden">
                <div
                  className="absolute top-0 left-0 h-full bg-gradient-to-tr from-indigo-50 via-indigo-300 to-indigo-400 transition-all duration-300"
                  style={{ width: `${50}%` }}
                ></div>
              </div> */}
            {/* </div> */}

            <button
              className="inline-flex items-center px-3 py-2 text-sm font-medium text-center border rounded-md shadow md:mr-2 bg-zinc-100 hover:bg-zinc-200 focus:ring-0 focus:outline-none focus:ring-indigo-300 md:justify-evenly text-zinc-800"
              onClick={() => setShowUploadLogsModal(true)}
            >
              {validationInProgress ? (
                <div className="flex items-center capitalize w-fit">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 24 24"
                  >
                    <g>
                      <rect
                        width="2"
                        height="5"
                        x="11"
                        y="1"
                        fill="currentColor"
                        opacity="0.14"
                      />
                      <rect
                        width="2"
                        height="5"
                        x="11"
                        y="1"
                        fill="currentColor"
                        opacity="0.29"
                        transform="rotate(30 12 12)"
                      />
                      <rect
                        width="2"
                        height="5"
                        x="11"
                        y="1"
                        fill="currentColor"
                        opacity="0.43"
                        transform="rotate(60 12 12)"
                      />
                      <rect
                        width="2"
                        height="5"
                        x="11"
                        y="1"
                        fill="currentColor"
                        opacity="0.57"
                        transform="rotate(90 12 12)"
                      />
                      <rect
                        width="2"
                        height="5"
                        x="11"
                        y="1"
                        fill="currentColor"
                        opacity="0.71"
                        transform="rotate(120 12 12)"
                      />
                      <rect
                        width="2"
                        height="5"
                        x="11"
                        y="1"
                        fill="currentColor"
                        opacity="0.86"
                        transform="rotate(150 12 12)"
                      />
                      <rect
                        width="2"
                        height="5"
                        x="11"
                        y="1"
                        fill="currentColor"
                        transform="rotate(180 12 12)"
                      />
                      <animateTransform
                        attributeName="transform"
                        calcMode="discrete"
                        dur="0.75s"
                        repeatCount="indefinite"
                        type="rotate"
                        values="0 12 12;30 12 12;60 12 12;90 12 12;120 12 12;150 12 12;180 12 12;210 12 12;240 12 12;270 12 12;300 12 12;330 12 12;360 12 12"
                      />
                    </g>
                  </svg>
                  <span className="ml-2 text-xs md:text-sm">Validating</span>
                  <span className="w-[3ch] text-right">{percentComplete}</span>%
                </div>
              ) : (
                <div className="inline-flex items-center justify-center py-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="mr-2 size-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 8.25H7.5a2.25 2.25 0 0 0-2.25 2.25v9a2.25 2.25 0 0 0 2.25 2.25h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25H15m0-3-3-3m0 0-3 3m3-3V15"
                    />
                  </svg>
                  Upload Logs
                </div>
              )}
            </button>
          </div>
          <hr className="border-t border-zinc-300" />
        </header>
        <div className="flex gap-10 py-4">
          <button
            className={`cursor-pointer w-28 flex-center text-lg font-medium text-zinc-500 ${
              activeTab === 'logs'
                ? 'text-zinc-800 underline underline-offset-4'
                : ''
            }`}
            onClick={() => handleSelectTab('logs')}
          >
            Logs
          </button>
          <button
            className={`cursor-pointer w-28 font-medium flex-center text-lg text-zinc-500 ${
              activeTab === 'datasets'
                ? 'text-zinc-800 underline underline-offset-4'
                : ''
            }`}
            onClick={() => handleSelectTab('datasets')}
          >
            Datasets
          </button>
        </div>
        <div className="w-full min-h-screen ">
          {activeTab === 'logs' ? (
            <LogsComponent
              retrievedLogs={retrievedLogs}
              setFilters={setFilters}
              filters={filters}
              uniqueModels={uniqueModels}
              uniqueTags={uniqueTags}
              loading={loading}
              page={page}
              setPage={handlePageChange}
              totalPages={totalPages}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={handleItemsPerPageChange}
              fetchLogs={fetchLogs}
            />
          ) : (
            <DatasetsComponent />
          )}
        </div>
      </div>
      <UploadLogsModal
        openModal={showUploadLogsModal}
        closeModal={() => setShowUploadLogsModal(false)}
      />
    </>
  );
};

export default TailorLogsDatasets;
