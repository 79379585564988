import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { toast } from 'react-hot-toast';
import { PlusIcon, MinusIcon } from '@heroicons/react/20/solid';

import Pagination from '../Components/AdminDashPagination';
import { useUser } from '../UserContext';
import { useCurrencyConverter } from '../Hooks/useCurrencyConverter';
import AdminDashConfirmationModal from '../Components/Modals/AdminDashConfirmationModal';
import AdminAddCredits from '../Components/Modals/AdminAddCredits';
import { padStart } from '../utils/generalUtils';
import AddToWhitelistModal from '../Components/Modals/AddToWhitelistModal';
import LastUpdated from '../Components/Dashboards/LastUpdated';
import useCheckUserLoggedIn from '../Hooks/useCheckUserLoggedIn';
import Spinner from '../Components/Spinner';
import AdminUserRequest from '../Components/Dashboards/AdminUserRequest';

const DashboardStats = ({
  handleOpenAddCreditsModal,
  openGraylog,
  lastFetched,
  stats,
  dataLoading,
  handlePressRefreshData,
  scrollToNewUser,
  filteredRequests,
}) => {
  let utilPercent =
    (stats.total_number_of_rented_gpus / stats.total_number_of_gpus) * 100;

  return (
    <div className="py-3 font-medium tracking-wide border-b shadow-sm bg-zinc-50">
      <div className="px-4 mx-auto max-w-screen-2xl lg:px-12">
        {/* Boxes in a Single Row */}
        <div className="grid grid-cols-1 gap-4 mb-6 md:grid-cols-2 lg:grid-cols-4">
          <div className="flex flex-col justify-between m-2 space-y-2">
            {/* Content for Box 1 */}
            <div className="flex border-b rounded shadow-sm h-1/2 bg-gradient-to-r from-gray-50 to-gray-100 dark:bg-gray-800 dark:from-gray-800">
              <div className="flex items-end justify-start w-full px-4 text-lg text-center text-gray-600 dark:text-white dark:bg-gray-800 dark:from-gray-700 rounded-l-md">
                Current Rentals
              </div>
              <div className="flex items-end justify-end px-4 text-5xl font-thin text-center dark:text-white dark:bg-gray-800 rounded-r-md">
                {padStart(stats.number_active_pods)}
              </div>
            </div>
            <div className="flex border-b rounded shadow-sm bg-gradient-to-r from-gray-50 to-gray-100 dark:bg-gray-800 dark:from-gray-800 h-1/2 ">
              <div className="flex items-end justify-start w-full px-4 text-lg text-center text-gray-600 dark:text-white rounded-l-md dark:bg-gray-800 dark:from-gray-700">
                Nodes Listed
              </div>
              <div className="flex items-end justify-end px-4 text-5xl font-thin text-center dark:text-white dark:bg-gray-800 rounded-r-md">
                {padStart(stats.total_number_nodes)}
              </div>
            </div>
          </div>

          <div className="flex flex-col px-4 pt-4 m-2 border-b rounded shadow-sm bg-gradient-to-r from-gray-50 to-gray-100 dark:bg-gray-800 dark:from-gray-800 h-80">
            {/* Content for Box 2 */}

            <div className="flex items-center justify-center flex-grow w-full -mt-4">
              <div className="flex w-64 h-64 ">
                <svg className="w-full h-full" viewBox="0 0 100 100 ">
                  {/* <!-- Background circle --> */}
                  <circle
                    className="text-gray-900 stroke-current"
                    strokeWidth="5"
                    cx="50"
                    cy="50"
                    r="40"
                    fill="transparent"
                  ></circle>
                  {/* <!-- Progress circle --> */}
                  <circle
                    className="text-indigo-500 stroke-current progress-ring__circle"
                    strokeWidth="3"
                    strokeLinecap="round"
                    cx="50"
                    cy="50"
                    r="40"
                    fill="transparent"
                    strokeDasharray="251.2"
                    strokeDashoffset={`${251.2 * ((100 - utilPercent) / 100)}`}
                  ></circle>

                  {/* <!-- Center text --> */}
                  <text
                    x="50"
                    y="50"
                    fontSize="12"
                    textAnchor="middle"
                    alignmentBaseline="middle"
                    fill="#5145cd"
                  >
                    {utilPercent.toFixed(1)}%
                  </text>
                  <text
                    x="50"
                    y="62"
                    fontSize="7"
                    textAnchor="middle"
                    alignmentBaseline="middle"
                    fill="#5145cd"
                  >
                    {stats.total_number_of_rented_gpus}/
                    {stats.total_number_of_gpus}
                  </text>
                </svg>
              </div>
            </div>
            <div className="flex items-start justify-start w-full text-lg text-left text-gray-600 dark:text-white">
              Utilisation Rate (GPUs)
            </div>
          </div>

          <div className="flex flex-col justify-between m-2 space-y-2">
            {/* Box 1 */}
            <div className="flex w-full border-b rounded shadow-sm h-1/2 bg-gradient-to-r from-gray-50 to-gray-100 dark:bg-gray-800 dark:from-gray-800">
              <div className="flex items-end w-full px-4 text-lg text-center text-gray-600 justify-left dark:text-white dark:bg-gray-800 dark:from-gray-700 rounded-l-md">
                Total Users
              </div>
              <div className="flex items-end justify-center px-4 text-5xl font-thin text-center dark:text-white dark:bg-gray-800 rounded-r-md">
                {padStart(stats.total_users)}
              </div>
            </div>

            {/* Box 2 */}
            <div className="flex w-full border-b rounded shadow-sm h-1/4 bg-gradient-to-r from-gray-50 to-gray-100 dark:bg-gray-800 dark:from-gray-800">
              <div className="flex items-end w-full pl-4 text-lg text-center text-gray-600 whitespace-pre justify-left dark:text-white dark:bg-gray-800 dark:from-gray-700 rounded-l-md">
                Active<span className="pb-1 text-xs"> (last 30 days)</span>
              </div>
              <div className="flex items-end justify-center pr-4 text-3xl font-thin text-center dark:text-white dark:bg-gray-800 rounded-r-md">
                {padStart(stats.total_active_users)}
              </div>
            </div>

            {/* Box 3 */}
            <div className="flex border-b rounded shadow-sm h-1/4 bg-gradient-to-r from-gray-50 to-gray-100 dark:bg-gray-800 dark:from-gray-800">
              <div className="flex items-end w-full px-4 text-lg text-center text-gray-600 justify-left dark:text-white dark:bg-gray-800 dark:from-gray-700 rounded-l-md">
                <div className="flex items-baseline text-left lg:block">
                  <div>Today</div>
                </div>
              </div>
              <div className="flex items-end justify-center px-4 text-3xl font-thin text-center dark:text-white dark:bg-gray-800 rounded-r-md">
                +{padStart(stats.new_users_today)}
              </div>
            </div>

            {/* Box 4 */}
            <div className="flex border-b rounded shadow-sm h-1/4 bg-gradient-to-r from-gray-50 to-gray-100 dark:bg-gray-800 dark:from-gray-800">
              <div className="flex items-end w-full px-4 text-lg text-center text-gray-600 justify-left dark:text-white dark:bg-gray-800 dark:from-gray-700 rounded-l-md">
                <div className="text-left">
                  <div>This Week</div>
                </div>
              </div>
              <div className="flex items-end justify-center px-4 text-3xl font-thin text-center dark:text-white dark:bg-gray-800 rounded-r-md">
                +{padStart(stats.new_users_this_week)}
              </div>
            </div>
          </div>

          <div className="flex flex-col justify-between p-2 rounded-lg dark:bg-gray-900">
            {/* Grid container */}
            <div className="grid h-full grid-cols-2 grid-rows-4 gap-3 pb-2 text-sm">
              <button
                onClick={handlePressRefreshData}
                className={`flex flex-col h-full col-span-1 row-span-1 p-2 text-indigo-600 border-b border-indigo-600 rounded shadow-md cursor-pointer dark:text-gray-300 dark:bg-gray-800 dark:border-gray-700 hover:text-gray-600 hover: dark:hover:bg-gray-700 bg-gradient-to-r from-gray-50 to-gray-100 hover:shadow-inner dark:from-gray-700 ${dataLoading ? 'items-center justify-around' : 'items-start justify-end'}`}
                disabled={dataLoading}
              >
                {dataLoading ? (
                  <Spinner borderTopColor={'#4F46E5'} />
                ) : (
                  'Refresh Data'
                )}
              </button>
              <div
                onClick={handleOpenAddCreditsModal}
                className="flex flex-col items-start justify-end h-full col-span-1 row-span-1 p-2 text-indigo-600 border-b border-indigo-600 rounded shadow-md cursor-pointer dark:text-gray-300 dark:bg-gray-800 dark:border-gray-700 hover:text-gray-600 hover: dark:hover:bg-gray-700 bg-gradient-to-r from-gray-50 to-gray-100 hover:shadow-inner dark:from-gray-700"
              >
                Add Credits
              </div>
              <div
                onClick={openGraylog}
                className="flex flex-col items-start justify-end h-full col-span-1 row-span-1 p-2 text-indigo-600 border-b border-indigo-600 rounded shadow-md cursor-pointer dark:text-gray-300 dark:bg-gray-800 dark:border-gray-700 hover:text-gray-600 hover: dark:hover:bg-gray-700 bg-gradient-to-r from-gray-50 to-gray-100 hover:shadow-inner dark:from-gray-700"
              >
                Graylog
              </div>
              {/* <div
                onClick={addUserToWhitelist}
                className="flex flex-col items-start justify-end h-full col-span-1 row-span-1 p-2 text-indigo-600 border-b border-indigo-600 rounded shadow-md cursor-pointer dark:text-gray-300 dark:bg-gray-800 dark:border-gray-700 hover:text-gray-600 hover: dark:hover:bg-gray-700 bg-gradient-to-r from-gray-50 to-gray-100 hover:shadow-inner dark:from-gray-700"
              >
                Invite to Enterprise
              </div> */}
              <button
                onClick={scrollToNewUser}
                disabled={filteredRequests.length === 0}
                className="flex flex-col items-start justify-end h-full col-span-2 row-span-1 p-2 text-indigo-600 border-b border-indigo-600 rounded shadow-md cursor-pointer dark:text-gray-300 dark:bg-gray-800 dark:border-gray-700 hover:text-gray-600 hover: dark:hover:bg-gray-700 bg-gradient-to-r from-gray-50 to-gray-100 hover:shadow-inner dark:from-gray-700"
              >
                <span>
                  Review New Users
                  <span className="ml-2 text-2xl text-zinc-900 ">
                    {filteredRequests.length}
                  </span>
                </span>
              </button>

              {/* Add more grid items here as needed */}
            </div>
            {/* Last fetched information */}
            <LastUpdated date={lastFetched} />
          </div>
        </div>
      </div>
    </div>
  );
};

const NewAdminDash = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [marketplaceListings, setMarketplaceListings] = useState([]);
  const [instances, setInstances] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedOption, setSelectedOption] = useState('instances');
  const initialSelectedItems = {
    instances: JSON.parse(localStorage.getItem('selectedInstancesItems')) || [],
    listings: JSON.parse(localStorage.getItem('selectedListingsItems')) || [],
    users: JSON.parse(localStorage.getItem('selectedUsersItems')) || [],
    providers: JSON.parse(localStorage.getItem('selectedProvidersItems')) || [],
  };

  const [selectedItems, setSelectedItems] = useState(initialSelectedItems);

  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');
  const [lastFetched, setLastFetched] = useState(null);
  const [dloading, setdLoading] = useState(false);
  const { customAxios } = useUser();
  const money = useCurrencyConverter();
  const [searchInput, setSearchInput] = useState('');
  const [filterUserEmail, setFilterUserEmail] = useState('');
  const [isCreditModalOpen, setIsCreditModalOpen] = useState(false);
  const [providersData, setProvidersData] = useState([]);
  const [expandedRows, setExpandedRows] = useState({});
  const [isWhitelistModalOpen, setIsWhitelistModalOpen] = useState(false);
  const [stats, setStats] = useState({});
  const [nodes, setNodes] = useState([]);
  const [displayedEntries, setDisplayedEntries] = useState(0);
  const checkLoginStatus = useCheckUserLoggedIn();
  const [accountRequests, setAccountRequests] = useState([]);
  const [deniedRequests, setDeniedRequests] = useState([]);

  const newUserRef = useRef(null);

  const scrollToNewUser = () => {
    newUserRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const getProviderName = (providerId) => {
    const providerNames = {
      1: 'Tromero 1',
      2: 'Tromero 2',
      8: 'GCP',
      4: 'Easy Crypto',
      7: 'Northern Data',
      6: 'Hut8',
      9: 'Tromero 3',
    };

    return providerNames[providerId] || 'Tromero';
  };

  const fetchStats = async () => {
    try {
      const response = await customAxios.get('admin/stats');
      setStats(response.data);
    } catch (error) {
      if (import.meta.env.DEV) {
        console.error('Error fetching stats:', error);
      }
      if (error.code === 'ERR_NETWORK') {
        toast.error('Network error. Please try again later.');
        return;
      }
      toast.error('Error fetching stats');
    }
  };

  const fetchInstances = async () => {
    try {
      const response = await customAxios.get('admin/pods');
      setInstances(response.data.pods);
    } catch (error) {
      if (import.meta.env.DEV) {
        console.error('Error fetching instances:', error);
      }
      if (error.code === 'ERR_NETWORK') {
        toast.error('Network error. Please try again later.');
        return;
      }
      toast.error('Error fetching instances');
    }
  };

  const fetchNodes = async () => {
    try {
      const response = await customAxios.get('admin/nodes');
      const processedNodes = response.data.nodes
        .map((n) => ({
          ...n,
          sortPriority:
            n.available_gpus < n.gpu_count ? 1 : n.accessible ? 2 : 3,
        }))
        .sort((a, b) => a.sortPriority - b.sortPriority);
      setNodes(processedNodes);
    } catch (error) {
      if (import.meta.env.DEV) {
        console.error('Error fetching nodes:', error);
      }
      if (error.code === 'ERR_NETWORK') {
        toast.error('Network error. Please try again later.');
        return;
      }
      toast.error('Error fetching nodes');
    }
  };

  const fetchUsers = async () => {
    try {
      const usersResponse = await customAxios.get('admin/users');
      const fetchedUsers = usersResponse.data.users;
      const sortedUsers = fetchedUsers.sort((a, b) => b.id - a.id);

      setUsers(sortedUsers);
    } catch (error) {
      if (import.meta.env.DEV) {
        console.error('Error fetching users:', error);
      }
      if (error.code === 'ERR_NETWORK') {
        toast.error('Network error. Please try again later.');
        return;
      }
      toast.error('Error fetching users');
    }
  };

  const fetchProviders = async () => {
    try {
      const response = await customAxios.get('admin/clusters');
      setProvidersData(response.data.clusters);
    } catch (error) {
      if (import.meta.env.DEV) {
        console.error('Error fetching clusters:', error);
      }
      toast.error(
        'Error fetching clusters: ' + error.message || 'Unknown error',
      );
    }
  };

  const fetchAccountRequests = async () => {
    try {
      const response = await customAxios.get('admin/account_requests');
      setAccountRequests(response?.data?.account_requests || []);
    } catch (error) {
      if (import.meta.env.DEV) {
        console.error('Error fetching account requests:', error);
      }
      if (error.code === 'ERR_NETWORK') {
        toast.error('Network error. Please try again later.');
        return;
      }
      toast.error('Error fetching account requests');
    }
  };

  const fetchDeniedRequests = async () => {
    try {
      const response = await customAxios.get('admin/account_requests/denied');
      setDeniedRequests(response?.data?.account_requests || []);
    } catch (error) {
      console.error('Error fetching denied requests:', error);
    }
  };

  const handlePressRefreshData = async () => {
    setdLoading(true);
    await fetchData();
  };

  const fetchData = async () => {
    // try {
    // const statsPromise = fetchStats();
    // const instancesPromise = fetchInstances();
    // const nodesPromise = fetchNodes();
    // const usersPromise = fetchUsers();
    // const providersPromise = fetchProviders();

    // await Promise.all([
    //   statsPromise,
    //   instancesPromise,
    //   nodesPromise,
    //   usersPromise,
    //   providersPromise,
    // ]);

    await fetchStats();
    await fetchInstances();
    await fetchNodes();
    await fetchUsers();
    await fetchProviders();
    await fetchAccountRequests();
    await fetchDeniedRequests();

    const fetchedTime = new Date();
    setLastFetched(fetchedTime);
    setdLoading(false);
    // } catch (error) {
    //   console.error('Error fetching data:', error);
    //   if (error.code === 'ERR_NETWORK') {
    //     toast.error('Network error. Please try again later.');
    //     return;
    //   }
    //   toast.error('Error fetching data');
    // }
  };

  // Initial data load
  useEffect(() => {
    fetchData();

    // Set up the interval for polling
    const interval = setInterval(fetchData, 60000 * 5); // 60000 milliseconds = 1 minute

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const downloadCSV = (blob, filename) => {
  //   const url = window.URL.createObjectURL(blob);
  //   const a = document.createElement('a');
  //   a.href = url;
  //   a.download = filename;
  //   document.body.appendChild(a);
  //   a.click();
  //   window.URL.revokeObjectURL(url);
  //   a.remove();
  // };

  // const getDefaultFilename = (prefix) => {
  //   const date = new Date();
  //   const formattedDate = date.toISOString().split('T')[0];
  //   return `${formattedDate}-${prefix}.csv`;
  // };

  // const extractFilename = (headers) => {
  //   const contentDisposition =
  //     headers['content-disposition'] || headers['Content-Disposition'];
  //   if (contentDisposition) {
  //     const filenameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
  //     return filenameMatch ? filenameMatch[1] : getDefaultFilename('download');
  //   }
  //   return getDefaultFilename('download');
  // };

  // const handleRequestCSV = async (type) => {
  //   try {
  //     const response = await customAxios.get(`/admin/dashboard/${type}`, {
  //       headers: {
  //         Accept: 'text/csv',
  //       },
  //       responseType: 'blob',
  //     });

  //     const filename = response.headers['content-disposition']
  //       ? extractFilename(response.headers)
  //       : getDefaultFilename(type);
  //     downloadCSV(response.data, filename);
  //     toast.success(`${type} CSV downloaded`);
  //   } catch (error) {
  //     console.error('Error downloading CSV data:', error);
  //     if (error.code === 'ERR_NETWORK') {
  //       toast.error('Network error. Please try again later.');
  //       return;
  //     }
  //     toast.error(`Error downloading ${type} CSV`);
  //   }
  // };

  const downloadCSV = (filename) => {
    const headers = [
      'User ID',
      'Email',
      'First Name',
      'Last Name',
      'Sign Up Date',
      'Total Spent',
      'Available Balance',
    ];

    const csvContent = [
      headers.join(','),
      ...users.map((user) => {
        const formattedDate = new Date(user.created_at).toLocaleDateString(
          'en-US',
          {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          },
        );
        const totalSpent = money(user.total_credits_purchased);
        const availableBalance = money(user.current_balance);

        return [
          `"${user.id}"`,
          `"${user.email}"`,
          `"${user.first_name}"`,
          `"${user.last_name}"`,
          `"${formattedDate}"`,
          `"${totalSpent}"`,
          `"${availableBalance}"`,
        ].join(',');
      }),
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  };

  const getDefaultFilename = (prefix) => {
    const date = new Date();
    const formattedDate = date.toISOString().split('T')[0];
    return `${formattedDate}-${prefix}.csv`;
  };

  const handleDownloadUsersCSV = () => {
    if (users.length === 0) {
      toast.error('No data available to download.');
      return;
    }
    const filename = getDefaultFilename('users');
    downloadCSV(filename);
    toast.success('Users CSV downloaded');
  };

  // Handler for radio button change
  const handleOptionChange = (event) => {
    console.log('Selected option:', event.target.value);
    const newSelectedOption = event.target.value;
    setSelectedOption(newSelectedOption);

    // Update selectedItems state based on localStorage
    const updatedSelectedItems = { ...selectedItems };
    const storedItems =
      JSON.parse(
        localStorage.getItem(
          `selected${newSelectedOption.charAt(0).toUpperCase() + newSelectedOption.slice(1)}Items`,
        ),
      ) || [];
    updatedSelectedItems[newSelectedOption] = storedItems;
    setSelectedItems(updatedSelectedItems);

    setFilterUserEmail('');
    setCurrentPage(1);
  };

  // extra columns for table
  const handleToggleItemSelection = (item) => {
    const updatedSelectedItems = { ...selectedItems };

    // Ensure the selected option key exists in updatedSelectedItems
    if (!updatedSelectedItems[selectedOption]) {
      updatedSelectedItems[selectedOption] = [];
    }

    if (updatedSelectedItems[selectedOption].includes(item)) {
      updatedSelectedItems[selectedOption] = updatedSelectedItems[
        selectedOption
      ].filter((i) => i !== item);
    } else {
      updatedSelectedItems[selectedOption].push(item);
    }

    setSelectedItems(updatedSelectedItems);
    localStorage.setItem(
      `selected${selectedOption.charAt(0).toUpperCase() + selectedOption.slice(1)}Items`,
      JSON.stringify(updatedSelectedItems[selectedOption]),
    );
  };

  // new logic for pagination🧨🧨🧨🧨🧨🧨🧨🧨🧨🧨🧨🧨🧨🧨

  // Function to apply search filter to the entire dataset
  const applySearchFilter = (data) => {
    if (!searchInput) {
      return data;
    }
    const searchLower = searchInput.toLowerCase();

    return data.filter((item) => {
      let match = false;

      switch (selectedOption) {
        case 'instances':
          match =
            item.id.toString().includes(searchLower) ||
            item.buyer_email.toLowerCase().includes(searchLower) ||
            (item.created_at &&
              item.created_at.toLowerCase().includes(searchLower)) ||
            (item.duration && item.duration.toString().includes(searchLower)) ||
            (item.current_cost &&
              item.current_cost?.toString().includes(searchLower)) ||
            item.normalized_gpu_type?.toLowerCase().includes(searchLower) ||
            item.state?.toLowerCase().includes(searchLower);
          break;
        case 'nodes':
          match =
            item.id.toString().includes(searchLower) ||
            item.normalized_gpu_type.toLowerCase().includes(searchLower) ||
            item.gpu_count.toString().includes(searchLower) ||
            item.available_gpus.toString().includes(searchLower) ||
            item.accessibility.toLowerCase().includes(searchLower) ||
            getProviderName(item.provider_id)
              .toLowerCase()
              .includes(searchLower);
          break;
        case 'users':
          match =
            item.id.toString().includes(searchLower) ||
            (item.email && item.email.toLowerCase().includes(searchLower)) ||
            (item.first_name &&
              item.first_name.toLowerCase().includes(searchLower)) ||
            (item.last_name &&
              item.last_name.toLowerCase().includes(searchLower)) ||
            (item.created_at &&
              item.created_at.toLowerCase().includes(searchLower)) ||
            (item.total_spent &&
              item.total_spent.toString().includes(searchLower)) ||
            (item.role && item.role.toLowerCase().includes(searchLower)) ||
            (item.interest &&
              item.interest.toLowerCase().includes(searchLower)) ||
            (item.teamSize && item.teamSize.toString().includes(searchLower)) ||
            (item.delinquent &&
              item.delinquent.toString().includes(searchLower));
          break;
        case 'providers':
          break;

        default:
          break;
      }

      return match;
    });
  };

  // Function to get filtered data based on the selected option
  const getFilteredData = () => {
    let filteredData;
    switch (selectedOption) {
      case 'instances':
        filteredData = applySearchFilter(instances);
        break;
      case 'nodes':
        filteredData = applySearchFilter(nodes);
        break;
      case 'users':
        if (filterUserEmail) {
          filteredData = users.filter((user) => user.email === filterUserEmail);
        } else {
          filteredData = applySearchFilter(users);
        }
        break;

      case 'providers':
        filteredData = applySearchFilter(providersData);
        break;
      default:
        filteredData = [];
    }
    return filteredData;
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const calculatePageCount = () => {
    const filteredData = getFilteredData();
    return Math.ceil(filteredData.length / rowsPerPage);
  };

  const getCurrentRows = () => {
    const filteredData = getFilteredData();
    const start = (currentPage - 1) * rowsPerPage;
    const end = start + rowsPerPage;
    return filteredData.slice(start, end);
  };

  useEffect(() => {
    // Recalculate page count when search input changes
    calculatePageCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInput, selectedOption, instances, nodes, users]); // Add dependencies here as needed

  let displayData = getCurrentRows();

  //🧨🧨🧨🧨🧨🧨🧨🧨🧨🧨🧨🧨🧨🧨🧨

  const pageCount = calculatePageCount();
  const currentRows = getCurrentRows();

  const getTotalEntries = () => {
    switch (selectedOption) {
      case 'instances':
        return instances.length;
      case 'nodes':
        return nodes.length;
      case 'users':
        return users.length;
      case 'providers':
        return providersData.length;
      default:
        return 0;
    }
  };

  const totalEntries = getTotalEntries();

  useEffect(() => {
    setDisplayedEntries(currentRows.length);
  }, [currentRows]);

  // Function to handle page change
  // const handlePageChange = (newPage) => {
  //   setCurrentPage(newPage);
  // };

  const sortData = (columnName) => {
    const direction =
      sortColumn === columnName && sortDirection === 'asc' ? 'desc' : 'asc';
    setSortColumn(columnName);
    setSortDirection(direction);

    const dataToSort =
      selectedOption === 'nodes'
        ? nodes
        : selectedOption === 'instances'
          ? instances
          : users;
    const sorted = [...dataToSort].sort((a, b) => {
      let aValue = a[columnName];
      let bValue = b[columnName];

      if (columnName === 'created_at') {
        aValue = new Date(aValue);
        bValue = new Date(bValue);
      }

      // Convert numeric string values to numbers if the column is known to hold numeric values
      if (['total_credits_purchased', 'current_balance'].includes(columnName)) {
        aValue = parseFloat(aValue);
        bValue = parseFloat(bValue);
      }

      if (aValue < bValue) {
        return direction === 'asc' ? -1 : 1;
      }
      if (aValue > bValue) {
        return direction === 'asc' ? 1 : -1;
      }
      return 0;
    });

    selectedOption === 'nodes'
      ? setNodes(sorted)
      : selectedOption === 'instances'
        ? setInstances(sorted)
        : setUsers(sorted); // Update the correct state
  };

  // Admin status------------------🖐️🖐️🖐️🖐️🖐️🖐️🖐️

  // const updateAdminStatus = async (userId) => {
  //   const token = localStorage.getItem('token');
  //   const url = `${frontendServerUrl}/admin/users/${userId}`;
  //   const body = { admin: true };
  //   const headers = {
  //     'Content-Type': 'application/json',
  //     Authorization: `Bearer ${token}`,
  //   };

  //   try {
  //     const response = await axios.patch(url, body, { headers });
  //     console.log('Response:', response.data);
  //     // Handle the response as needed
  //   } catch (error) {
  //     console.error('Error in PATCH request:', error);
  //     // Handle the error appropriately
  //   }
  // };

  // const handleUpdateAdmin = () => {
  //   updateAdminStatus();
  // };

  const extraColumns = {
    // instances: ['LISTING ID', 'GPU TYPE'],
    instances: ['STATE'],
    nodes: ['CLUSTER ID', 'REGION', 'PROVIDER', 'GPU PRICE'],
    users: ['NEW USER', 'BANNED'],
    providers: [],
  };

  const getExtraColumnData = (displayData) => {
    let headerName;
    if (selectedOption in extraColumns) {
      if (!Array.isArray(selectedItems[selectedOption])) {
        return [];
      }
      return selectedItems[selectedOption]
        .map((item) => {
          if (extraColumns[selectedOption].includes(item)) {
            let rowData;
            switch (selectedOption) {
              case 'nodes':
                rowData = displayData.map((node) => {
                  // Note: Make sure to handle null or undefined values
                  switch (item) {
                    case 'NODE ID':
                      headerName = 'id';
                      return node.id.toString();
                    case 'AVAILABLE':
                      headerName = 'availability';
                      return node.availability === 'available' ? 'Yes' : 'No';
                    case 'ACCESSIBLE':
                      headerName = 'accessibility';
                      return node.accessibility === 'accessible' ? 'Yes' : 'No';
                    case 'AVAILABLE GPUS':
                      headerName = 'available_gpus';
                      return node.available_gpus || 'Unknown';
                    case 'CLUSTER ID':
                      headerName = 'cluster_id';
                      return node.cluster_id || 'Unknown';
                    case 'REGION':
                      headerName = 'region';
                      return node.region || 'Unknown';
                    case 'PROVIDER':
                      headerName = 'provider_id';
                      return getProviderName(node.provider_id) || 'Unknown';
                    case 'NUM GPUS':
                      headerName = 'num_gpus';
                      return node.num_gpus || '0';
                    case 'GPU PRICE':
                      headerName = 'price_per_gpu';
                      return money(node.price_per_gpu) || 'N/A';
                    case 'GPU TYPE':
                      headerName = 'normalized_gpu_type';
                      return node.normalized_gpu_type || 'N/A';
                    default:
                      return '';
                  }
                });
                break;
              case 'instances':
                rowData = displayData.map((instance) => {
                  // Assuming each instance has a listing object from which you need to extract data
                  // Make sure to handle null or undefined values appropriately
                  switch (item) {
                    case 'NODE ID':
                      headerName = 'node_id';
                      return instance?.node_id ?? 'N/A';
                    case 'GPU TYPE':
                      headerName = 'normalized_gpu_type';
                      return instance.normalized_gpu_type ?? 'N/A';
                    case 'GPU COUNT':
                      headerName = 'gpu_count';
                      return instance.gpu_count ?? 'N/A';
                    case 'STATE':
                      headerName = 'state';
                      return instance.state ?? 'N/A';
                    default:
                      return '';
                  }
                });
                break;
              case 'users':
                rowData = displayData.map((user) => {
                  switch (item) {
                    case 'NEW USER':
                      headerName = 'new_user';
                      return user.new_user ? 'Yes' : 'No';
                    case 'BANNED':
                      headerName = 'banned';
                      return user.banned ? 'Yes' : 'No';
                    default:
                      return '';
                  }
                });
                break;
              // Handle other cases like 'users', and 'providers'
            }
            return {
              header: item,
              rowData: rowData,
              headerName,
            };
          }
          return null;
        })
        .filter((column) => column !== null);
    }
    return [];
  };

  // Update where you call getExtraColumnData
  const extraColumnData = getExtraColumnData(displayData);

  // 🔴 banning logic
  const [isBanModalOpen, setIsBanModalOpen] = useState(false);
  const [selectedUserForBan, setSelectedUserForBan] = useState(null);

  const openBanModal = (user) => {
    setSelectedUserForBan(user);
    setIsBanModalOpen(true);
  };

  const closeModal = async () => {
    setIsBanModalOpen(false);
    await fetchUsers();
  };

  // new filter and search features

  const handleUserEmailClick = (email) => {
    setSelectedOption('users');
    setFilterUserEmail(email); // filter the input to the clicked email
    setCurrentPage(1); // Reset to the first page
  };

  // const handleSearchInputChange = (event) => {
  //   setSearchInput(event.target.value);
  // };

  // new filter and search features

  // Function to handle adding credits

  const handleOpenAddCreditsModal = () => {
    setIsCreditModalOpen(true);
  }; // Open the modal
  const handleCloseAddCreditsModal = () => {
    setIsCreditModalOpen(false);
  }; // Close the modal

  const openGraylog = () => {
    const url = 'http://35.188.61.42:9000/';
    window.open(url, '_blank');
  };

  useEffect(() => {
    if (
      !['instances', 'nodes', 'users', 'providers'].includes(selectedOption)
    ) {
      setSelectedOption('instances');
    }
  }, [selectedOption]);

  const aggregateListingsByGPU = (listings) => {
    const grouped = {};

    listings.forEach((listing) => {
      // Check if the listing object and gpu_name exist
      if (listing && listing.listing && listing.listing.gpu_name) {
        const gpuName = listing.listing.gpu_name;
        if (grouped[gpuName]) {
          grouped[gpuName].count++;
          grouped[gpuName].accessible += listing.listing.accessible ? 1 : 0;
        } else {
          grouped[gpuName] = {
            count: 1,
            accessible: listing.listing.accessible ? 1 : 0,
          };
        }
      }
    });

    return grouped;
  };

  // Filter newRequests to only include items where user_id is null
  const filteredRequests = accountRequests.filter(
    (request) => request.user_id === null,
  );

  const filteredDeniedRequests = deniedRequests.filter(
    (request) => request.user_id === null,
  );

  const convertAccounts = async (accountRequestIds, emails) => {
    try {
      const response = await customAxios.post('admin/convert_accounts', {
        account_request_ids: accountRequestIds,
      });

      if (import.meta.env.DEV) {
        console.log(`Converting accounts with IDs: ${accountRequestIds}`);
        console.log('Response:', response.data);
      }

      emails.forEach((email) => {
        toast.success(`Account for ${email} has been successfully converted.`);
      });

      await fetchAccountRequests();

      // Additional success handling if necessary
    } catch (error) {
      console.error('Error converting accounts:', error);

      emails.forEach((email) => {
        toast.error(`Failed to convert account for ${email}.`);
      });

      // Additional error handling if necessary
    }
  };

  const denyAccounts = async (accountRequestIds, emails) => {
    try {
      const response = await customAxios.post('admin/deny_accounts', {
        account_request_ids: accountRequestIds,
      });

      if (import.meta.env.DEV) {
        console.log(`Denying accounts with IDs: ${accountRequestIds}`);
        console.log('Response:', response.data);
      }

      emails.forEach((email) => {
        toast.success(
          `Account request for ${email} has been successfully denied.`,
        );
      });

      await fetchAccountRequests();

      // Additional success handling if necessary
    } catch (error) {
      console.error('Error denying accounts:', error);

      emails.forEach((email) => {
        toast.error(`Failed to deny account request for ${email}.`);
      });

      // Additional error handling if necessary
    }
  };

  return (
    <div className="min-h-screen tracking-wide font-spaceG dark:bg-gray-900 bg-zinc-50">
      <DashboardStats
        instances={instances}
        marketplaceListings={marketplaceListings}
        users={users}
        handleOpenAddCreditsModal={handleOpenAddCreditsModal}
        openGraylog={openGraylog}
        lastFetched={lastFetched}
        stats={stats}
        addUserToWhitelist={() => setIsWhitelistModalOpen(true)}
        dataLoading={dloading}
        handlePressRefreshData={handlePressRefreshData}
        scrollToNewUser={scrollToNewUser}
        newUserRef={newUserRef}
        filteredRequests={filteredRequests}
        accountRequests={accountRequests}
      />
      <section className="py-3 sm:py-5">
        <div className="px-4 mx-auto max-w-screen-2xl lg:px-12 ">
          <div className="relative overflow-hidden bg-zinc-50 dark:bg-gray-800 sm:rounded-lg">
            <div className="mx-4 ">
              <div className="flex items-center justify-between pt-3 space-x-4">
                <div className="flex items-center flex-1 space-x-3"></div>
                <div className="flex flex-row items-center justify-end flex-shrink-0 space-x-3"></div>
              </div>
              <div className="flex flex-col-reverse items-center justify-between py-3 md:flex-row md:space-x-4">
                <div className="flex flex-col w-full space-y-3 lg:w-2/3 md:space-y-0 md:flex-row md:items-center"></div>
              </div>
            </div>
            <div className="flex flex-wrap pb-3 mx-4">
              <ul className="grid w-full grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-4">
                <li>
                  <input
                    id="inline-1-radio"
                    type="radio"
                    value="instances"
                    onChange={handleOptionChange}
                    checked={selectedOption === 'instances'}
                    name="inline-radio-group"
                    className="hidden peer"
                  />
                  <label
                    htmlFor="inline-1-radio"
                    className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-md cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-indigo-500 peer-checked:border-indigo-600 peer-checked:text-indigo-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-300 dark:bg-gray-800 dark:hover:bg-gray-700 hover:shadow-inner"
                  >
                    <div className="block">
                      <div className="w-full text-lg font-semibold">
                        Instances
                      </div>
                    </div>
                    {/* <svg

                    </svg> */}
                  </label>
                </li>
                <li>
                  <input
                    id="inline-2-radio"
                    type="radio"
                    value="nodes"
                    onChange={handleOptionChange}
                    checked={selectedOption === 'nodes'}
                    name="inline-radio-group"
                    className="hidden peer"
                  />
                  <label
                    htmlFor="inline-2-radio"
                    className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-md cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-indigo-500 peer-checked:border-indigo-600 peer-checked:text-indigo-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-300 dark:bg-gray-800 dark:hover:bg-gray-700 hover:shadow-inner"
                  >
                    <div className="block">
                      <div className="w-full text-lg font-semibold">Nodes</div>
                    </div>
                    {/* <svg

                    </svg> */}
                  </label>
                </li>
                <li>
                  <input
                    id="inline-3-radio"
                    type="radio"
                    value="users"
                    onChange={handleOptionChange}
                    checked={selectedOption === 'users'}
                    name="inline-radio-group"
                    className="hidden peer"
                  />
                  <label
                    htmlFor="inline-3-radio"
                    className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-md cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-indigo-500 peer-checked:border-indigo-600 peer-checked:text-indigo-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-300 dark:bg-gray-800 dark:hover:bg-gray-700 hover:shadow-inner"
                  >
                    <div className="block">
                      <div className="w-full text-lg font-semibold">Users</div>
                    </div>
                    {/* <svg

                    </svg> */}
                  </label>
                </li>
                <li>
                  <input
                    id="inline-4-radio"
                    type="radio"
                    value="providers"
                    onChange={handleOptionChange}
                    checked={selectedOption === 'providers'}
                    name="inline-radio-group"
                    className="hidden peer"
                    disabled
                  />
                  <label
                    htmlFor="inline-4-radio"
                    className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-md cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-indigo-500 peer-checked:border-indigo-600 peer-checked:text-indigo-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-300 dark:bg-gray-800 dark:hover:bg-gray-700 hover:shadow-inner"
                  >
                    <div className="block">
                      <div className="w-full text-lg font-semibold">
                        Providers
                      </div>
                    </div>
                    {/* <svg

                    </svg> */}
                  </label>
                </li>
              </ul>
              <div className="flex flex-col w-full py-2 md:flex-row">
                <div className="flex items-center justify-center w-full my-auto mr-3 md:w-1/2">
                  <label
                    htmlFor="search"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  ></label>
                  <div className="relative flex items-center w-full md:mr-1.5">
                    <input
                      type="text"
                      name="search"
                      id="search"
                      value={searchInput}
                      placeholder={`Search ${selectedOption}...`}
                      onChange={(e) => setSearchInput(e.target.value)}
                      className="block w-full h-16 text-gray-900 bg-white border border-gray-200 rounded-md dark:text-gray-300 dark:border-gray-700 dark:hover:text-gray-300 hover:text-gray-600 dark:bg-gray-800 dark:hover:bg-gray-700 "
                    />
                  </div>
                </div>
                <div className="grid w-full grid-cols-2 gap-4 mt-4 md:my-auto h-36 md:h-16 md:w-1/2 md:grid-cols-3 md:gap-x-4 md:gap-y-2 ">
                  {extraColumns[selectedOption].map((item, index) => (
                    <div
                      className={`md:ml-2 flex items-center justify-between px-2 h-7 text-xs md:text-sm font-medium border rounded cursor-pointer dark:text-gray-100 dark:border-gray-700  uppercase bg-gray-50 dark:bg-gray-800 dark:from-gray-700 dark:text-gray-300 ${
                        Array.isArray(selectedItems[selectedOption]) &&
                        selectedItems[selectedOption].includes(item)
                          ? 'bg-gray-900 text-gray-100 dark:bg-gray-800 dark:from-gray-700'
                          : 'hover:bg-gray-100 dark:bg-gray-800 hover:shadow-inner'
                      }`}
                      key={index}
                      onClick={() => handleToggleItemSelection(item)}
                    >
                      {Array.isArray(selectedItems[selectedOption]) &&
                      selectedItems[selectedOption].includes(item) ? (
                        <MinusIcon className="w-5 h-5" />
                      ) : (
                        <PlusIcon className="w-5 h-5" />
                      )}
                      <span className="whitespace-nowrap">{item}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="overflow-x-auto">
              {/* 🔑 */}
              <table className="w-full font-sans text-sm text-left text-gray-500 dark:text-gray-300">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-800 dark:text-gray-300">
                  <tr>
                    {selectedOption === 'instances' && (
                      <>
                        <th
                          scope="col"
                          className="p-4 cursor-pointer"
                          onClick={() => sortData('id')}
                        >
                          Pod ID
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 cursor-pointer"
                          onClick={() => sortData('user.email')}
                        >
                          Rented By
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 cursor-pointer"
                          onClick={() => sortData('listing.gpu_name')}
                        >
                          GPU Type
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 cursor-pointer"
                          onClick={() => sortData('listing.gpu_name')}
                        >
                          GPU Count
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 cursor-pointer"
                          onClick={() => sortData('created_at')}
                        >
                          Created at
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 cursor-pointer"
                          onClick={() => sortData('duration')}
                        >
                          Duration
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 cursor-pointer"
                          onClick={() => sortData('current_cost')}
                        >
                          Current cost
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 cursor-pointer"
                          onClick={() => sortData('listing.id')}
                        >
                          Node ID
                        </th>
                        {extraColumnData.map((col, index) => (
                          <th
                            key={index}
                            scope="col"
                            className="px-4 py-3 cursor-pointer"
                            onClick={() => sortData(col.headerName)}
                          >
                            {col.header}
                          </th>
                        ))}
                      </>
                    )}

                    {selectedOption === 'nodes' && (
                      <>
                        <th
                          scope="col"
                          className="p-4 cursor-pointer"
                          onClick={() => sortData('id')}
                        >
                          Node ID
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 uppercase cursor-pointer"
                          onClick={() => sortData('normalized_gpu_type')}
                        >
                          gpu Type
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 uppercase cursor-pointer"
                          onClick={() => sortData('gpu_count')}
                        >
                          total gpus
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 cursor-pointer"
                          onClick={() => sortData('available_gpus')}
                        >
                          Available GPUs
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 cursor-pointer"
                          onClick={() => sortData('accessibility')}
                        >
                          accessible
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 cursor-pointer"
                          onClick={() => sortData('availability')}
                        >
                          available
                        </th>
                        {extraColumnData.map((col, index) => (
                          <th
                            key={index}
                            scope="col"
                            className="px-4 py-3 cursor-pointer"
                            onClick={() => sortData(col.headerName)}
                          >
                            {col.header}
                          </th>
                        ))}
                      </>
                    )}

                    {selectedOption === 'users' && (
                      <>
                        <th
                          scope="col"
                          className="p-4 cursor-pointer"
                          onClick={() => sortData('id')}
                        >
                          User ID
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 cursor-pointer"
                          onClick={() => sortData('email')}
                        >
                          Email
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 cursor-pointer"
                          onClick={() => sortData('first_name')}
                        >
                          First Name
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 cursor-pointer"
                          onClick={() => sortData('last_name')}
                        >
                          Last Name
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 cursor-pointer"
                          onClick={() => sortData('created_at')}
                        >
                          Sign Up Date
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 cursor-pointer"
                          onClick={() => sortData('total_credits_purchased')}
                        >
                          Total Spent
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 cursor-pointer"
                          onClick={() => sortData('current_balance')}
                        >
                          Available balance
                        </th>
                        {extraColumnData.map((col, index) => (
                          <th
                            key={index}
                            scope="col"
                            className="px-4 py-3 cursor-pointer"
                            onClick={() => sortData(col.headerName)}
                          >
                            {col.header}
                          </th>
                        ))}
                      </>
                    )}

                    {selectedOption === 'providers' && (
                      <>
                        <th
                          scope="col"
                          className="px-4 py-3 cursor-pointer"
                          onClick={() => sortData('name')}
                        >
                          Provider Name
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 cursor-pointer"
                          onClick={() => sortData('listings.length')}
                        >
                          Num of Nodes
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 cursor-pointer"
                          onClick={() => sortData('accessibleListingsCount')}
                        >
                          Accessible
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 cursor-pointer"
                          onClick={() => sortData('rentedCount')}
                        >
                          Rented
                        </th>
                        {/* Additional columns */}
                      </>
                    )}
                  </tr>
                </thead>

                <tbody>
                  {selectedOption === 'instances' &&
                    displayData.map((item, index) => (
                      <tr
                        key={index}
                        className="border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700"
                      >
                        <td className="px-4 py-3">{item.id}</td>
                        <td
                          className="px-4 py-3 cursor-pointer"
                          onClick={() => handleUserEmailClick(item.buyer_email)}
                        >
                          {item.buyer_email}
                        </td>
                        <td className="px-4 py-3">
                          {item.normalized_gpu_type}
                        </td>
                        <td className="px-4 py-3">{item.gpu_count}</td>
                        <td className="px-4 py-3">{item.created_at}</td>
                        <td className="px-4 py-3">
                          {moment
                            .duration(item.duration, 'seconds')
                            .asHours()
                            .toFixed(2)}{' '}
                          hours
                        </td>
                        <td className="px-4 py-3">
                          {item.current_cost !== null
                            ? money(item.current_cost)
                            : 'N/A'}
                        </td>
                        <td className="px-4 py-3">{item.node_id}</td>
                        {extraColumnData.map((col, colIndex) => (
                          <td key={colIndex} className="px-4 py-3 capitalize">
                            {col.rowData[index]}
                          </td>
                        ))}
                      </tr>
                    ))}

                  {selectedOption === 'nodes' &&
                    displayData.map((item, index) => (
                      <tr
                        key={index}
                        className="border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700"
                      >
                        <td className="px-4 py-3">{item.id}</td>
                        <td className="px-4 py-3">
                          {item.normalized_gpu_type}
                        </td>

                        <td className="px-4 py-3">{item.gpu_count}</td>
                        <td className="px-4 py-3">{item.available_gpus}</td>
                        <td className="px-4 py-3">
                          {item.accessibility === 'accessible' ? 'Yes' : 'No'}
                        </td>
                        <td className="px-4 py-3">
                          {item.availability === 'available' ? 'Yes' : 'No'}
                        </td>

                        {extraColumnData.map((col, colIndex) => {
                          return (
                            <td key={colIndex} className="px-4 py-3">
                              {col.rowData[index]}
                            </td>
                          );
                        })}
                      </tr>
                    ))}

                  {selectedOption === 'users' &&
                    displayData.map((item, index) => (
                      <tr
                        key={index}
                        className="border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700"
                      >
                        <td className="px-4 py-3">{item.id}</td>
                        <td
                          className={`px-4 py-3 cursor-pointer ${item.banned ? 'text-red-500 font-bold' : ''}`}
                          onClick={() => openBanModal(item)}
                        >
                          {item.email}
                        </td>

                        <td className="px-4 py-3">{item.first_name}</td>
                        <td className="px-4 py-3">{item.last_name}</td>
                        <td className="px-4 py-3">
                          {new Date(item.created_at).toLocaleDateString(
                            'en-US',
                            {
                              year: 'numeric',
                              month: 'short',
                              day: 'numeric',
                            },
                          )}
                        </td>
                        <td className="px-4 py-3">
                          {money(item.total_credits_purchased)}
                        </td>
                        {/* <td className="px-4 py-3">{money(item.total_paid)}</td> */}
                        {/* <td className="px-4 py-3">{item.role}</td>
                        <td className="px-4 py-3">{item.interest}</td>
                        <td className="px-4 py-3">{item.teamSize}</td> */}
                        <td
                          className={`px-4 py-3 ${parseFloat(item.current_balance) < 0 ? 'text-rose-600' : null}`}
                        >
                          {money(item.current_balance)}
                        </td>
                        {extraColumnData.map((col, colIndex) => (
                          <td key={colIndex} className="px-4 py-3">
                            {col.rowData[index]}
                          </td>
                        ))}
                      </tr>
                    ))}

                  {selectedOption === 'providers' &&
                    providersData
                      .filter(
                        (provider) => provider.id !== 1 && provider.id !== 2,
                      )
                      .map((provider, index) => (
                        <>
                          <tr
                            key={index}
                            className="border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700"
                            onClick={() =>
                              setExpandedRows({
                                ...expandedRows,
                                [provider.id]: !expandedRows[provider.id],
                              })
                            }
                          >
                            <td className="px-4 py-3">
                              <div className="flex items-center font-semibold">
                                {getProviderName(provider.id)}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="w-3 h-3 ml-2"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="m19.5 8.25-7.5 7.5-7.5-7.5"
                                  />
                                </svg>
                              </div>
                            </td>
                            <td className="px-4 py-3">
                              {provider.listings.length}
                            </td>
                            <td className="px-4 py-3">
                              {/* {countAccessibleListings(provider.listings)} /{' '}
                              {provider.listings.length} (
                              {(
                                (countAccessibleListings(provider.listings) /
                                  provider.listings.length) *
                                100
                              ).toFixed(2)}
                              %) */}{' '}
                              duu%
                            </td>

                            <td className="px-4 py-3">
                              {/* {countCurrentlyRented(provider.listings)} */}{' '}
                              duhh
                            </td>
                            {/* {extraColumnData.map((col, colIndex) => (
                          <td key={colIndex} className="px-4 py-3">
                            {col.rowData[index]}
                          </td>
                        ))} */}
                          </tr>
                          {expandedRows[provider.id] &&
                            Object.entries(
                              aggregateListingsByGPU(provider.listings),
                            ).map(([gpuName, data], listingIndex) => (
                              <tr
                                key={listingIndex}
                                className="border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700"
                              >
                                <td className="px-4 py-3 pl-8">{gpuName}</td>
                                <td className="px-4 py-3">{data.count}</td>
                                <td className="px-4 py-3">
                                  {
                                    // Ensure data.count is not zero to avoid division by zero
                                    data.count > 0
                                      ? `${data.accessible} / ${data.count} (${((data.accessible / data.count) * 100).toFixed(2)}%)`
                                      : `${data.accessible} / ${data.count} (N/A)`
                                  }
                                </td>
                                <td className="px-4 py-3">
                                  {data.currently_rented}
                                </td>
                              </tr>
                            ))}
                        </>
                      ))}
                </tbody>
              </table>

              {/* 🔑 */}
            </div>
            <div
              className="flex flex-col items-start justify-between px-4 pt-3 pb-4 space-y-3 md:flex-row md:items-center md:space-y-0"
              aria-label="Table navigation"
            >
              {/* <div className='flex items-center space-x-5 text-xs'>
                <div>
                  <div className='mb-1 text-gray-500 dark:text-gray-400'>
                    PLACEHOLDER
                  </div>
                  <div className='font-medium dark:text-white'>$ X</div>
                </div>
                <div>
                  <div className='mb-1 text-gray-500 dark:text-gray-400'>
                    PLACEHOLDER
                  </div>
                  <div className='font-medium dark:text-white'>$ X</div>
                </div>
              </div> */}
              <div className="flex items-center space-x-4">
                {/* <button
                  type='button'
                  className='py-1.5 flex items-center text-sm font-medium text-center text-indigo-700 rounded-lg hover:text-indigo-800 focus:ring-4 focus:outline-none focus:ring-indigo-300 dark:text-indigo-500 dark:hover:text-indigo-600 dark:focus:ring-indigo-800'
                >
                  PLACEHOLDER
                </button> */}
                {/* <button
                  onClick={() => handleRequestCSV('instances')}
                  type="button"
                  disabled={instances.length === 0}
                  className={`flex items-center px-3 py-2 text-xs font-medium text-center text-white bg-indigo-700 rounded-md hover:bg-indigo-800 dark:bg-indigo-600 dark:hover:bg-indigo-700 ${
                    instances.length === 0
                      ? 'opacity-50 cursor-not-allowed'
                      : ''
                  }`}
                >
                  Export Instances CSV
                </button>
                <button
                  onClick={() => handleRequestCSV('listings')}
                  type="button"
                  className="flex items-center px-3 py-2 text-xs font-medium text-center text-white bg-indigo-700 rounded-md hover:bg-indigo-800 dark:bg-indigo-600 dark:hover:bg-indigo-700 "
                >
                  Export Nodes CSV
                </button>
                <button
                  onClick={handleDownloadUsersCSV}
                  type="button"
                  className="flex items-center px-3 py-2 text-xs font-medium text-center text-white bg-indigo-700 rounded-md hover:bg-indigo-800 dark:bg-indigo-600 dark:hover:bg-indigo-700 "
                >
                  Export Users CSV
                </button> */}
              </div>
            </div>
            <nav
              className="flex items-center justify-between px-4 pt-3 pb-4"
              aria-label="Table navigation"
            >
              <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
                {totalEntries === 0 ? (
                  <>
                    Showing{' '}
                    <span className="font-semibold text-gray-900 dark:text-white">
                      0
                    </span>{' '}
                    entries
                  </>
                ) : (
                  <>
                    Showing{' '}
                    <span className="font-semibold text-gray-900 dark:text-white">
                      {Math.min(
                        (currentPage - 1) * rowsPerPage + 1,
                        displayedEntries,
                      )}
                    </span>{' '}
                    to{' '}
                    <span className="font-semibold text-gray-900 dark:text-white">
                      {Math.min(currentPage * rowsPerPage, displayedEntries)}
                    </span>{' '}
                    of{' '}
                    <span className="font-semibold text-gray-900 dark:text-white">
                      {getFilteredData().length}
                    </span>{' '}
                    entries
                  </>
                )}
              </span>
              <Pagination
                pageCount={pageCount}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
              <AdminDashConfirmationModal
                isOpen={isBanModalOpen}
                onClose={closeModal}
                user={selectedUserForBan}
              />
              <AdminAddCredits
                isOpen={isCreditModalOpen}
                setIsOpen={setIsCreditModalOpen}
                onClose={handleCloseAddCreditsModal}
              />
              <AddToWhitelistModal
                isOpen={isWhitelistModalOpen}
                setIsOpen={setIsWhitelistModalOpen}
                onClose={() => setIsWhitelistModalOpen(false)}
              />
            </nav>
          </div>
        </div>
      </section>

      <div
        className=" w-full pb-24 border-t h-[750px] max-w-[1500px] mx-auto"
        ref={newUserRef}
      >
        <AdminUserRequest
          filteredRequests={filteredRequests}
          filteredDeniedRequests={filteredDeniedRequests}
          convertAccounts={convertAccounts}
          denyAccounts={denyAccounts}
        />
      </div>
      {/* <div className="w-full h-24 bg-zinc-50"></div> */}
      <div className="flex items-center justify-center w-full mt-16 pb-24 border-t min-h-64 max-w-[1500px] mx-auto">
        <div className="w-full h-full px-2 sm:px-8">
          <div className="my-4 font-sans text-xl dark:text-gray-200">
            Credits Supply
          </div>
          <div className="max-w-full mx-auto">
            {/* Grid container that expands full width */}
            <div className="grid grid-cols-1 gap-4 mb-6 sm:grid-cols-2">
              {/* First Box */}
              <div className="flex flex-col space-y-2">
                <div className="flex flex-col h-24 border-b rounded shadow-sm sm:flex-row bg-gradient-to-r from-gray-50 to-gray-100 dark:bg-gray-800 dark:from-gray-800">
                  <div className="flex items-end justify-start w-full px-4 text-lg text-center text-gray-600 dark:text-white dark:bg-gray-800 dark:from-gray-700 rounded-t-md sm:rounded-l-md sm:rounded-t-none">
                    Total Supply
                  </div>
                  <div className="flex items-end justify-center px-4 text-xl font-thin text-center sm:text-2xl dark:text-white dark:bg-gray-800 rounded-b-md sm:rounded-r-md sm:rounded-b-none">
                    {money(stats.total_supply_credits)}
                  </div>
                </div>
                <div className="flex flex-col h-24 border-b rounded shadow-sm sm:flex-row bg-gradient-to-r from-gray-50 to-gray-100 dark:bg-gray-800 dark:from-gray-800">
                  <div className="flex items-end justify-start w-full px-4 text-lg text-center text-gray-600 dark:text-white rounded-t-md sm:rounded-l-md sm:rounded-t-none dark:bg-gray-800 dark:from-gray-700">
                    Admin Issued
                  </div>
                  <div className="flex items-end justify-center px-4 text-xl font-thin text-center sm:text-2xl dark:text-white dark:bg-gray-800 rounded-b-md sm:rounded-r-md sm:rounded-b-none">
                    {money(stats.admin_issued_credits)}
                  </div>
                </div>
              </div>
              {/* Second Box */}
              <div className="flex flex-col space-y-2">
                <div className="flex flex-col h-24 border-b rounded shadow-sm sm:flex-row bg-gradient-to-r from-gray-50 to-gray-100 dark:bg-gray-800 dark:from-gray-800">
                  <div className="flex items-end justify-start w-full px-4 text-lg text-center text-gray-600 dark:text-white dark:bg-gray-800 dark:from-gray-700 rounded-t-md sm:rounded-l-md sm:rounded-t-none">
                    Purchased Credits
                  </div>
                  <div className="flex items-end justify-center px-4 text-xl font-thin text-center sm:text-2xl dark:text-white dark:bg-gray-800 rounded-b-md sm:rounded-r-md sm:rounded-b-none">
                    {money(stats.purchased_credits)}
                  </div>
                </div>
                <div className="flex flex-col h-24 border-b rounded shadow-sm sm:flex-row bg-gradient-to-r from-gray-50 to-gray-100 dark:bg-gray-800 dark:from-gray-800">
                  <div className="flex items-end justify-start w-full px-4 text-lg text-center text-gray-600 dark:text-white rounded-t-md sm:rounded-l-md sm:rounded-t-none dark:bg-gray-800 dark:from-gray-700">
                    Spent Credits
                  </div>
                  <div className="flex items-end justify-center px-4 text-xl font-thin text-center sm:text-2xl dark:text-white dark:bg-gray-800 rounded-b-md sm:rounded-r-md sm:rounded-b-none">
                    {money(stats.spent_credits)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="flex items-center justify-center w-full my-24 border-t h-80 max-w-[1500px] mx-auto">
        <div className="w-full h-full px-8 ">
          <div className="mt-4 font-sans text-xl dark:text-gray-200">
            Leading Consumers
          </div> */}
      {/* <UserSpendingTreemap users={getSortedUsersByTotalSpent()} /> */}
      {/* </div>
      </div>
      <div className="flex items-center justify-center w-full my-24 border-t h-80 max-w-[1500px] mx-auto">
        <div className="w-full h-full px-8 ">
          <div className="mt-4 font-sans text-xl dark:text-gray-200">
            Provider Marketshare
          </div> */}
      {/* <ProviderTreemap providersData={prepareProviderTreemapData()} /> */}
    </div>
    //{' '}

    //   <div className="flex items-center justify-center w-full my-24 border-t h-80 max-w-[1500px] mx-auto">
    //     <div className="w-full h-full px-8 ">
    //       <div className="mt-4 font-sans text-xl dark:text-gray-200">
    //         Hardware Presence
    //       </div>
    //       <HardwareTreemap hardwareData={prepareHardwareTreemapData()} />
    //     </div>
    //   </div>
    // </div>
  );
};

export default NewAdminDash;
