import { useRentalModal } from '../../RentalModalContext';
import RentMachineErrorModal from '../RentMachineErrorModal';
import RentalSuccessModal from './RentalSuccessModal';
import UserIsUnconfirmedModal from './UserIsUnconfirmedModal';
import AddCreditCardModal from './AddCreditCardModal';
import UserIsDelinquentModal from './UserIsDelinquentModal';
import MissingSSHKeyModal from './MissingSSHKeyModal';
import InsufficientCreditsModal from './InsufficientCreditsModal';
import InsufficientTopUpModal from './InsufficientTopUpModal';

/**
 * RentalModals: A component that renders all the rental modals
 * @component
 * @return {React.Component}
 */

const RentalModals = () => {
  const {
    showUserIsUnconfirmedModal,
    setShowUserIsUnconfirmedModal,
    showUserIsDelinquentModal,
    setShowUserIsDelinquentModal,
    typeofCreditCardModal,
    setTypeofCreditCardModal,
    errorMessage,
    setErrorMessage,
    showRentalSuccessModal,
    setShowRentalSuccessModal,
    showAddSSHModal,
    setShowAddSSHModal,
    setShowInsufficientCreditsModal,
    showInsufficientCreditsModal,
    insufficientTopUpAmount,
    setInsufficientTopUpAmount,
  } = useRentalModal();

  return (
    <>
      <InsufficientTopUpModal
        showModal={insufficientTopUpAmount > 0}
        closeModal={() => setInsufficientTopUpAmount(0)}
        insufficientTopUpAmount={insufficientTopUpAmount}
      />
      <InsufficientCreditsModal
        showModal={showInsufficientCreditsModal}
        closeModal={() => setShowInsufficientCreditsModal(false)}
      />
      <UserIsDelinquentModal
        showModal={showUserIsDelinquentModal}
        closeModal={() => setShowUserIsDelinquentModal(false)}
      />
      <RentMachineErrorModal
        showModal={!!errorMessage}
        closeModal={() => setErrorMessage('')}
        errorMessage={errorMessage}
      />
      <RentalSuccessModal
        showModal={showRentalSuccessModal}
        closeModal={() => setShowRentalSuccessModal(false)}
      />
      <UserIsUnconfirmedModal
        showModal={showUserIsUnconfirmedModal}
        closeModal={() => setShowUserIsUnconfirmedModal(false)}
      />
      <AddCreditCardModal
        showModal={!!typeofCreditCardModal}
        closeModal={() => setTypeofCreditCardModal('')}
        type={typeofCreditCardModal}
      />
      <MissingSSHKeyModal
        showModal={showAddSSHModal}
        closeModal={() => setShowAddSSHModal(false)}
      />
    </>
  );
};

export default RentalModals;
