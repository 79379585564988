import { useState, useEffect, useRef } from 'react';
import ApexCharts from 'react-apexcharts';
import { ArrowLongUpIcon, ArrowLongDownIcon } from '@heroicons/react/24/solid';
import { XMarkIcon } from '@heroicons/react/20/solid';
import toast from 'react-hot-toast';

import Spinner from '../../Spinner';
import MonthSelector from './MonthSelector';
import { useUser } from '../../../UserContext';
import { getPricePerToken } from '../../../utils/generalUtils';

function getFirstAndLastDaysOfMonth(year, month) {
  const firstDay = new Date(Date.UTC(year, month, 1));
  const lastDay = new Date(Date.UTC(year, month + 1, 0));
  return [firstDay, lastDay];
}

function convertTokensToApexChartsSeries(tokensArray) {
  let year, month, day;
  const parseUTCDate = (dateStr) => {
    if (dateStr.split(' ').length === 1) {
      [year, month, day] = dateStr.split('-').map(Number);
    } else {
      [year, month, day] = dateStr.split(' ')[1].split('-').map(Number);
    }
    return new Date(Date.UTC(year, month - 1, day));
  };

  const toUTCDateString = (date) => {
    return date.toISOString().split('T')[0];
  };

  if (tokensArray.length === 0) {
    const today = new Date();
    const firstOfMonth = new Date(
      Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), 1),
    );
    const lastOfMonth = new Date(
      Date.UTC(today.getUTCFullYear(), today.getUTCMonth() + 1, 0),
    );
    const daysInMonth = lastOfMonth.getUTCDate();
    return Array.from({ length: daysInMonth }, (_, index) => {
      const date = new Date(firstOfMonth.getTime());
      date.setUTCDate(firstOfMonth.getUTCDate() + index);
      return { x: toUTCDateString(date), y: null };
    });
  }

  const mappedTokensArray = tokensArray.map((entry) => {
    const parsedDate = parseUTCDate(entry.date);
    return {
      x: toUTCDateString(parsedDate),
      y: entry.tokens,
    };
  });

  mappedTokensArray.sort(
    (a, b) => new Date(a.x).getTime() - new Date(b.x).getTime(),
  );

  const startDate = parseUTCDate(mappedTokensArray[0].x);
  const endDate = new Date(); // Current date
  const firstOfStartMonth = new Date(
    Date.UTC(startDate.getUTCFullYear(), startDate.getUTCMonth(), 1),
  );
  const lastOfCurrentMonth = new Date(
    Date.UTC(endDate.getUTCFullYear(), endDate.getUTCMonth() + 1, 0),
  );

  const daysInRange =
    Math.floor(
      (lastOfCurrentMonth - firstOfStartMonth) / (1000 * 60 * 60 * 24),
    ) + 1;

  const fullDateArray = Array.from({ length: daysInRange }, (_, index) => {
    const date = new Date(firstOfStartMonth.getTime());
    date.setUTCDate(firstOfStartMonth.getUTCDate() + index);
    return toUTCDateString(date);
  });

  const tokensMap = new Map(mappedTokensArray.map((item) => [item.x, item.y]));

  const completeSeries = fullDateArray.map((dateStr) => ({
    x: dateStr,
    y: tokensMap.has(dateStr) ? tokensMap.get(dateStr) : null,
  }));

  return completeSeries;
}

const TokenUsageChart = ({ model, setModelNotFound, closeModel }) => {
  const { customAxios } = useUser();
  const costPerToken = getPricePerToken(model.model_config.base_model);
  const chartDataRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [errorNoModel, setErrorNoModel] = useState(false);
  const [totalTokens, setTotalTokens] = useState(0);
  const [totalTokensLastMonth, setTotalTokensLastMonth] = useState(0);
  const [chartData, setChartData] = useState([]);
  const [chartSeries, setChartSeries] = useState([]);
  const [dataStartDate, setDataStartDate] = useState(new Date());
  const [percentageChange, setPercentageChange] = useState(0);
  const [costChange, setCostChange] = useState(0);
  const [showSettingsMenu, setShowSettingsMenu] = useState(false);
  const [displayDate, setDisplayDate] = useState({
    thisMonth: {
      month: null,
      year: null,
    },
    previousMonth: {
      month: null,
      year: null,
    },
  });

  const getDollars = (tokens, decimalPlaces = 2) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: decimalPlaces,
    }).format(tokens);
  };

  useEffect(() => {
    const getModelUsageData = async () => {
      setLoading(true);
      setChartData([]);
      setChartSeries([]);
      setErrorNoModel(false);
      try {
        const response = await customAxios.get(
          `tailor/v1/models/${model.model_id}`,
        );
        if (response.status === 200) {
          const { usage_data } = response.data.message;
          chartDataRef.current = convertTokensToApexChartsSeries(usage_data);
          setChartData(chartDataRef.current);
          if (usage_data.length > 0) {
            setDataStartDate(new Date(usage_data[0].date));
          }
          setModelNotFound(false);
        } else if (response.status === 404) {
          setErrorNoModel(true);
          setModelNotFound(true);
        } else {
          toast.error('Failed to fetch model usage data, try again later.');
        }
      } catch (error) {
        if (import.meta.env.DEV) {
          console.error(error);
        }
        toast.error('Failed to fetch model usage data, try again later.');
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 500);
      }
    };
    getModelUsageData();
  }, [model, setModelNotFound, customAxios]);

  useEffect(() => {
    if (!chartDataRef.current) {
      return;
    }
    const [firstDay, lastDay] = getFirstAndLastDaysOfMonth(
      displayDate.thisMonth.year,
      displayDate.thisMonth.month,
    );
    const subsetData = chartDataRef.current.filter((data) => {
      const dataDate = new Date(data.x);
      return dataDate >= firstDay && dataDate <= lastDay;
    });
    const [firstDayLastMonth, lastDayLastMonth] = getFirstAndLastDaysOfMonth(
      displayDate.previousMonth.year,
      displayDate.previousMonth.month,
    );

    const subsetDataLastMonth = chartDataRef.current.filter((data) => {
      const dataDate = new Date(data.x);
      return dataDate >= firstDayLastMonth && dataDate <= lastDayLastMonth;
    });

    setChartData(subsetData);
    setChartSeries([{ name: 'Tokens', data: subsetData }]);

    const totalTokens = subsetData.reduce((acc, cur) => acc + cur.y, 0);
    const totalTokensLastMonth = subsetDataLastMonth.reduce(
      (acc, cur) => acc + cur.y,
      0,
    );

    const calculatePercentageChange = () => {
      if (totalTokensLastMonth === 0) {
        return 0;
      }
      const percentageChange =
        ((totalTokens - totalTokensLastMonth) / totalTokensLastMonth) * 100;
      if (Number.isInteger(percentageChange)) {
        return percentageChange; // Return as is without decimal places
      } else {
        return percentageChange.toFixed(2); // Return with 2 decimal places
      }
    };

    setTotalTokensLastMonth(totalTokensLastMonth);
    setTotalTokens(totalTokens);

    setPercentageChange(calculatePercentageChange());

    setCostChange(
      totalTokens * costPerToken - totalTokensLastMonth * costPerToken,
    );
  }, [displayDate, costPerToken]);

  const options = {
    chart: {
      animations: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      type: 'datetime',
      categories: chartData.map((data) => data.x),
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 2,
      strokeColors: '#4338CA',
      colors: '#4338CA',
      strokeWidth: 2,
      hover: {
        size: 4,
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          const valueString = (value / 1000).toLocaleString();
          return valueString + ' k';
        },
      },
    },
    colors: ['#818CF8'],
    tooltip: {
      custom: function ({ _, seriesIndex, dataPointIndex, w }) {
        const data = w.config.series[seriesIndex].data[dataPointIndex];
        return data.y
          ? '<div class="bg-white border border-zinc-300 rounded-md shadow-md">' +
              '<div class="text-xs text-zinc-600 px-2 pt-2 pb-1 border-b border-zinc-300">' +
              new Date(data.x).toLocaleDateString('en-US', {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
              }) +
              '</div>' +
              '<div class="text-sm text-zinc-600 bg-zinc-100 w-full px-2 pt-1">' +
              'Tokens: ' +
              data.y.toLocaleString() +
              '</div>' +
              '<div class="text-sm px-2 pt-1 pb-2 text-zinc-600 bg-zinc-100">' +
              'Cost: $' +
              (data.y * costPerToken).toFixed(2) +
              '</div>' +
              '</div>'
          : '<div class="bg-white border border-zinc-300 rounded-md shadow-md">' +
              '<div class="text-xs text-zinc-600 px-2 pt-2 pb-1 border-b border-zinc-300">' +
              new Date(data.x).toLocaleDateString('en-US', {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
              }) +
              '</div>' +
              '<div class="text-sm text-zinc-600 bg-zinc-100 w-full px-2 pt-1">' +
              'No data' +
              '</div>' +
              '</div>';
      },
    },
    stroke: {
      width: 4,
      curve: 'straight',
    },
    title: {
      text: 'Token Usage Per Day',
      align: 'left',
    },
  };

  if (loading) {
    return (
      <div className="md:h-[33rem] h-[37.125rem] flex items-center justify-around relative">
        <button
          className="absolute p-1 rounded-full -top-9 right-2 bg-zinc-100 text-zinc-800 md:hidden"
          onClick={closeModel}
        >
          <XMarkIcon className="w-5 h-5" />
        </button>
        <Spinner size={'36px'} borderTopColor={'gray'} />
      </div>
    );
  }

  if (errorNoModel) {
    return (
      <>
        <div className="md:h-[33rem] h-[37.125rem] flex flex-col items-center justify-center text-sm md:text-base text-zinc-800 text-center relative">
          <button
            className="absolute p-1 rounded-full -top-9 right-2 bg-zinc-100 text-zinc-800 md:hidden"
            onClick={closeModel}
          >
            <XMarkIcon className="w-5 h-5" />
          </button>
          <p>Model not found. Please select a different model.</p>
          <p>If you believe this is an error, please contact support.</p>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="md:sh-[33rem] hs-[37.125rem] px-2 relative">
        {/* button with an X to close the model */}
        <button
          className="absolute p-1 rounded-full -top-9 right-2 bg-zinc-100 text-zinc-800 md:hidden"
          onClick={closeModel}
        >
          <XMarkIcon className="w-5 h-5" />
        </button>

        <div className="flex flex-col flex-wrap items-start mb-4 gap-x-20 gap-y-2">
          <div className="text-xl font-bold text-zinc-800 max-w-[90%] break-words">
            {model.model_name}
          </div>
          <MonthSelector startDate={dataStartDate} setDate={setDisplayDate} />
        </div>
        <ApexCharts
          options={options}
          series={chartSeries}
          height={250}
          // type="scatter"
          type={`${chartData?.filter((entry) => entry.y !== null).length > 1 ? 'area' : 'scatter'}`}
        />
        <div className="flex flex-wrap md:justify-center md:gap-8 md:flex-nowrap">
          <div className="flex items-baseline w-screen p-1 md:flex-col md:items-center md:justify-around gap-x-2 md:w-auto ">
            <div className="md:flex-1 md:pt-4 md:text-lg w-fit text-nowrap">
              Model Usage
            </div>
            <div className="text-xs md:text-sm text-zinc-600">This Month</div>
            <div className="text-xs md:pb-4 md:text-sm text-zinc-600">
              vs. Last Month
            </div>
          </div>
          <div className="relative w-1/2 p-2 border md:rounded-lg rounded-tl-md border-zinc-300 md:w-36 md:min-w-32">
            <div className="flex flex-col items-center justify-around h-full">
              <span
                className={`flex text-xs absolute top-1 right-1 ${percentageChange > 0 ? '!text-lime-600' : percentageChange < 0 ? 'text-rose-600' : 'text-zinc-600'}`}
              >
                {percentageChange > 0 ? (
                  <ArrowLongUpIcon className="w-4 h-4" />
                ) : percentageChange < 0 ? (
                  <ArrowLongDownIcon className="w-4 h-4" />
                ) : null}
                {percentageChange === 0
                  ? '⎯'
                  : Math.abs(percentageChange) + '%'}
              </span>
              <span className="mt-3 font-bold md:text-lg">
                {totalTokens.toLocaleString()}
              </span>
              <div className="flex flex-col items-center justify-center text-xs text-center md:text-base text-zinc-700 grow">
                <div>Tokens Used</div>
                <div className="text-xs text-zinc-500">(this month)</div>
              </div>
            </div>
          </div>

          <div className="relative w-1/2 p-2 border-r md:border border-y md:rounded-lg rounded-tr-md border-zinc-300 md:w-36 md:min-w-32">
            <div className="flex flex-col items-center justify-around h-full">
              <span
                className={`text-xs flex absolute top-1 right-1 ${costChange > 0 ? '!text-lime-600' : costChange < 0 ? 'text-rose-600' : 'text-zinc-600'}`}
              >
                {costChange > 0 ? (
                  <ArrowLongUpIcon className="w-4 h-4" />
                ) : costChange < 0 ? (
                  <ArrowLongDownIcon className="w-4 h-4" />
                ) : null}
                {getDollars(Math.abs(costChange))}
              </span>
              <span className="mt-3 font-bold md:text-lg">
                {getDollars(totalTokens * costPerToken)}
              </span>
              <div className="flex flex-col items-center justify-center text-xs text-center md:text-base text-zinc-700 grow">
                <div>Cost</div>
                <div className="text-xs text-zinc-500">
                  (this month)
                  {model.self_hosted && <sup>2</sup>}
                </div>
              </div>
            </div>
          </div>

          <div className="relative w-1/2 p-2 border-b md:border border-x md:rounded-lg rounded-bl-md border-zinc-300 md:w-36 md:min-w-32">
            <div className="flex flex-col items-center justify-around h-full">
              <span className="mt-3 font-bold md:text-lg">
                {getDollars(costPerToken * 1_000_000)}
              </span>
              <span className="flex flex-col items-center justify-center text-xs text-center md:text-base text-zinc-700 grow">
                <div>
                  Cost per 1M tokens
                  {model.self_hosted && (
                    <span className="text-xs text-zinc-500 inline">
                      <sup>2</sup>
                    </span>
                  )}
                </div>
              </span>
            </div>
          </div>

          <div className="relative w-1/2 p-2 border-b border-r rounded-br-md border-zinc-300 md:hidden"></div>
        </div>
      </div>
      <div className="text-[10px] italic text-right mt-4 px-3 md:px-0">
        {/* <sup>*</sup> Based on data from https://openai.com/pricing */}
      </div>
      <hr className="mb-4 border-2 border-t border-zinc-300" />
    </>
  );
};

export default TokenUsageChart;
