// CommonLayout.tsx
import { Outlet } from 'react-router-dom';
import { useState } from 'react';

import NavigationBar from '../Components/NavigationBar';
import Footer from '../Components/Footer';
import UnderNavbarBanner from '../Components/UnderNavbarBanner.jsx';
import ResponsiveNavbar from '../V2LandingThings/ResponsiveNavbar.jsx';

const CommonLayout = () => {
  const [, setMinerMode] = useState(false);

  return (
    <>
      <ResponsiveNavbar />
      {/* <NavigationBar setMinerMode={setMinerMode} /> */}
      <UnderNavbarBanner />
      <Outlet />
      <Footer />
    </>
  );
};

export default CommonLayout;
