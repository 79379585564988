const Spinner = ({ size, borderColor, borderTopColor }) => {
  return (
    <div
      className="spinner"
      style={{
        border: `4px solid ${borderColor || 'rgba(255, 255, 255, 0.3)'}`,
        borderTop: `4px solid ${borderTopColor || 'white'}`,
        borderRadius: '50%',
        width: `${size || '24px'}`,
        height: `${size || '24px'}`,
        animation: 'spin 1s linear infinite',
      }}
    ></div>
  );
};

export default Spinner;
