import { Modal } from 'flowbite-react';
import moment from 'moment';
import toast from 'react-hot-toast';

import { useUser } from '../../../UserContext';

const UndeployWarningModal = ({ model, onClose, showModal }) => {
  const { customAxios } = useUser();

  const handleUndeployModel = async () => {
    try {
      const response = await customAxios.post('tailor/v1/undeploy_model', {
        model_name: model.model_name,
      });
      if (response.status === 200) {
        toast.success('Model is Un-deploying');
        onClose();
      }
    } catch (error) {
      toast.error('Failed to un-deploy model, try again later.');
      if (import.meta.env.DEV) {
        console.error(error);
      }
    }
  };

  const getTimeFromNow = (date) => {
    const now = moment();
    const inputDate = moment(date);
    const daysDifference = now.diff(inputDate, 'days');

    if (daysDifference === 0) {
      return 'today';
    } else if (daysDifference === 1) {
      return '1 day ago';
    } else {
      return `${daysDifference} days ago`;
    }
  };

  return (
    <Modal
      show={showModal}
      onClose={onClose}
      title="Delete API key"
      dismissible
    >
      <div className="p-6">
        <div className="text-lg font-semibold text-zinc-800">
          Un-deploy Model
        </div>
        <div className="mt-4 text-sm text-zinc-700">
          <p>
            Un-deploying a model will disconnect it from the API endpoint and
            any applications that are using it will no longer be able to access
            it.
          </p>
          <p className="mt-2">
            you can always re-deploy the model later if you want to.
          </p>
          <div className="mt-4 text-sm text-zinc-700">
            <p>
              <span className="font-semibold">Model:</span>{' '}
              <span className="text-lg">{model?.model_name}</span>
            </p>
          </div>
          <div className="mt-4 text-sm text-zinc-700 capitalize">
            <p>
              <span className="font-semibold">Last used:</span>{' '}
              {model?.last_used
                ? getTimeFromNow(model?.last_used)
                : 'Never used'}
            </p>
          </div>
        </div>
        <div className="flex justify-end gap-4 mt-8">
          <button
            className="flex items-center gap-1 p-2 px-3 text-sm font-bold underline rounded-md text-zinc-700 hover:underline underline-offset-4"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="flex items-center gap-1 p-2 px-3 text-sm font-bold bg-red-200 rounded-md text-zinc-700 hover:bg-red-300 active:bg-red-400"
            onClick={() => handleUndeployModel()}
          >
            Un-deploy
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default UndeployWarningModal;
