// components/UploadLogsModal.js
import { Modal } from 'flowbite-react';
import { useEffect, useState } from 'react';
import clsx from 'clsx';

import LogsUpload from './LogsUpload';
import useUploadLogs from '../../../Hooks/useUploadLogs';

const UploadLogsModal = ({ openModal, closeModal }) => {
  const {
    uploadFile,
    setSelectedFile,
    setFileContent,
    setSaveLogsTagList,
    setValidationMessages,
    validationMessages,
    validationInProgress,
    uploadInProgress,
    selectedFile,
    inProgress,
    skipLogsWithErrors,
    setSkipLogsWithErrors,
    setCreateSyntheticLogs,
    createSyntheticLogs,
  } = useUploadLogs();
  const [tagsTextArea, setTagsTextArea] = useState('');
  const [validationError, setValidationError] = useState('');
  const [tempTags, setTempTags] = useState([]);

  useEffect(() => {
    if (openModal) {
      setTagsTextArea('');
      setTempTags([]);
      setValidationError('');
      setTempTags([]);
    }
    if (openModal && !inProgress) {
      setSelectedFile(null);
      setFileContent({ name: '', size: null, numberOfLines: 0 });
      setSaveLogsTagList([]);
      setSkipLogsWithErrors(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal, inProgress]);

  const showTags = () => {
    const tags = tagsTextArea.split(',').map((tag) => tag.trim());
    const uniqueTags = [...new Set(tags.filter((tag) => tag))];
    setTempTags(uniqueTags);
  };

  const handleCloseModal = () => {
    closeModal();
  };

  const handleUploadRequest = (e) => {
    if (!selectedFile) {
      setValidationError('Please select a file to upload.');
      return;
    }
    if (!inProgress) {
      setSaveLogsTagList(tempTags);
      e.preventDefault();
      uploadFile();
      handleCloseModal();
    }
  };

  return (
    <Modal
      show={openModal}
      onClose={handleCloseModal}
      title="Upload Logs"
      dismissible
      className=" dark:bg-zinc-800 font-dmSans"
    >
      <Modal.Header className="dark:bg-zinc-800">
        <div className="text-lg font-semibold text-zinc-700 dark:text-zinc-300">
          Upload Custom Logs
        </div>
      </Modal.Header>
      <Modal.Body className=" dark:bg-zinc-800">
        <div className="flex mb-1 text-sm font-semibold text-zinc-800 peer-disabled:opacity-50">
          1. Select the file containing the logs:
        </div>
        <LogsUpload
          validationError={validationError}
          setValidationError={setValidationError}
          inProgress={uploadInProgress || validationInProgress}
        />
        <div className="mt-6">
          <label className="flex text-sm font-semibold text-zinc-800 peer-disabled:opacity-50">
            2. Add tags to the logs: (optional)
          </label>
          <textarea
            className={clsx(
              'mt-1 block w-full h-24 px-3 py-2 border border-zinc-300 dark:border-zinc-600 rounded-md shadow-sm focus:ring-1 focus:ring-zinc-300 focus:outline-none dark:bg-zinc-700 dark:text-zinc-300 placeholder-zinc-400 dark:placeholder-zinc-400 placeholder:text-sm text-sm',
            )}
            placeholder="Enter tags separated by commas."
            onChange={(e) => {
              setValidationMessages({
                file: '',
              });
              setTagsTextArea(e.target.value.trim());
            }}
            onKeyUp={showTags}
            autoCapitalize="off"
            value={tagsTextArea}
          ></textarea>
        </div>
        {/* make a list of the tags with tag formatting */}
        <div className="flex flex-wrap gap-2 mt-2">
          {tempTags.map((tag, index) => (
            <span
              key={index}
              className="inline-block px-2 py-1 text-xs font-medium text-gray-800 rounded-full bg-zinc-200 w-fit whitespace-nowrap"
            >
              {tag}
            </span>
          ))}
        </div>
        {/* add a 'skip logs with errors checkbox */}
        <div className="flex items-start py-4">
          <input
            type="checkbox"
            id="skipErrors"
            name="skipErrors"
            className="w-5 h-5 mt-0.5 bg-gray-100 border-gray-300 cursor-pointer md:w-4 md:h-4 text-zinc-800 focus:ring-transparent peer disabled:cursor-not-allowed"
            checked={skipLogsWithErrors}
            onChange={(e) => {
              setSkipLogsWithErrors(e.target.checked);
            }}
          />
          <div className="ml-2">
            <label
              htmlFor="skipErrors"
              className="flex text-sm font-semibold text-zinc-800 peer-disabled:opacity-50"
            >
              Skip logs with errors
            </label>
            <span className="block mt-1 text-xs text-zinc-500">
              When checked, validation will be performed on every log, and only
              the logs that pass will be uploaded. When unchecked, validation
              will stop at the first invalid log, and no logs will be uploaded.
            </span>
          </div>
        </div>

        <div className="flex items-start pb-4">
          <input
            type="checkbox"
            id="synthetic"
            name="synthetic"
            className="w-5 h-5 bg-gray-100 border-gray-300 cursor-pointer md:w-4 md:h-4 text-zinc-800 focus:ring-transparent peer disabled:cursor-not-allowed mt-0.5"
            checked={createSyntheticLogs}
            onChange={(e) => {
              setCreateSyntheticLogs(e.target.checked);
            }}
          />
          <div className="ml-2">
            <label
              htmlFor="synthetic"
              className="flex text-sm font-semibold text-zinc-800 peer-disabled:opacity-50"
            >
              Create synthetic version of the logs
            </label>
            <span className="block mt-1 text-xs text-zinc-500">
              If this data was collected from a model which outputs cannot be
              used for training, we recommend you create a rephrased synthetic
              version of the logs which can be used to improve the model's
              quality.
            </span>
          </div>
        </div>

        {/* a warning: not to refresh the page while logs are uploading or all progress will be lost. also not to close the browser */}
        <div className="mt-4 text-xs max-w-md border rounded-md p-2 border-red-500/50 text-zinc-800">
          <span className="font-semibold text-red-500 ">Warning:</span> Do not
          refresh the page or close the browser while logs are being validated
          and uploaded. All progress will be lost - Validation happens entirely
          on your browser.
        </div>

        <div className="flex items-center justify-start gap-4 mt-8">
          <button
            onClick={(e) => handleUploadRequest(e)}
            className="inline-flex items-center justify-center h-10 px-4 py-2 text-sm font-medium text-indigo-900 bg-indigo-100 border border-transparent rounded-md min-w-36 max-w-64 hover:bg-indigo-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2 whitespace-nowrap"
            disabled={inProgress}
          >
            Validate & Upload
          </button>
          <button
            className="inline-flex items-center justify-center h-10 px-4 py-2 ml-2 text-sm font-medium text-red-900 border border-gray-300 rounded-md hover:bg-red-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2"
            onClick={handleCloseModal}
          >
            Cancel
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UploadLogsModal;
