import { useEffect, useMemo, useRef, useState } from 'react';
import { CodeBracketSquareIcon, SettingsIcon } from './_icons';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { Listbox } from '@headlessui/react';
import CodeSnippet2 from '../../../utils/CodeSnippet2';
import useIsRef2OnTop from '../../../Hooks/useIsBlocking';
import { useAtom } from 'jotai';
import { preferredLanguageAtom, showButtonsAtom } from '../../../context/atoms';
import useGetCodeSnippet from '../../../Hooks/useGetCodeSnippet';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

const languages = [
  ['python', 'Python'],
  ['javascript', 'JavaScript'],
  ['curl', 'cURL'],
];

const ApiWidget = ({
  id,
  model,
  containerRef,
  parameters,
  conversation,
  isParametersMinimized,
  refreshButtonRef,
  isSplitView,
  modelNameRef,
}) => {
  const [isWidgetOpen, setIsWidgetOpen] = useState(false);
  const [showButtons, setShowButtons] = useAtom(showButtonsAtom);
  const [selectedLanguage, setSelectedLanguage] = useAtom(
    preferredLanguageAtom,
  );
  const widgetRef = useRef(null);
  const buttonsRef = useRef(null);
  const finalParameters = useMemo(() => {
    if (isParametersMinimized) {
      return null;
    }
    return Object.fromEntries(
      Object.entries(parameters).filter(([, value]) => value !== -1),
    );
  }, [isParametersMinimized, parameters]);

  const isrefreshButtonBlockingWidget = useIsRef2OnTop(
    widgetRef,
    refreshButtonRef,
    containerRef,
  );

  const areButtonsBlockingModelname = useIsRef2OnTop(
    modelNameRef,
    buttonsRef,
    containerRef,
  );

  const code = useGetCodeSnippet({
    conversation,
    model,
    finalParameters,
    isSplitView,
    languages,
  });

  useEffect(() => {
    const container = containerRef.current;
    const refreshButton = refreshButtonRef.current;

    if (isWidgetOpen) {
      container.style.transform = 'translateX(0px)';
    }

    if (isWidgetOpen && container) {
      container.style.overflow = 'clip';

      if (isrefreshButtonBlockingWidget && refreshButton) {
        refreshButton.style.visibility = 'hidden';
      } else if (refreshButton) {
        refreshButton.style.visibility = 'visible';
      }
    } else if (container) {
      container.style.overflow = 'auto';

      if (refreshButton) {
        refreshButton.style.visibility = 'visible';
      }
    }

    return () => {
      if (container) {
        container.style.overflow = 'auto';
        container.style.transform = 'translateX(0px)';
      }
      if (refreshButton) {
        refreshButton.style.position = 'absolute';
        refreshButton.style.top = '';
      }
    };
  }, [
    isWidgetOpen,
    isrefreshButtonBlockingWidget,
    containerRef,
    refreshButtonRef,
    widgetRef,
    isSplitView,
  ]);

  useEffect(() => {
    if (id === '1') {
      setShowButtons([!areButtonsBlockingModelname, showButtons[1]]);
    } else if (id === '2') {
      setShowButtons([showButtons[0], !areButtonsBlockingModelname]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areButtonsBlockingModelname, setShowButtons, id]);

  return (
    <>
      <div
        data-name="api-widget-buttons"
        className={clsx(
          'sticky z-[52] w-fit gap-4 items-center hidden sm:flex flex-wrap ',
          isSplitView && '!hidden lg:!flex',
          showButtons[0] && showButtons[1] ? 'opacity-100' : 'opacity-10',
          isWidgetOpen
            ? 'left-5 top-5 !opacity-100'
            : isSplitView
              ? 'left-0 top-0 ease-in-out transition-opacity hover:opacity-100'
              : 'left-0 top-0',
        )}
      >
        <div
          ref={buttonsRef}
          className="flex items-center ring-1 ring-zinc-500 rounded-md divide-x divide-zinc-400 shadow-md z-[51] w-fit h-fit"
        >
          <button
            onClick={() => setIsWidgetOpen(false)}
            className={clsx(
              'text-sm font-medium px-2 py-1 min-w-10 flex-center rounded-l-md gap-1',
              isWidgetOpen
                ? 'bg-white'
                : 'bg-indigo-200 shadow-inner hover:bg-indigo-300 text-indigo-900',
            )}
          >
            <SettingsIcon className="size-5" />
            UI
          </button>
          <button
            onClick={() => setIsWidgetOpen(true)}
            className={clsx(
              'text-sm font-medium px-2 py-1 min-w-10 flex-center gap-1 rounded-r-md',
              isWidgetOpen
                ? 'bg-indigo-200 shadow-inner hover:bg-indigo-300 text-indigo-900'
                : 'bg-white',
            )}
          >
            <CodeBracketSquareIcon className="size-5" />
            API
          </button>
        </div>
        {isWidgetOpen && (
          <div className="relative pr-10">
            <Listbox value={selectedLanguage} onChange={setSelectedLanguage}>
              <Listbox.Button className="text-sm font-medium px-3 py-1 min-w-10 flex-center gap-1 rounded-md ring-1 ring-indigo-500 text-indigo-500 bg-[#1d212c] flex items-center gap-4">
                {selectedLanguage[1]}
                <ChevronDownIcon className="size-5" />
              </Listbox.Button>
              <Listbox.Options className="absolute top-10 ring-1 ring-indigo-500 rounded-md shadow-md bg-indigo-200 text-indigo-900">
                {languages.map((language) => (
                  <Listbox.Option
                    key={language[0]}
                    value={language}
                    className={clsx(
                      'ui-active:bg-indigo-500 ui-active:bg-opacity-80 ui-active:text-white text-sm font-medium px-2 py-1 min-w-10 flex-center cursor-pointer ui-active:ring-1 ui-active:ring-indigo-500 hover:bg-indigo-600 hover:text-white active:bg-indigo-500 active:text-white',
                    )}
                  >
                    {language[1]}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Listbox>
          </div>
        )}
      </div>
      <div
        data-name="api-widget-code"
        ref={widgetRef}
        className={clsx(
          'overflow-auto fixed top-0 left-0 w-full h-full',
          isWidgetOpen ? 'z-[51]' : '-z-50 opacity-0',
        )}
      >
        <AnimatePresence>
          <motion.div
            initial={{ height: 0 }}
            animate={{ height: '100%' }}
            exit={{ height: 0 }}
            transition={{ duration: 0 }}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              backgroundColor: '#1d212c',
              zIndex: isWidgetOpen ? 49 : -50,
              overflow: 'auto',
              paddingTop: '40px',
            }}
          >
            <CodeSnippet2
              overrideStyle
              buttonFixed
              language={
                selectedLanguage[0] === 'curl' ? 'bash' : selectedLanguage[0]
              }
              code={code[selectedLanguage[0]]}
              className={clsx(isWidgetOpen ? '' : '!-z-50')}
            />
          </motion.div>
        </AnimatePresence>
      </div>
    </>
  );
};

export default ApiWidget;
